<template>
    <div
        v-dragscroll
        id="world-map-wrapper"
        @scroll="throttle(emitScrollCoords, throttleTimerCoordsEmit, 16)"
        @touchstart="stopCoordsEmit"
        @mousedown="stopCoordsEmit"
        :style="`
			transform: scale(${mapScaleFactor});
			width: calc(${(1 / mapScaleFactor) * 100}vw);
			margin-left: calc(${(100 - (1 / mapScaleFactor) * 100) / 2}vw);
			height: calc(${(1 / mapScaleFactor) * 100}vh);
			margin-top: calc(${(100 - (1 / mapScaleFactor) * 100) / 2}vh);
		`"
    >
        <div
            class="world-map"
            :style="`
				height: ${WORLD_MAP_HEIGHT}px;
				width: ${WORLD_MAP_WIDTH}px;
			`"
        >
            <template v-for="row of visibleTiles" :key="row.key">
                <MapTile
                    v-for="tile of row.tiles"
                    :key="tile.tile"
                    :tile="tile"
                    :centered-on-city="centeredOnCity"
                    :map-scale-factor="mapScaleFactor"
                    :current-city-id="currentCityId"
                />
            </template>
            <MapArrowsLayer :map-arrows="currentCityMapArrows" :map-actions="mapActions" />
<!--            <div-->
<!--                v-for="(arrows_group, arrows_group_index) in mapActionsGroupedByCityPair"-->
<!--                :key="arrows_group_index"-->
<!--                class="arrows-wrapper"-->
<!--                :style="`-->
<!--        left: ${calculateCityCenterX(arrows_group.coords[0].x, arrows_group.coords[0].y)}px;-->
<!--        top: ${calculateCityCenterY(arrows_group.coords[0].x, arrows_group.coords[0].y) - 62}px;-->
<!--        width: ${calculateDistanceBetweenCities(-->
<!--            calculateCityCenterX(arrows_group.coords[0].x, arrows_group.coords[0].y),-->
<!--            calculateCityCenterY(arrows_group.coords[0].x, arrows_group.coords[0].y),-->
<!--            calculateCityCenterX(arrows_group.coords[1].x, arrows_group.coords[1].y),-->
<!--            calculateCityCenterY(arrows_group.coords[1].x, arrows_group.coords[1].y),-->
<!--        )}px;-->
<!--        transform: rotateZ(${calculateAngleBetweenCities(-->
<!--            calculateCityCenterX(arrows_group.coords[0].x, arrows_group.coords[0].y),-->
<!--            calculateCityCenterY(arrows_group.coords[0].x, arrows_group.coords[0].y),-->
<!--            calculateCityCenterX(arrows_group.coords[1].x, arrows_group.coords[1].y),-->
<!--            calculateCityCenterY(arrows_group.coords[1].x, arrows_group.coords[1].y),-->
<!--        )}deg)-->
<!--      `"-->
<!--            >-->
<!--                <div-->
<!--                    v-for="(arrow, arrow_index) in arrows_group.uniqueDirections"-->
<!--                    :key="arrow_index"-->
<!--                    :class="{-->
<!--                        'arrow-reversed':-->
<!--                            (!arrow.isOwnAction && arrows_group.cities[0] === currentCityId) ||-->
<!--                            (arrow.isOwnAction && arrows_group.cities[0] !== currentCityId),-->
<!--                        'd-none': !arrow.troopMovements.some(movement => movement.percentageDone < 100),-->
<!--                    }"-->
<!--                    class="arrow"-->
<!--                >-->
<!--                    <div class="arrow-tail" />-->
<!--                    <div class="arrow-body" />-->
<!--                    <div class="arrow-head" />-->
<!--                    <div-->
<!--                        v-for="(marker, marker_index) in arrow.troopMovements"-->
<!--                        :key="marker_index"-->
<!--                        src="../../assets/icons/map_pin_icon.svg"-->
<!--                        class="action-progress-indicator-wrapper"-->
<!--                        :style="`-->
<!--          left: ${marker.action === 'intercept' ? 100 - marker.percentageDone : marker.percentageDone}%;-->
<!--          transform: rotateZ(${-->
<!--              ((!arrow.isOwnAction && arrows_group.cities[0] === currentCityId) ||-->
<!--              (arrow.isOwnAction && arrows_group.cities[0] !== currentCityId)-->
<!--                  ? 180-->
<!--                  : 0) - -->
<!--              calculateAngleBetweenCities(-->
<!--                  calculateCityCenterX(arrows_group.coords[0].x, arrows_group.coords[0].y),-->
<!--                  calculateCityCenterY(arrows_group.coords[0].x, arrows_group.coords[0].y),-->
<!--                  calculateCityCenterX(arrows_group.coords[1].x, arrows_group.coords[1].y),-->
<!--                  calculateCityCenterY(arrows_group.coords[1].x, arrows_group.coords[1].y),-->
<!--              )-->
<!--          }deg) translateY(-${-->
<!--                            arrows_group.coords[0].y < arrows_group.coords[1].y &&-->
<!--                            ((!arrow.isOwnAction && arrows_group.cities[0] === currentCityId) ||-->
<!--                                (arrow.isOwnAction && arrows_group.cities[0] !== currentCityId))-->
<!--                                ? 20-->
<!--                                : arrows_group.coords[0].y > arrows_group.coords[1].y &&-->
<!--                                  ((!arrow.isOwnAction && arrows_group.cities[0] === currentCityId) ||-->
<!--                                      (arrow.isOwnAction && arrows_group.cities[0] !== currentCityId))-->
<!--                                ? 20-->
<!--                                : 95-->
<!--                        }%)-->
<!--        `"-->
<!--                    >-->
<!--                        <Transition name="tooltip-transition">-->
<!--                            <div v-if="indicatorSelectPanelVisible === marker.id" class="indicator-select-panel">-->
<!--                                <div-->
<!--                                    class="panel-indicator-wrapper"-->
<!--                                    v-for="(indicator, indicator_index) in stackedIndicatorGroups.find(group =>-->
<!--                                        group.find(m => m.id === marker.id),-->
<!--                                    )"-->
<!--                                    :key="indicator_index"-->
<!--                                    @click="toggleTroopInfoPanel(indicator.id)"-->
<!--                                    @mouseover="selectIndicator(indicator.id)"-->
<!--                                    @mouseleave="deselectIndicator(indicator.id)"-->
<!--                                >-->
<!--                                    <img-->
<!--                                        src="../../assets/icons/map_pin_icon.svg"-->
<!--                                        class="panel-indicator"-->
<!--                                        :class="{-->
<!--                                            'indicator-red': indicator.action === 'attack',-->
<!--                                            'indicator-black':-->
<!--                                                indicator.action === 'attack' &&-->
<!--                                                indicator.troops.find(troop => troop.troopsName.includes('General')),-->
<!--                                            'indicator-purple': indicator.action === 'returning_attack',-->
<!--                                            'indicator-blue': indicator.action === 'reinforcement',-->
<!--                                            'indicator-lightblue': indicator.action === 'returning_reinforcement',-->
<!--                                            'indicator-orange': indicator.action === 'spy_mission',-->
<!--                                            'indicator-yellow': indicator.action === 'returning_spies',-->
<!--                                            'indicator-brown': indicator.action === 'intercept',-->
<!--                                            'indicator-gray': indicator.action === 'returning_intercept',-->
<!--                                            // 'indicator-green': indicator.action === 'trading_transport',-->
<!--                                            // 'indicator-dark-green': indicator.action === 'send_resources_transport',-->
<!--                                            'd-none':-->
<!--                                                indicator.action === 'intercept'-->
<!--                                                    ? indicator.percentageDone >= indicator.interceptMeetingPoint-->
<!--                                                    : indicator.percentageDone >= 100,-->
<!--                                        }"-->
<!--                                    />-->
<!--                                    <span class="city-name-and-coords">-->
<!--                                        {{ getCityNameFromId(indicator.targetId) }}-->
<!--                                        <span>( {{ indicator.targetCoords.x }} | {{ indicator.targetCoords.y }} )</span>-->
<!--                                    </span>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </Transition>-->
<!--                        <Transition name="tooltip-transition">-->
<!--                            <div v-if="troopInfoPanelVisible === marker.id" class="troop-info-hover-panel">-->
<!--                                <div class="troop-info-rows-wrapper">-->
<!--                                    <div-->
<!--                                        v-for="(troop_name, troop_index) in Object.keys(calculateTroopsToShow(marker.troops))"-->
<!--                                        :key="troop_index"-->
<!--                                        class="troop-info-row"-->
<!--                                    >-->
<!--                                        <div class="troop-image-wrapper">-->
<!--                                            <img-->
<!--                                                :src="require(`../../assets/icons/troops/${paramCase(troop_name)}-tall.avif`)"-->
<!--                                                class="troop-image"-->
<!--                                            />-->
<!--                                        </div>-->
<!--                                        <span class="troop-number">-->
<!--                                            {{ calculateTroopsToShow(marker.troops)[troop_name] }}-->
<!--                                        </span>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <span-->
<!--                                    v-if="marker.action !== 'intercept'"-->
<!--                                    class="go-to-target-button"-->
<!--                                    @click="scrollToCity(marker.targetId, marker.targetCoords, 'smooth')"-->
<!--                                    @touchstart.stop-->
<!--                                    @mousedown.stop-->
<!--                                >-->
<!--                                    {{ getCityNameFromId(marker.targetId) }} ( {{ marker.targetCoords.x }} |-->
<!--                                    {{ marker.targetCoords.y }} )-->
<!--                                </span>-->
<!--                            </div>-->
<!--                        </Transition>-->
<!--                        <img-->
<!--                            :id="marker.id"-->
<!--                            src="../../assets/icons/map_pin_icon.svg"-->
<!--                            class="action-progress-indicator"-->
<!--                            :class="{-->
<!--                                'indicator-red': marker.action === 'attack',-->
<!--                                'indicator-black':-->
<!--                                    marker.action === 'attack' &&-->
<!--                                    marker.troops.find(troop => troop.troopsName.includes('General')),-->
<!--                                'indicator-purple': marker.action === 'returning_attack',-->
<!--                                'indicator-blue': marker.action === 'reinforcement',-->
<!--                                'indicator-lightblue': marker.action === 'returning_reinforcement',-->
<!--                                'indicator-orange': marker.action === 'spy_mission',-->
<!--                                'indicator-yellow': marker.action === 'returning_spies',-->
<!--                                'indicator-brown': marker.action === 'intercept',-->
<!--                                'indicator-gray': marker.action === 'returning_intercept',-->
<!--                                // 'indicator-green': marker.action === 'trading_transport',-->
<!--                                // 'indicator-dark-green': marker.action === 'send_resources_transport',-->
<!--                                'd-none':-->
<!--                                    marker.action === 'intercept'-->
<!--                                        ? marker.percentageDone >= marker.interceptMeetingPoint-->
<!--                                        : marker.percentageDone >= 100,-->
<!--                            }"-->
<!--                            @click="openTroopPanelOrIndicatorSelectPanel(marker.id)"-->
<!--                            @mouseover="selectEntireGroup(marker.id)"-->
<!--                            @mouseleave="deselectEntireGroup(marker.id)"-->
<!--                        />-->
<!--                    </div>-->
<!--                    <div-->
<!--                        v-for="(marker, marker_index) in arrow.troopMovements.filter(action => action.action === 'intercept')"-->
<!--                        :key="marker_index"-->
<!--                        class="intercept-meeting-point"-->
<!--                        :class="{-->
<!--                            'd-none': marker.percentageDone >= marker.interceptMeetingPoint,-->
<!--                        }"-->
<!--                        :style="`-->
<!--                    left: ${100 - marker.interceptMeetingPoint}%;-->
<!--					`"-->
<!--                    >-->
<!--                        &#10006;-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { paramCase } from 'change-case';
import {
    WORLD_MAP_WIDTH,
    WORLD_MAP_HEIGHT,
    MAP_TILES_VERTICALLY,
    MAP_TILES_HORIZONTALLY,
    MAP_TILE_WIDTH,
    MAP_TILE_HEIGHT,
    CITY_TILE_WIDTH,
    CITY_TILE_HEIGHT,
} from '@/utils/constants/map';
import MapTile from '@/components/WorldMap/MapTile';
import MapArrowsLayer from '@/components/WorldMap/MapArrowsLayer';

export default {
    name: 'WorldMap',
    components: { MapArrowsLayer, MapTile },
    props: {
        allCities: {
            type: Array,
            default: () => [],
        },
        attacks: {
            type: Array,
            default: () => [],
        },
        returningAttacks: {
            type: Array,
            default: () => [],
        },
        reinforcements: {
            type: Array,
            default: () => [],
        },
        returningReinforcements: {
            type: Array,
            default: () => [],
        },
        spyMissions: {
            type: Array,
            default: () => [],
        },
        returningSpies: {
            type: Array,
            default: () => [],
        },
        intercepts: {
            type: Array,
            default: () => [],
        },
        returningIntercepts: {
            type: Array,
            default: () => [],
        },
        tradingTransports: {
            type: Array,
            default: () => [],
        },
        returningTradingTransports: {
            type: Array,
            default: () => [],
        },
        sendResourcesTransports: {
            type: Array,
            default: () => [],
        },
        returningSendResourcesTransports: {
            type: Array,
            default: () => [],
        },
        mapScaleFactor: {
            type: Number,
            default: 1,
        },
        isMapCentered: {
            type: Boolean,
            default: false,
        },
		currentCityId: {
			type: String,
			default: '',
		},
		mapActions: {
			type: Set,
			default: () => new Set(),
		},
        worldMapScrollCoordsFromMinimap: {
            type: Object,
            default: () => {},
        },
        currentCityCoords: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        currentCityMapArrows() {
            const AllArrows = this.getMapArrows();
            const CurrentCityMapArrows = new Set();

            AllArrows.items.forEach(arrows_pair => {
                if (arrows_pair.ownCity._id === this.currentCityId) {
                    CurrentCityMapArrows.add(arrows_pair);
                }
            });

            console.log('CurrentCityMapArrows:::', CurrentCityMapArrows);
            return CurrentCityMapArrows;
        },
    },
    data() {
        return {
            WORLD_MAP_WIDTH,
            WORLD_MAP_HEIGHT,
            MAP_TILES_VERTICALLY,
            MAP_TILES_HORIZONTALLY,
            MAP_TILE_WIDTH,
            MAP_TILE_HEIGHT,

            mapMatrix: [],
            visibleTiles: [],
            visibleIndexes: {
                topIndex: null,
                bottomIndex: null,
                leftIndex: null,
                rightIndex: null,
            },

            decorationsMatrix: [],
            citiesToShowOnMap: [],
            indicatorSelectPanelVisible: null,
            troopInfoPanelVisible: null,
            centeredOnCity: null,
            worldMap: null,
            windowSize: {
                width: window.innerWidth,
                height: window.innerHeight,
            },
            mapActionsGroupedByCityPair: [],
            actionPercentageDone: 0,
            actionProgressInterval: null,
            coordsEmitStopped: false,
            progressIndicatorUpdateInterval: 5000,
            // throttle timers are objects so that they can be passed by reference
            // to throttle function, rather than by value
            throttleTimerCoordsEmit: {
                timer: null,
            },
            throttleTimerTileVisibility: {
                timer: null,
            },
            allIndicators: [],
            stackedIndicatorGroups: [],
        };
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
        // window.removeEventListener("mousedown", this.stopCoordsEmit);
        // window.removeEventListener("mouseup", this.allowCoordsEmit);
        clearInterval(this.actionProgressInterval);
    },
    created() {
        this.mapMatrix = this.getMapMatrix();
        this.decorationsMatrix = this.getDecorationsMatrix();
        this.mapActionsGroupedByCityPair = this.groupActionsByCityPair();
        console.log('actions:::', this.mapActionsGroupedByCityPair);
        this.$nextTick(() => {
            this.worldMap = document.getElementById('world-map-wrapper');
        });

        // this.startProgressIndicatorUpdate();

        window.addEventListener('resize', this.onResize);
        // window.addEventListener("mousedown", this.stopCoordsEmit);
        // window.addEventListener("mouseup", this.allowCoordsEmit);
        this.$nextTick(() => {
            this.scrollToCity(this.currentCityId, this.currentCityCoords, 'instant');
        });
    },
    watch: {
        mapScaleFactor: {
            handler() {
                this.setVisibleTiles();
                // stop view from sliding when zooming out on map border
                this.stopCoordsEmit();
                this.allowCoordsEmit();
            },
        },
        isMapCentered: {
            handler(isCentered) {
                if (isCentered) {
                    this.scrollToCity(this.currentCityId, this.currentCityCoords, 'smooth');
                }
            },
            deep: true,
        },
        worldMapScrollCoordsFromMinimap: {
            handler() {
                if (!this.coordsEmitStopped) {
                    const worldMap = document.getElementById('world-map-wrapper');
                    if (worldMap !== undefined) {
                        worldMap.scrollLeft = this.worldMapScrollCoordsFromMinimap.x;
                        worldMap.scrollTop = this.worldMapScrollCoordsFromMinimap.y;
                    }
                }
            },
            deep: true,
        },
        'allActions.length': {
            handler(newVal, oldVal) {
                if (newVal > oldVal) {
                    clearInterval(this.actionProgressInterval);
                    // this.startProgressIndicatorUpdate();
                }
            },
        },
        mapActionsGroupedByCityPair: {
            handler() {
                this.allIndicators = this.getAllIndicators();
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions(['getMap', 'getAllUsers']),
        ...mapGetters(['getMapMatrix', 'getDecorationsMatrix', 'getMapArrows']),
        paramCase,

        selectIndicator(action_id) {
            document.getElementById(action_id).classList.add('hovered');
        },

        deselectIndicator(action_id) {
            document.getElementById(action_id).classList.remove('hovered');
        },

        selectEntireGroup(action_id) {
            const GROUP = this.stackedIndicatorGroups.find(group => group.find(action => action.id === action_id));
            GROUP.forEach(indicator => this.selectIndicator(indicator.id));
        },

        deselectEntireGroup(action_id) {
            const GROUP = this.stackedIndicatorGroups.find(group => group.find(action => action.id === action_id));
            GROUP.forEach(indicator => this.deselectIndicator(indicator.id));
        },

        getAllIndicators() {
            const ALL_INDICATORS = [];

            this.mapActionsGroupedByCityPair.forEach(city_pair => {
                city_pair.uniqueDirections.forEach(unique_direction => {
                    unique_direction.troopMovements.forEach(troop_movement => {
                        ALL_INDICATORS.push(troop_movement);
                    });
                });
            });
            return ALL_INDICATORS;
        },

        getStackedIndicatorGroups() {
            const STACKED_INDICATOR_GROUPS = [];

            // Iterate over each indicator
            for (let i = 0; i < this.allIndicators.length; i++) {
                let indicatorStacked = false;

                // Iterate over each group of stacked indicators
                for (let j = 0; j < STACKED_INDICATOR_GROUPS.length; j++) {
                    // Iterate over each indicator in the current group
                    for (let k = 0; k < STACKED_INDICATOR_GROUPS[j].length; k++) {
                        // Check if the current indicator is stacked with any indicator in the current group
                        if (this.areIndicatorsStacked(this.allIndicators[i].id, STACKED_INDICATOR_GROUPS[j][k].id)) {
                            // If stacked, add the current indicator to the group and set indicatorStacked to true
                            STACKED_INDICATOR_GROUPS[j].push(this.allIndicators[i]);
                            indicatorStacked = true;
                            break;
                        }
                    }
                    // If the current indicator is stacked with any indicator in the current group,
                    // no need to check further groups
                    if (indicatorStacked) {
                        break;
                    }
                }

                // If the current indicator is not stacked with any existing group, create a new group for it
                if (!indicatorStacked) {
                    STACKED_INDICATOR_GROUPS.push([this.allIndicators[i]]);
                }
            }

            this.stackedIndicatorGroups = STACKED_INDICATOR_GROUPS;
        },

        areIndicatorsStacked(element1, element2) {
            const rect1 = document.getElementById(element1).getBoundingClientRect();
            const rect2 = document.getElementById(element2).getBoundingClientRect();

            // Calculate the overlap area
            const overlapWidth = Math.min(rect1.right, rect2.right) - Math.max(rect1.left, rect2.left);
            const overlapHeight = Math.min(rect1.bottom, rect2.bottom) - Math.max(rect1.top, rect2.top);
            const overlapArea = Math.max(0, overlapWidth) * Math.max(0, overlapHeight);

            // Check if the overlap area exceeds the threshold
            return overlapArea >= this.intersectionThreshold * this.indicatorArea;
        },

        // to be used temporarily
        getCityNameFromId(city_id) {
            if (!city_id) {
                return '';
            }
            return this.allCities.length ? this.allCities.find(city => city._id === city_id)?.name : '';
        },

        calculateTroopsToShow(troops_array) {
            const TROOPS = {};
            troops_array.forEach(array => {
                array.troopsName.forEach((troop, index) => {
                    if (TROOPS[troop]) {
                        TROOPS[troop] += array.numOfTroops[index];
                    } else {
                        TROOPS[troop] = array.numOfTroops[index];
                    }
                });
            });

            return TROOPS;
        },

        setVisibleTiles() {
            const FilteredMapMatrix = [];

            const CurrentIndexes = this.getVisibleIndexes();

            if (this.areIndexesTheSame(CurrentIndexes)) {
                this.setVisibleIndexes(CurrentIndexes);
                return;
            }

            for (let i = CurrentIndexes.topIndex; i <= CurrentIndexes.bottomIndex; i++) {
                const RowObject = {
                    tiles: [],
                    key: i,
                };
                for (let j = CurrentIndexes.leftIndex; j <= CurrentIndexes.rightIndex; j++) {
                    RowObject.tiles.push(this.mapMatrix[i][j]);
                }
                FilteredMapMatrix.push(RowObject);
            }

            this.setVisibleIndexes(CurrentIndexes);
            this.visibleTiles = FilteredMapMatrix;
        },

        getVisibleIndexes() {
            const topIndex = Math.max(Math.floor(this.worldMap.scrollTop / MAP_TILE_HEIGHT) - 1, 0);

            const bottomIndex = Math.min(
                Math.floor((this.worldMap.scrollTop + this.windowSize.height / this.mapScaleFactor) / MAP_TILE_HEIGHT) + 1,
                MAP_TILES_VERTICALLY - 1,
            );

            const leftIndex = Math.max(Math.floor(this.worldMap.scrollLeft / MAP_TILE_WIDTH) - 1, 0);

            const rightIndex = Math.min(
                Math.floor((this.worldMap.scrollLeft + this.windowSize.width / this.mapScaleFactor) / MAP_TILE_WIDTH) + 1,
                MAP_TILES_HORIZONTALLY - 1,
            );

            return {
                topIndex,
                bottomIndex,
                leftIndex,
                rightIndex,
            };
        },

        setVisibleIndexes(indexes) {
            this.visibleIndexes.topIndex = indexes.topIndex;
            this.visibleIndexes.bottomIndex = indexes.bottomIndex;
            this.visibleIndexes.leftIndex = indexes.leftIndex;
            this.visibleIndexes.rightIndex = indexes.rightIndex;
        },

        areIndexesTheSame(indexes) {
            return (
                this.visibleIndexes.topIndex === indexes.topIndex &&
                this.visibleIndexes.bottomIndex === indexes.bottomIndex &&
                this.visibleIndexes.leftIndex === indexes.leftIndex &&
                this.visibleIndexes.rightIndex === indexes.rightIndex
            );
        },

        throttle(func, timer, delay) {
            if (timer.timer === null) {
                func();
                timer.timer = setTimeout(() => {
                    timer.timer = null;
                    func();
                }, delay);
            }
        },

        convertDateStringToMiliseconds(date_string) {
            // TO DO: remove this exception when data arrives in correct form
            if (date_string.split('/').length === 1) {
                return parseInt(date_string);
            }

            const [datePart, timePart] = date_string.split(' ');
            const [day, month, year] = datePart.split('/');
            const [hour, minute, second, millisecond] = timePart.split(':');

            const dateObject = new Date(year, month - 1, day, hour, minute, second, millisecond);
            return dateObject.getTime();
        },

        scrollToCity(city_id, coords, behavior) {
            if (behavior === 'smooth') {
                this.centeredOnCity = city_id;
                setTimeout(() => {
                    this.centeredOnCity = null;
                }, 2000);
            }
            this.stopCoordsEmit();
            document.getElementById('world-map-wrapper').scrollTo({
                left: this.calculateCityCenterX(coords.x),
                top: this.calculateCityCenterY(coords.y),
                behavior: behavior,
            });
            setTimeout(() => {
                this.allowCoordsEmit();
            }, 1000);
        },

        calculateCityCenterX(x) {
            return x * CITY_TILE_WIDTH + CITY_TILE_WIDTH / 2 - this.windowSize.width / this.mapScaleFactor / 2;
        },

        calculateCityCenterY(y) {
            return y * CITY_TILE_HEIGHT + CITY_TILE_HEIGHT / 2 - this.windowSize.height / this.mapScaleFactor / 2;
        },

        calculateDistanceBetweenCities(currentX, currentY, targetX, targetY) {
            return Math.sqrt((targetX - currentX) ** 2 + (targetY - currentY) ** 2);
        },

        calculateAngleBetweenCities(currentX, currentY, targetX, targetY) {
            return Math.atan2(targetY - currentY, targetX - currentX) * (180 / Math.PI);
        },

        stopCoordsEmit() {
            this.coordsEmitStopped = true;
            window.addEventListener('mouseup', this.allowCoordsEmit);
            window.addEventListener('touchend', this.allowCoordsEmit);
        },

        allowCoordsEmit() {
            setTimeout(() => {
                this.coordsEmitStopped = false;
                window.removeEventListener('mouseup', this.allowCoordsEmit);
                window.removeEventListener('touchend', this.allowCoordsEmit);
            }, 100);
        },

        startProgressIndicatorUpdate() {
            let now = new Date().getTime() - 3600000;
            this.$nextTick(() => {
                this.calculateActionProgress(now);
                this.getStackedIndicatorGroups();
            });
            this.actionProgressInterval = setInterval(() => {
                now = new Date().getTime() - 3600000;
                this.calculateActionProgress(now);
                if (!this.mapActionsGroupedByCityPair.length) {
                    clearInterval(this.actionProgressInterval);
                }
            }, this.progressIndicatorUpdateInterval);
        },

        calculateActionProgress(now) {
            this.getStackedIndicatorGroups();
            for (let i = 0; i < this.mapActionsGroupedByCityPair.length; i++) {
                const uniqueDirections = this.mapActionsGroupedByCityPair[i].uniqueDirections;
                for (let j = 0; j < uniqueDirections.length; j++) {
                    const troopMovements = uniqueDirections[j].troopMovements;
                    for (let k = 0; k < troopMovements.length; k++) {
                        const currentAction = troopMovements[k];
                        if (currentAction.percentageDone < 100) {
                            const actionDuration = currentAction.end - currentAction.start;
                            const timeElapsed = now - currentAction.start;
                            if (currentAction.action === 'intercept') {
                                currentAction.percentageDone =
                                    (timeElapsed / actionDuration) * currentAction.interceptMeetingPoint;
                                continue;
                            }
                            currentAction.percentageDone =
                                currentAction.action === 'returning_intercept'
                                    ? currentAction.interceptMeetingPoint -
                                      (timeElapsed / actionDuration) * currentAction.interceptMeetingPoint
                                    : (timeElapsed / actionDuration) * 100;
                        }
                    }
                }
            }
        },

        onResize() {
            this.windowSize = {
                width: window.innerWidth,
                height: window.innerHeight,
            };
            this.$emit('window-resize', this.windowSize);
        },
        openTroopPanelOrIndicatorSelectPanel(action_id) {
            const GROUP = this.stackedIndicatorGroups.find(group => group.find(action => action.id === action_id));
            if (GROUP.length > 1) {
                this.toggleIndicatorSelectPanel(GROUP, action_id);
            } else {
                this.toggleTroopInfoPanel(action_id);
            }
        },
        toggleIndicatorSelectPanel(group, action_id) {
            if (group.find(action => action.id === this.indicatorSelectPanelVisible)) {
                this.indicatorSelectPanelVisible = null;
                return;
            }
            this.troopInfoPanelVisible = null;
            this.indicatorSelectPanelVisible = action_id;
        },
        toggleTroopInfoPanel(action_id) {
            if (this.troopInfoPanelVisible === action_id) {
                this.troopInfoPanelVisible = null;
                return;
            }
            this.indicatorSelectPanelVisible = null;
            this.troopInfoPanelVisible = action_id;
        },
        emitScrollCoords() {
            this.throttle(this.setVisibleTiles, this.throttleTimerTileVisibility, 100);
            this.$emit('world-map-scroll', {
                x: this.worldMap.scrollLeft,
                y: this.worldMap.scrollTop,
            });
        },
        groupActionsByCityPair() {
            // console.time('groupActionsByCityPair:');
            const reinforcementsNotArrivedYet = this.filterReinforcementsNotArrivedYet(this.reinforcements);
            const mapActions = {
                attacks: this.attacks,
				attacksReturning: this.returningAttacks,
                reinforcements: reinforcementsNotArrivedYet,
                returningReinforcements: this.returningReinforcements,
                spyMissions: this.spyMissions,
                returningSpies: this.returningSpies,
                returningIntercepts: this.returningIntercepts,
                // tradingTransports: this.tradingTransports.map(transport => {
                //     return {
                //         ...transport,
                //         toCity: transport.toCity._id,
                //         fromCity: transport.fromCity._id,
                //         troops: [],
                //         arrivalDateToShow: transport.arrivalDate,
                //     };
                // }),
                // returningTradingTransports: this.returningTradingTransports.map(transport => {
                //     return {
                //         ...transport,
                //         toCity: transport.toCity._id,
                //         fromCity: transport.fromCity._id,
                //         troops: [],
                //         arrivalDateToShow: transport.arrivalDate,
                //     };
                // }),
                // sendResourcesTransports: this.sendResourcesTransports.map(transport => {
                //     return {
                //         ...transport,
                //         toCity: transport.toCity._id,
                //         fromCity: transport.fromCity._id,
                //         troops: [],
                //         arrivalDateToShow: transport.arrivalDate,
                //     };
                // }),
                // returningSendResourcesTransports: this.returningSendResourcesTransports.map(transport => {
                //     return {
                //         ...transport,
                //         toCity: transport.toCity._id,
                //         fromCity: transport.fromCity._id,
                //         troops: [],
                //         arrivalDateToShow: transport.arrivalDate,
                //     };
                // }),
            };
            const mapActionsArray = [];
            // TO DO: put command type property on the map action object itself,
            // as soon as its received and set into state
            for (const type of Object.keys(mapActions)) {
                let actionType;
                switch (type) {
                    case 'attacks':
                        actionType = 'attack';
                        break;
                    case 'attacksReturning':
                        actionType = 'returning_attack';
                        break;
                    case 'reinforcements':
                        actionType = 'reinforcement';
                        break;
                    case 'returningReinforcements':
                        actionType = 'returning_reinforcement';
                        break;
                    case 'spyMissions':
                        actionType = 'spy_mission';
                        break;
                    case 'returningSpies':
                        actionType = 'returning_spies';
                        break;
                    case 'returningIntercepts':
                        actionType = 'returning_intercept';
                        break;
                    // case 'tradingTransports':
                    //     actionType = 'trading_transport';
                    //     break;
                    // case 'returningTradingTransports':
                    //     actionType = 'returning_trading_transport';
                    //     break;
                    // case 'sendResourcesTransports':
                    //     actionType = 'send_resources_transport';
                    //     break;
                    // case 'returningSendResourcesTransports':
                    //     actionType = 'returning_send_resources_transport';
                    //     break;
                }
                for (let i = 0; i < mapActions[type].length; i++) {
                    const cityPairObj = {
                        cities: [],
                        coords: [],
                        uniqueDirections: [],
                    };
                    const uniqueDirectionObj = {
                        isOwnAction: false,
                        troopMovements: [],
                        hasIntercept: false,
                    };

                    const troopMovementsObj = {
                        id: mapActions[type][i]._id,
                        action: actionType,
                        troops: mapActions[type][i].troops || mapActions[type][i].spies,
                        start: this.convertDateStringToMiliseconds(mapActions[type][i].dateCreated),
                        end: this.convertDateStringToMiliseconds(mapActions[type][i].arrivalDateToShow),
                        percentageDone: type === 'returning_intercept' ? 50 : 0,
                        targetId: mapActions[type][i].toCity?._id,
                        targetCoords: this.allCities.find(city => city._id === mapActions[type][i].toCity?._id)?.coords,
                    };

                    uniqueDirectionObj.troopMovements.push(troopMovementsObj);

                    // add intercepts to troopMovementsObject
                    if (type === 'attacks') {
                        let intercepts = this.intercepts.filter(
                            intercept => intercept.interceptedAttack === troopMovementsObj.id,
                        );
                        intercepts.forEach(intercept => {
                            const interceptObj = {
                                action: 'intercept',
                                id: intercept._id,
                                troops: intercept.troops,
                                start: this.convertDateStringToMiliseconds(intercept.dateCreated),
                                end: this.convertDateStringToMiliseconds(intercept.arrivalDateToShow),
                                interceptedAttack: troopMovementsObj.id,
                                interceptMeetingPoint: intercept.interceptPoint,
                                // interceptedAttackPercentageDone: 0,
                                percentageDone: 0,
                            };
                            uniqueDirectionObj.troopMovements.push(interceptObj);
                            uniqueDirectionObj.hasIntercept = true;
                        });
                    }

                    if (mapActions[type][i].fromCity?._id === this.currentCityId) {
                        uniqueDirectionObj.isOwnAction = true;
                    }

                    const samePairIndex = mapActionsArray.findIndex(
                        pair =>
                            pair.cities.includes(mapActions[type][i].toCity?._id) &&
                            pair.cities.includes(mapActions[type][i].fromCity?._id),
                    );
                    if (samePairIndex !== -1) {
                        if (
                            !mapActionsArray[samePairIndex].uniqueDirections.some(
                                action => action.isOwnAction === uniqueDirectionObj.isOwnAction,
                            )
                        ) {
                            mapActionsArray[samePairIndex].uniqueDirections.push(uniqueDirectionObj);
                        } else {
                            const sameUniqueActionIndex = mapActionsArray[samePairIndex].uniqueDirections.findIndex(
                                unique_action => unique_action.isOwnAction === uniqueDirectionObj.isOwnAction,
                            );
                            mapActionsArray[samePairIndex].uniqueDirections[sameUniqueActionIndex].troopMovements.push(
                                troopMovementsObj,
                            );
                            if (type === 'attacks') {
                                let intercepts = this.intercepts.filter(
                                    intercept => intercept.interceptedAttack === troopMovementsObj.id,
                                );
                                intercepts.forEach(intercept => {
                                    const interceptObj = {
                                        action: 'intercept',
                                        troops: intercept.troops,
                                        start: this.convertDateStringToMiliseconds(intercept.dateCreated),
                                        end: this.convertDateStringToMiliseconds(intercept.arrivalDateToShow),
                                        interceptedAttack: troopMovementsObj.id,
                                        interceptMeetingPoint: intercept.interceptPoint,
                                        // interceptedAttackPercentageDone: 0,
                                        percentageDone: 0,
                                    };
                                    mapActionsArray[samePairIndex].uniqueDirections[sameUniqueActionIndex].troopMovements.push(
                                        interceptObj,
                                    );
                                });
                            }
                        }
                    } else {
                        const originCityCoords = this.allCities?.find(city => city._id === mapActions[type][i].fromCity?._id).coords;
                        const targetCityCoords = this.allCities?.find(city => city._id === mapActions[type][i].toCity?._id).coords;
                        cityPairObj.cities.push(mapActions[type][i].fromCity?._id, mapActions[type][i].toCity?._id);
                        cityPairObj.coords.push(originCityCoords, targetCityCoords);
                        cityPairObj.uniqueDirections.push(uniqueDirectionObj);
                        mapActionsArray.push(cityPairObj);
                    }
                }
            }
            console.log('###', mapActionsArray);
            return mapActionsArray;
        },

        filterReinforcementsNotArrivedYet(reinforcements) {
            const reinforcementsNotArrived = reinforcements.filter(reinforcement => reinforcement.arrived === false);
            for (let i = 0; i < reinforcementsNotArrived.length; i++) {
                const reinforcementObj = reinforcementsNotArrived[i];
                reinforcementObj.mission = 'Reinforcing';
            }
            return reinforcementsNotArrived;
        },
    },
};
</script>

<style scoped>
#world-map-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}

.world-map {
    position: relative;
    overflow: hidden;
    background: #79664b;
    user-select: none;
    cursor: move;
}

.arrows-wrapper {
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transform-origin: left center;
}

.arrow {
    display: flex;
    align-items: center;
    width: 100%;
    filter: drop-shadow(0 0 2px black);
    -webkit-filter: drop-shadow(0 0 2px black);
}

.arrow-tail {
    width: 100px;
    height: 3px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), white);
}

.arrow-body {
    height: 3px;
    background: white;
    width: 100%;
}

.arrow-head {
    width: 10px;
    height: 10px;
    transform: translateX(-50%);
    background-image: url('../../assets/icons/arrowhead.png');
    background-size: cover;
}

.arrow-reversed {
    transform: rotateZ(180deg);
}

.action-progress-indicator-wrapper {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.25s;
}

.action-progress-indicator {
    width: 40px;
    height: 40px;
    /*top: -200%;*/
    opacity: 0.75;
    cursor: pointer;
}

/*.action-progress-indicator:hover {*/
/*    opacity: 1;*/
/*}*/

.action-progress-indicator.hovered {
    opacity: 1;
}

.indicator-select-panel {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: max-content;
    height: auto;
    bottom: calc(125% / v-bind(mapScaleFactor));
    left: 50%;
    transform: translateX(-50%) scale(v-bind(1 / mapScaleFactor));
    background: rgba(0, 0, 0, 0.8);
    border: 1px solid darkgray;
    padding: 0.5rem;
}

.panel-indicator-wrapper {
    display: flex;
    gap: 0.75rem;
    cursor: pointer;
}

.panel-indicator {
    width: 1.5rem;
    height: 1.5rem;
}

.city-name-and-coords {
    display: flex;
    column-gap: 0.125rem;
    flex-wrap: wrap;
    font-size: 0.75rem;
    color: palegoldenrod;
}

.city-name-and-coords:before {
    content: 'Target: ';
    color: ghostwhite;
}

.troop-info-hover-panel {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: max-content;
    height: auto;
    bottom: calc(125% / v-bind(mapScaleFactor));
    left: 50%;
    transform: translateX(-50%) scale(v-bind(1 / mapScaleFactor));
    background: rgba(0, 0, 0, 0.8);
    border: 1px solid darkgray;
    padding: 0.5rem;
}

.troop-info-rows-wrapper {
    column-count: 3;
    column-gap: 1rem;
    break-inside: avoid;
}

.troop-info-row {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.25rem;
}

.troop-image-wrapper {
    min-width: 1.75rem;
    height: 1.75rem;
    overflow: hidden;
}

.troop-image {
    width: 1.75rem;
    cursor: pointer;
}

.troop-number {
    font-size: 0.875rem;
    color: ghostwhite;
}

.go-to-target-button {
    color: cornflowerblue;
    text-decoration: underline;
    font-size: 0.75rem;
    line-height: 1;
    cursor: pointer;
    -moz-osx-font-smoothing: unset;
    -webkit-font-smoothing: unset;
}

.go-to-target-button:hover {
    text-decoration: none;
}

.intercept-arrow .arrow-tail,
.intercept-arrow .arrow-body,
.intercept-arrow .arrow-head {
    opacity: 0;
}

.intercept-meeting-point {
    color: brown;
    font-size: 2rem;
    position: absolute;
    transform: translateX(50%);
}

.indicator-top {
    top: -200%;
}

.indicator-bottom {
    bottom: -200%;
}

.indicator-red {
    filter: drop-shadow(-1px -1px 1px black);
    -webkit-filter: drop-shadow(-1px -1px 1px black);
}

.indicator-brown {
    filter: brightness(0.75) saturate(0.75) drop-shadow(-1px -1px 1px black);
    -webkit-filter: brightness(0.75) saturate(0.75) drop-shadow(-1px -1px 1px black);
}

.indicator-purple {
    filter: hue-rotate(290deg) brightness(1.25) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(290deg) brightness(1.25) drop-shadow(-1px -1px 1px black);
}

.indicator-blue {
    filter: hue-rotate(200deg) brightness(1.25) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(200deg) brightness(1.25) drop-shadow(-1px -1px 1px black);
}

.indicator-lightblue {
    filter: hue-rotate(200deg) brightness(2.25) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(200deg) brightness(2.25) drop-shadow(-1px -1px 1px black);
}

.indicator-green {
    filter: hue-rotate(130deg) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(130deg) drop-shadow(-1px -1px 1px black);
}

.indicator-dark-green {
    filter: hue-rotate(130deg) brightness(0.75) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(130deg) brightness(0.75) drop-shadow(-1px -1px 1px black);
}

.indicator-orange {
    filter: hue-rotate(50deg) brightness(3) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(50deg) brightness(3) drop-shadow(-1px -1px 1px black);
}

.indicator-yellow {
    filter: hue-rotate(70deg) brightness(5) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(70deg) brightness(5) drop-shadow(-1px -1px 1px black);
}

.indicator-white {
    filter: grayscale(1) brightness(10) drop-shadow(-1px -1px 1px black);
    -webkit-filter: grayscale(1) brightness(10) drop-shadow(-1px -1px 1px black);
}

.indicator-black {
    filter: grayscale(1) brightness(0) drop-shadow(-1px -1px 1px black);
    -webkit-filter: grayscale(1) brightness(0) drop-shadow(-1px -1px 1px black);
}

.indicator-gray {
    filter: grayscale(1) brightness(0.25) drop-shadow(-1px -1px 1px black);
    -webkit-filter: grayscale(1) brightness(0.25) drop-shadow(-1px -1px 1px black);
}
</style>
