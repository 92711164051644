<template>
    <div class="action-pin-wrapper">
        <img
            :id="action.originalData._id"
            src="../../assets/icons/map_pin_icon.svg"
            class="action-pin"
            :class="[ActionPinCssClass[action.actionType]]"
            @mouseover="$emit('pin-hovered', action.originalData._id)"
            @mouseleave="$emit('pin-unhovered')"
        />
    </div>
</template>

<script>
import MapAction from '@/classes/mapActions/MapAction';
import { ActionPinCssClass } from '@/utils/constants/actions';

export default {
    name: 'MapActionPin',
    props: {
        action: {
            type: MapAction,
            default: () => new MapAction(),
        },
        angleBetweenCities: {
            type: Number,
            default: 0,
        },
        isOutgoingAction: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['pin-hovered', 'pin-unhovered'],
    computed: {
        actionProgress() {
            return this.isOutgoingAction
                ? `${this.action.progressTracking.percentageDone}%`
                : `${100 - this.action.progressTracking.percentageDone}%`;
        },
        angle() {
            return this.angleBetweenCities < 0
                ? `${Math.abs(this.angleBetweenCities)}deg`
                : `-${Math.abs(this.angleBetweenCities)}deg`;
        },
    },
    data() {
        return {
            ActionPinCssClass,
        };
    },
};
</script>

<style scoped>
.action-pin-wrapper {
    z-index: 9;
    position: absolute;
    bottom: 0;
    left: v-bind(actionProgress);
    transform-origin: bottom;
    transform: translate(-50%, -12%) rotateZ(v-bind(angle));
    transition: all 1s linear;
}

.action-pin {
    width: 40px;
    height: 40px;
    opacity: 0.75;
    cursor: pointer;
}

.action-pin:hover,
.action-pin.hovered {
    opacity: 1;
}

.indicator-red {
    filter: drop-shadow(-1px -1px 1px black);
    -webkit-filter: drop-shadow(-1px -1px 1px black);
}

.indicator-brown {
    filter: brightness(0.75) saturate(0.75) drop-shadow(-1px -1px 1px black);
    -webkit-filter: brightness(0.75) saturate(0.75) drop-shadow(-1px -1px 1px black);
}

.indicator-purple {
    filter: hue-rotate(290deg) brightness(1.25) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(290deg) brightness(1.25) drop-shadow(-1px -1px 1px black);
}

.indicator-blue {
    filter: hue-rotate(200deg) brightness(1.25) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(200deg) brightness(1.25) drop-shadow(-1px -1px 1px black);
}

.indicator-lightblue {
    filter: hue-rotate(200deg) brightness(2.25) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(200deg) brightness(2.25) drop-shadow(-1px -1px 1px black);
}

.indicator-green {
    filter: hue-rotate(130deg) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(130deg) drop-shadow(-1px -1px 1px black);
}

.indicator-dark-green {
    filter: hue-rotate(130deg) brightness(0.75) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(130deg) brightness(0.75) drop-shadow(-1px -1px 1px black);
}

.indicator-orange {
    filter: hue-rotate(50deg) brightness(3) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(50deg) brightness(3) drop-shadow(-1px -1px 1px black);
}

.indicator-yellow {
    filter: hue-rotate(70deg) brightness(5) drop-shadow(-1px -1px 1px black);
    -webkit-filter: hue-rotate(70deg) brightness(5) drop-shadow(-1px -1px 1px black);
}

.indicator-white {
    filter: grayscale(1) brightness(10) drop-shadow(-1px -1px 1px black);
    -webkit-filter: grayscale(1) brightness(10) drop-shadow(-1px -1px 1px black);
}

.indicator-black {
    filter: grayscale(1) brightness(0) drop-shadow(-1px -1px 1px black);
    -webkit-filter: grayscale(1) brightness(0) drop-shadow(-1px -1px 1px black);
}

.indicator-gray {
    filter: grayscale(1) brightness(0.25) drop-shadow(-1px -1px 1px black);
    -webkit-filter: grayscale(1) brightness(0.25) drop-shadow(-1px -1px 1px black);
}
</style>
