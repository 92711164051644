import {
    ActionDescriptionIdentifier,
    ActionDescriptionTargetIdentifier,
    ActionInitiatorIdentifier,
    ActionTargetIdentifier,
} from '@/utils/constants/actions';
import { reactive } from 'vue';
import CollectionItem from '@/classes/generic/CollectionItem';
import ProgressTracking from '@/classes/mapActions/ProgressTracking';

export default class MapAction extends CollectionItem {
    originalData = null;
    actionType = null;

    progressTracking = null;

    initiator = {
        _id: '',
        name: '',
        x: 0,
        y: 0,
    };

    target = {
        _id: '',
        name: '',
        x: 0,
        y: 0,
    };

    actionDescription = '';
    actionDescriptionTarget = '';

    // !!!!!!!!! TEMPORARY, remove when you receive city coords on map actions !!!!!!!!!!!!!!
    allUsers = [];

    constructor({ action, action_type, all_users }) {
        super();
        this.allUsers = all_users;

        this.originalData = action;
        this.actionType = action_type;

        this.progressTracking = reactive(new ProgressTracking(this));

        this.initializeActionInfo();
    }

    initializeActionInfo() {
        this.target.name = this.getTargetName();
        this.target._id = this.getTargetId();

        this.initiator.name = this.getInitiatorName();
        this.initiator._id = this.getInitiatorId();

        let FoundCity = null;

        this.allUsers.forEach(user => {
            user.cities.forEach(city => {
                if (city._id === this.initiator._id) {
                    FoundCity = city;
                }
            });
        });

        this.initiator.x = FoundCity.x;
        this.initiator.y = FoundCity.y;

        this.allUsers.forEach(user => {
            user.cities.forEach(city => {
                if (city._id === this.target._id) {
                    FoundCity = city;
                }
            });
        });

        this.target.x = FoundCity.x;
        this.target.y = FoundCity.y;

        this.actionDescription = this.getActionDescription();
        this.actionDescriptionTarget = this.getActionDescriptionTarget();
    }

    getActionDescription() {
        return ActionDescriptionIdentifier[this.actionType];
    }

    getActionDescriptionTarget() {
        const PropertyToAccess = ActionDescriptionTargetIdentifier[this.actionType];

        return typeof this.originalData[PropertyToAccess] === 'object'
            ? this.originalData[PropertyToAccess].name
            : this.originalData[PropertyToAccess];
    }

    getInitiatorName() {
        const PropertyToAccess = ActionInitiatorIdentifier[this.actionType];

        return typeof this.originalData[PropertyToAccess] === 'object'
            ? this.originalData[PropertyToAccess].name
            : this.originalData[PropertyToAccess];
    }

    getInitiatorId() {
        const PropertyToAccess = ActionInitiatorIdentifier[this.actionType];

        return typeof this.originalData[PropertyToAccess] === 'object'
            ? this.originalData[PropertyToAccess]._id
            : this.originalData[PropertyToAccess];
    }

    getTargetName() {
        const PropertyToAccess = ActionTargetIdentifier[this.actionType];

        return typeof this.originalData[PropertyToAccess] === 'object'
            ? this.originalData[PropertyToAccess].name
            : this.originalData[PropertyToAccess];
    }

    getTargetId() {
        const PropertyToAccess = ActionTargetIdentifier[this.actionType];

        return typeof this.originalData[PropertyToAccess] === 'object'
            ? this.originalData[PropertyToAccess]._id
            : this.originalData[PropertyToAccess];
    }

    startCountdown() {
        this.progressTracking.startProgressUpdate(this);
    }

    stopCountdown() {
        this.progressTracking.stopProgressUpdate();
    }
}
