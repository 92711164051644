<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ commercialZone?.name }}</h5>
                    <div class="building-description">
                        {{ commercialZone?.description }}
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal">
                        <img src="../../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <ul class="nav nav-tabs" id="commercial-center-nav" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link active"
                                id="exchange"
                                data-bs-toggle="tab"
                                data-bs-target="#exchange-page"
                                type="button"
                                role="tab"
                                aria-controls="exchange-tab"
                                aria-selected="true"
                            >
                                Exchange
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="create-offer"
                                data-bs-toggle="tab"
                                data-bs-target="#create-offer-page"
                                type="button"
                                role="tab"
                                aria-controls="create-offer-tab"
                                aria-selected="false"
                            >
                                Create Offer
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="send-resources"
                                data-bs-toggle="tab"
                                data-bs-target="#send-resources-page"
                                type="button"
                                role="tab"
                                aria-controls="send-resources-tab"
                                aria-selected="false"
                            >
                                Send Resources
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="transports"
                                data-bs-toggle="tab"
                                data-bs-target="#transports-page"
                                type="button"
                                role="tab"
                                aria-controls="transports-tab"
                                aria-selected="false"
                            >
                                Transports
                            </button>
                        </li>
                        <li class="nav-filler" />
                    </ul>
                    <div class="tab-content" id="commercial-center-pages">
                        <div class="tab-pane fade show active" id="exchange-page" role="tabpanel" aria-labelledby="exchange-tab">
                            <div class="trade-filters-wrapper mb-4">
                                <div class="row standalone-label">Traders: {{ tradersAvailableString }}</div>
                                <div class="row standalone-label">Trader Resource Capacity: {{ maximumTraderCapacity }}</div>
                                <div class="row standalone-label">Available Trucks: {{ trucksAvailable }}</div>
                                <div class="row standalone-label">Truck Resource Capacity: {{ maximumTruckCapacity }}</div>
                                <div class="trade-filters pt-4">
                                    <h6 class="pb-2">Filter Offers:</h6>
                                    <div class="row">
                                        <div class="col standalone-label">I Want</div>
                                        <div class="col">
                                            <div
                                                class="form-check"
                                                :class="{ checked: wantedResourceExchange === ResourceIdentifier.ALUMINUM }"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="wanted-resources-group-exchange"
                                                    id="wanted-aluminum-exchange"
                                                    step="100"
                                                    :value="ResourceIdentifier.ALUMINUM"
                                                    v-model="wantedResourceExchange"
                                                    @click="disableWantedIfSelected(ResourceIdentifier.ALUMINUM)"
                                                />
                                                <label class="form-check-label" for="wanted-aluminum-exchange">
                                                    <div class="icon-tooltip">Aluminum</div>
                                                    <img src="../../assets/icons/aluminum_icon.svg" class="offer-icon" />
                                                </label>
                                            </div>
                                            <div
                                                class="form-check"
                                                :class="{ checked: wantedResourceExchange === ResourceIdentifier.OIL }"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="wanted-resources-group-exchange"
                                                    id="wanted-oil-exchange"
                                                    step="100"
                                                    :value="ResourceIdentifier.OIL"
                                                    v-model="wantedResourceExchange"
                                                    @click="disableWantedIfSelected(ResourceIdentifier.OIL)"
                                                />
                                                <label class="form-check-label" for="wanted-oil-exchange">
                                                    <div class="icon-tooltip">Oil</div>
                                                    <img src="../../assets/icons/oil_icon.svg" class="offer-icon" />
                                                </label>
                                            </div>
                                            <div
                                                class="form-check"
                                                :class="{ checked: wantedResourceExchange === ResourceIdentifier.METAL }"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="wanted-resources-group-exchange"
                                                    id="wanted-metal-exchange"
                                                    step="100"
                                                    :value="ResourceIdentifier.METAL"
                                                    v-model="wantedResourceExchange"
                                                    @click="disableWantedIfSelected(ResourceIdentifier.METAL)"
                                                />
                                                <label class="form-check-label" for="wanted-metal-exchange">
                                                    <div class="icon-tooltip">Metal</div>
                                                    <img src="../../assets/icons/metal_icon.svg" class="offer-icon" />
                                                </label>
                                            </div>
                                            <div
                                                class="form-check"
                                                :class="{ checked: wantedResourceExchange === ResourceIdentifier.RATIONS }"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="wanted-resources-group-exchange"
                                                    id="wanted-rations-exchange"
                                                    step="100"
                                                    :value="ResourceIdentifier.RATIONS"
                                                    v-model="wantedResourceExchange"
                                                    @click="disableWantedIfSelected(ResourceIdentifier.RATIONS)"
                                                />
                                                <label class="form-check-label" for="wanted-rations-exchange">
                                                    <div class="icon-tooltip">Rations</div>
                                                    <img src="../../assets/icons/rations_icon.svg" class="offer-icon" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col standalone-label">I Offer</div>
                                        <div class="col">
                                            <div
                                                class="form-check"
                                                :class="{ checked: offeredResourceExchange === ResourceIdentifier.ANY }"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="offered-resources-group-exchange"
                                                    id="offered-any-exchange"
                                                    step="100"
                                                    :value="ResourceIdentifier.ANY"
                                                    v-model="offeredResourceExchange"
                                                    @click="disableOfferedIfSelected(ResourceIdentifier.ANY)"
                                                />
                                                <label class="form-check-label" for="offered-any-exchange">
                                                    <div class="icon-tooltip">Any</div>
                                                    <img src="../../assets/icons/interface/storage_icon.svg" class="offer-icon" />
                                                </label>
                                            </div>
                                            <div
                                                class="form-check"
                                                :class="{ checked: offeredResourceExchange === ResourceIdentifier.ALUMINUM }"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="offered-resources-group-exchange"
                                                    id="offered-aluminum-exchange"
                                                    step="100"
                                                    :value="ResourceIdentifier.ALUMINUM"
                                                    v-model="offeredResourceExchange"
                                                    @click="disableOfferedIfSelected(ResourceIdentifier.ALUMINUM)"
                                                />
                                                <label class="form-check-label" for="offered-aluminum-exchange">
                                                    <div class="icon-tooltip">Aluminum</div>
                                                    <img src="../../assets/icons/aluminum_icon.svg" class="offer-icon" />
                                                </label>
                                            </div>
                                            <div
                                                class="form-check"
                                                :class="{ checked: offeredResourceExchange === ResourceIdentifier.OIL }"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="offered-resources-group-exchange"
                                                    id="offered-oil-exchange"
                                                    step="100"
                                                    :value="ResourceIdentifier.OIL"
                                                    v-model="offeredResourceExchange"
                                                    @click="disableOfferedIfSelected(ResourceIdentifier.OIL)"
                                                />
                                                <label class="form-check-label" for="offered-oil-exchange">
                                                    <div class="icon-tooltip">Oil</div>
                                                    <img src="../../assets/icons/oil_icon.svg" class="offer-icon" />
                                                </label>
                                            </div>
                                            <div
                                                class="form-check"
                                                :class="{ checked: offeredResourceExchange === ResourceIdentifier.METAL }"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="offered-resources-group-exchange"
                                                    id="offered-metal-exchange"
                                                    step="100"
                                                    :value="ResourceIdentifier.METAL"
                                                    v-model="offeredResourceExchange"
                                                    @click="disableOfferedIfSelected(ResourceIdentifier.METAL)"
                                                />
                                                <label class="form-check-label" for="offered-metal-exchange">
                                                    <div class="icon-tooltip">Metal</div>
                                                    <img src="../../assets/icons/metal_icon.svg" class="offer-icon" />
                                                </label>
                                            </div>
                                            <div
                                                class="form-check"
                                                :class="{ checked: offeredResourceExchange === ResourceIdentifier.RATIONS }"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="offered-resources-group-exchange"
                                                    id="offered-rations-exchange"
                                                    step="100"
                                                    :value="ResourceIdentifier.RATIONS"
                                                    v-model="offeredResourceExchange"
                                                    @click="disableOfferedIfSelected(ResourceIdentifier.RATIONS)"
                                                />
                                                <label class="form-check-label" for="offered-rations-exchange">
                                                    <div class="icon-tooltip">Rations</div>
                                                    <img src="../../assets/icons/rations_icon.svg" class="offer-icon" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col standalone-label">Time Limit</div>
                                        <div class="col">
                                            <select v-model="maxTravelTimeExchange" class="form-select">
                                                <option :value="Infinity" selected>None</option>
                                                <option :value="24">24 Hours</option>
                                                <option :value="12">12 Hours</option>
                                                <option :value="6">6 Hours</option>
                                                <option :value="5">5 Hours</option>
                                                <option :value="4">4 Hours</option>
                                                <option :value="3">3 Hours</option>
                                                <option :value="2">2 Hours</option>
                                                <option :value="1">1 Hour</option>
                                                <option :value="0.5">30 Minutes</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <TableWithPagination :all-rows="filteredOffers" no-items-text="No offers found" v-slot="props">
                                <div class="trade-offers-wrapper">
                                    <div class="row label">
                                        <div class="col">I offer</div>
                                        <div class="col">I receive</div>
                                        <div class="col large">Player</div>
                                        <div class="col">Distance</div>
                                        <div class="col small d-none d-sm-flex">Ratio</div>
                                        <div class="col small d-none d-sm-flex">Offers</div>
                                        <div class="col opacity-0"></div>
                                    </div>
                                    <div
                                        v-for="outside_offer in props.currentRows"
                                        :key="`outside-offer-${outside_offer._id}`"
                                        :class="{
                                            inactive: outside_offer.toBeRemoved === true,
                                            outline: outside_offer.newValue === true,
                                        }"
                                        class="row"
                                    >
                                        <div class="col resource-column">
                                            <div class="icon-tooltip capitalized">{{ outside_offer.resourceToGet?.name }}</div>
                                            <img
                                                :src="
                                                    require(`../../assets/icons/${
                                                        outside_offer.resourceToGet?.name === ResourceIdentifier.ANY
                                                            ? 'interface/storage'
                                                            : outside_offer.resourceToGet?.name
                                                    }_icon.svg`)
                                                "
                                                class="offer-icon"
                                            />
                                            <span class="offer-quantity">{{ outside_offer.resourceToGet?.quantity }}</span>
                                        </div>
                                        <div class="col resource-column">
                                            <div class="icon-tooltip capitalized">{{ outside_offer.resourceToOffer?.name }}</div>
                                            <img
                                                :src="
                                                    require(`../../assets/icons/${
                                                        outside_offer.resourceToOffer?.name === ResourceIdentifier.ANY
                                                            ? 'interface/storage'
                                                            : outside_offer.resourceToOffer?.name
                                                    }_icon.svg`)
                                                "
                                                class="offer-icon"
                                            />
                                            <span class="offer-quantity">{{ outside_offer.resourceToOffer?.quantity }}</span>
                                        </div>
                                        <div class="col large">{{ outside_offer.fromUser?.username }}</div>
                                        <div class="col">
                                            {{ outside_offer.travelTimeToShow }}
                                        </div>
                                        <div class="col small d-none d-sm-flex">
                                            {{
                                                (
                                                    outside_offer.resourceToGet?.quantity /
                                                    outside_offer.resourceToOffer?.quantity
                                                ).toFixed(1)
                                            }}
                                        </div>
                                        <div class="col small">
                                            {{ outside_offer.repeats }}
                                        </div>
                                        <div class="col">
                                            <button
                                                v-if="canAcceptOffer(outside_offer)"
                                                class="btn yellow-button"
                                                @click="openTradeConfirmationModal(outside_offer._id)"
                                                :disabled="outside_offer.toBeRemoved === true"
                                            >
                                                Accept
                                            </button>
                                            <span v-else>{{ getValidationMessage(outside_offer) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </TableWithPagination>
                        </div>
                        <div class="tab-pane fade" id="create-offer-page" role="tabpanel" aria-labelledby="create-offer-tab">
                            <div class="create-offer-wrapper">
                                <div class="row standalone-label">Traders: {{ tradersAvailableString }}</div>
                                <div class="row standalone-label">Trader Resource Capacity: {{ maximumTraderCapacity }}</div>
                                <div class="row standalone-label">Available Trucks: {{ trucksAvailable }}</div>
                                <div class="row standalone-label">Truck Resource Capacity: {{ maximumTruckCapacity }}</div>
                                <div class="trade-filters pt-4">
                                    <h6>Create an Offer</h6>
                                    <div class="row">
                                        <div class="col standalone-label">I Want</div>
                                        <div class="col input-wrapper">
                                            <input
                                                class="form-control"
                                                type="number"
                                                min="0"
                                                step="100"
                                                v-model="wantedResourceValueCreate"
                                            />
                                        </div>
                                        <div class="col wrap">
                                            <div
                                                class="form-check"
                                                :class="{ checked: wantedResourceCreate === ResourceIdentifier.ANY }"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="wanted-resources-group-create"
                                                    id="wanted-any-create"
                                                    step="100"
                                                    :value="ResourceIdentifier.ANY"
                                                    v-model="wantedResourceCreate"
                                                />
                                                <label class="form-check-label" for="wanted-any-create">
                                                    <div class="icon-tooltip">Any</div>
                                                    <img src="../../assets/icons/interface/storage_icon.svg" class="offer-icon" />
                                                </label>
                                            </div>
                                            <div
                                                class="form-check"
                                                :class="{ checked: wantedResourceCreate === ResourceIdentifier.ALUMINUM }"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="wanted-resources-group-create"
                                                    id="wanted-aluminum-create"
                                                    step="100"
                                                    :value="ResourceIdentifier.ALUMINUM"
                                                    v-model="wantedResourceCreate"
                                                />
                                                <label class="form-check-label" for="wanted-aluminum-create">
                                                    <div class="icon-tooltip">Aluminum</div>
                                                    <img src="../../assets/icons/aluminum_icon.svg" class="offer-icon" />
                                                </label>
                                            </div>
                                            <div
                                                class="form-check"
                                                :class="{ checked: wantedResourceCreate === ResourceIdentifier.OIL }"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="wanted-resources-group-create"
                                                    id="wanted-oil-create"
                                                    step="100"
                                                    :value="ResourceIdentifier.OIL"
                                                    v-model="wantedResourceCreate"
                                                />
                                                <label class="form-check-label" for="wanted-oil-create">
                                                    <div class="icon-tooltip">Oil</div>
                                                    <img src="../../assets/icons/oil_icon.svg" class="offer-icon" />
                                                </label>
                                            </div>
                                            <div
                                                class="form-check"
                                                :class="{ checked: wantedResourceCreate === ResourceIdentifier.METAL }"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="wanted-resources-group-create"
                                                    id="wanted-metal-create"
                                                    step="100"
                                                    :value="ResourceIdentifier.METAL"
                                                    v-model="wantedResourceCreate"
                                                />
                                                <label class="form-check-label" for="wanted-metal-create">
                                                    <div class="icon-tooltip">Metal</div>
                                                    <img src="../../assets/icons/metal_icon.svg" class="offer-icon" />
                                                </label>
                                            </div>
                                            <div
                                                class="form-check"
                                                :class="{ checked: wantedResourceCreate === ResourceIdentifier.RATIONS }"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="wanted-resources-group-create"
                                                    id="wanted-rations-create"
                                                    step="100"
                                                    :value="ResourceIdentifier.RATIONS"
                                                    v-model="wantedResourceCreate"
                                                />
                                                <label class="form-check-label" for="wanted-rations-create">
                                                    <div class="icon-tooltip">Rations</div>
                                                    <img src="../../assets/icons/rations_icon.svg" class="offer-icon" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col standalone-label">I Offer</div>
                                        <div class="col input-wrapper">
                                            <input
                                                class="form-control"
                                                type="number"
                                                min="0"
                                                step="100"
                                                v-model="offeredResourceValueCreate"
                                            />
                                        </div>
                                        <div class="col wrap">
                                            <div
                                                class="form-check"
                                                :class="{ checked: offeredResourceCreate === ResourceIdentifier.ALUMINUM }"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="offered-resources-group-create"
                                                    id="offered-aluminum-create"
                                                    step="100"
                                                    :value="ResourceIdentifier.ALUMINUM"
                                                    v-model="offeredResourceCreate"
                                                />
                                                <label class="form-check-label" for="offered-aluminum-create">
                                                    <div class="icon-tooltip">Aluminum</div>
                                                    <img src="../../assets/icons/aluminum_icon.svg" class="offer-icon" />
                                                </label>
                                            </div>
                                            <div
                                                class="form-check"
                                                :class="{ checked: offeredResourceCreate === ResourceIdentifier.OIL }"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="offered-resources-group-create"
                                                    id="offered-oil-create"
                                                    step="100"
                                                    :value="ResourceIdentifier.OIL"
                                                    v-model="offeredResourceCreate"
                                                />
                                                <label class="form-check-label" for="offered-oil-create">
                                                    <div class="icon-tooltip">Oil</div>
                                                    <img src="../../assets/icons/oil_icon.svg" class="offer-icon" />
                                                </label>
                                            </div>
                                            <div
                                                class="form-check"
                                                :class="{ checked: offeredResourceCreate === ResourceIdentifier.METAL }"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="offered-resources-group-create"
                                                    id="offered-metal-create"
                                                    step="100"
                                                    :value="ResourceIdentifier.METAL"
                                                    v-model="offeredResourceCreate"
                                                />
                                                <label class="form-check-label" for="offered-metal-create">
                                                    <div class="icon-tooltip">Metal</div>
                                                    <img src="../../assets/icons/metal_icon.svg" class="offer-icon" />
                                                </label>
                                            </div>
                                            <div
                                                class="form-check"
                                                :class="{ checked: offeredResourceCreate === ResourceIdentifier.RATIONS }"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="offered-resources-group-create"
                                                    id="offered-rations-create"
                                                    step="100"
                                                    :value="ResourceIdentifier.RATIONS"
                                                    v-model="offeredResourceCreate"
                                                />
                                                <label class="form-check-label" for="offered-rations-create">
                                                    <div class="icon-tooltip">Rations</div>
                                                    <img src="../../assets/icons/rations_icon.svg" class="offer-icon" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col standalone-label">Number of Offers</div>
                                        <div class="col input-wrapper">
                                            <input class="form-control" type="number" min="1" v-model="numberOfOffersCreate" />
                                        </div>
                                        <div class="col opacity-0"></div>
                                    </div>
                                    <div class="row">
                                        <div class="col standalone-label">Maximum Travel Time (h)</div>
                                        <div class="col input-wrapper">
                                            <input class="form-control" type="number" min="1" v-model="maxTravelTimeCreate" />
                                        </div>
                                        <div class="col opacity-0"></div>
                                    </div>
                                    <div class="row">
                                        <div class="col standalone-label">Traders Needed</div>
                                        <div class="col input-wrapper">
                                            {{ tradersNeededForTrade }}
                                        </div>
                                        <div class="col opacity-0"></div>
                                    </div>
                                    <div class="row">
                                        <div class="col standalone-label">Trucks Needed</div>
                                        <div class="col input-wrapper">
                                            {{ trucksNeededForTrade }}
                                        </div>
                                        <div class="col opacity-0"></div>
                                    </div>
                                </div>
                                <div class="truck-select-table">
                                    <div class="row label">
                                        <div class="col">Unit</div>
                                        <div class="col">Owned</div>
                                        <div class="col">Controlled</div>
                                    </div>
                                    <TruckSelect
                                        :current-city-id="currentCityId"
                                        :trucks="trucks"
                                        :reinforcements="reinforcements"
                                    />
                                </div>
                            </div>
                            <button class="btn blue-button mt-3 w-25" @click="createOfferCommand()">Create</button>
                            <div class="created-offers-wrapper">
                                <h6 class="section-title">Your Offers</h6>
                                <div class="row label">
                                    <div class="col">Offer</div>
                                    <div class="col">For</div>
                                    <div class="col">Maximum Distance</div>
                                    <div class="col small d-none d-sm-flex">Ratio</div>
                                    <div class="col small">Offers</div>
                                    <div class="col">Trade</div>
                                </div>
                                <div
                                    v-for="current_city_offer in currentCityOffers"
                                    :key="`current-city-offer-${current_city_offer._id}`"
                                    class="row"
                                >
                                    <div class="col resource-column">
                                        <div class="icon-tooltip capitalized">{{ current_city_offer.resourceToOffer.name }}</div>
                                        <img
                                            :src="
                                                require(`../../assets/icons/${
                                                    current_city_offer.resourceToOffer.name === ResourceIdentifier.ANY
                                                        ? 'interface/storage'
                                                        : current_city_offer.resourceToOffer.name
                                                }_icon.svg`)
                                            "
                                            class="offer-icon"
                                        />
                                        <span class="offer-quantity">{{ current_city_offer.resourceToOffer.quantity }}</span>
                                    </div>
                                    <div class="col resource-column">
                                        <div class="icon-tooltip capitalized">{{ current_city_offer.resourceToGet.name }}</div>
                                        <img
                                            :src="
                                                require(`../../assets/icons/${
                                                    current_city_offer.resourceToGet.name === ResourceIdentifier.ANY
                                                        ? 'interface/storage'
                                                        : current_city_offer.resourceToGet.name
                                                }_icon.svg`)
                                            "
                                            class="offer-icon"
                                        />
                                        <span class="offer-quantity">{{ current_city_offer.resourceToGet.quantity }}</span>
                                    </div>
                                    <div class="col">{{ current_city_offer.maximumTime }}h</div>
                                    <div class="col small d-none d-sm-flex">
                                        {{
                                            (
                                                current_city_offer.resourceToGet.quantity /
                                                current_city_offer.resourceToOffer.quantity
                                            ).toFixed(1)
                                        }}
                                    </div>
                                    <div class="col small">{{ current_city_offer.repeats }}</div>
                                    <div class="col">
                                        <button
                                            class="btn red-button"
                                            @click="
                                                setConfirmationModalProps({
                                                    title: 'Are you sure you want to cancel this offer?',
                                                    action: () => cancelOfferCommand(current_city_offer._id),
                                                })
                                            "
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="send-resources-page" role="tabpanel" aria-labelledby="send-resources-tab">
                            <div class="send-resources-wrapper">
                                <div class="row standalone-label">Traders: {{ tradersAvailableString }}</div>
                                <div class="row standalone-label">Trader Resource Capacity: {{ maximumTraderCapacity }}</div>
                                <div class="row standalone-label">Available Trucks: {{ trucksAvailable }}</div>
                                <div class="row standalone-label">Truck Resource Capacity: {{ maximumTruckCapacity }}</div>
                            </div>
                            <div class="send-resources-section">
                                <div class="resources-wrapper">
                                    <h6 class="section-title pb-2">Send Resources</h6>
                                    <div class="row standalone-label">Resources</div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="resource-group">
                                                <span>Aluminum</span>
                                                <img src="../../assets/icons/aluminum_icon.svg" class="offer-icon" />
                                            </div>
                                            <input
                                                class="form-control"
                                                type="number"
                                                v-model="resourcesToSend.aluminum"
                                                min="0"
                                                :max="resources.aluminum"
                                                step="100"
                                            />
                                            <span
                                                class="max-send-resources"
                                                @click="setMaxResourcesToSend(ResourceIdentifier.ALUMINUM)"
                                                >({{ resources.aluminum }})</span
                                            >
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="resource-group">
                                                <span>Oil</span>
                                                <img src="../../assets/icons/oil_icon.svg" class="offer-icon" />
                                            </div>
                                            <input
                                                class="form-control"
                                                type="number"
                                                v-model="resourcesToSend.oil"
                                                min="0"
                                                :max="resources.oil"
                                                step="100"
                                            />
                                            <span
                                                class="max-send-resources"
                                                @click="setMaxResourcesToSend(ResourceIdentifier.OIL)"
                                                >({{ resources.oil }})</span
                                            >
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="resource-group">
                                                <span>Metal</span>
                                                <img src="../../assets/icons/metal_icon.svg" class="offer-icon" />
                                            </div>
                                            <input
                                                class="form-control"
                                                type="number"
                                                v-model="resourcesToSend.metal"
                                                min="0"
                                                :max="resources.metal"
                                                step="100"
                                            />
                                            <span
                                                class="max-send-resources"
                                                @click="setMaxResourcesToSend(ResourceIdentifier.METAL)"
                                                >({{ resources.metal }})</span
                                            >
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="resource-group">
                                                <span>Rations</span>
                                                <img src="../../assets/icons/rations_icon.svg" class="offer-icon" />
                                            </div>
                                            <input
                                                class="form-control"
                                                type="number"
                                                v-model="resourcesToSend.rations"
                                                min="0"
                                                :max="resources.rations"
                                                step="100"
                                            />
                                            <span
                                                class="max-send-resources"
                                                @click="setMaxResourcesToSend(ResourceIdentifier.RATIONS)"
                                                >({{ resources.rations }})</span
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="city-finder-wrapper">
                                    <div class="truck-select-table">
                                        <div class="row label">
                                            <div class="col">Unit</div>
                                            <div class="col">Owned</div>
                                            <div class="col">Controlled</div>
                                        </div>
                                        <TruckSelect
                                            :current-city-id="currentCityId"
                                            :trucks="trucks"
                                            :reinforcements="reinforcements"
                                        />
                                    </div>
                                    <CityFinder
                                        id="city-finder-commercial-zone"
                                        :all-cities="allCities"
                                        @city-selected="city => setSendResourcesTarget(city)"
                                    />
                                    <div class="row">
                                        <div class="col send-resources-target">
                                            {{
                                                sendResourcesTarget ? getFormattedSearchResult(sendResourcesTarget) : 'No target'
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button class="btn yellow-button mt-3 w-25 ml-auto" @click="sendResourcesCommand()">Send</button>
                        </div>
                        <div class="tab-pane fade" id="transports-page" role="tabpanel" aria-labelledby="transports-tab">
                            <div class="transports-wrapper">
                                <div class="transports-info-wrapper">
                                    <div class="row standalone-label">Traders: {{ tradersAvailableString }}</div>
                                    <div class="row standalone-label">Trader Resource Capacity: {{ maximumTraderCapacity }}</div>
                                    <div class="row standalone-label">Available Trucks: {{ trucksAvailable }}</div>
                                    <div class="row standalone-label">Truck Resource Capacity: {{ maximumTruckCapacity }}</div>
                                </div>
                                <div class="transports-info-wrapper">
                                    <div class="row standalone-label">
                                        Outgoing:
                                        <div
                                            v-for="resource in outgoingResources"
                                            :key="resource.name"
                                            class="transports-info-group"
                                        >
                                            <img
                                                :src="require(`../../assets/icons/${resource.name}_icon.svg`)"
                                                class="transports-info-icon"
                                            />
                                            <span>{{ resource.quantity }}</span>
                                        </div>
                                    </div>
                                    <div class="row standalone-label">
                                        Incoming:
                                        <div
                                            v-for="resource in incomingResources"
                                            :key="resource.name"
                                            class="transports-info-group"
                                        >
                                            <img
                                                :src="require(`../../assets/icons/${resource.name}_icon.svg`)"
                                                class="transports-info-icon"
                                            />
                                            <span>{{ resource.quantity }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="your-transports-wrapper">
                                <h6 class="section-title">Your Transports</h6>
                                <div class="row label">
                                    <div class="col">Action</div>
                                    <div class="col">Payload</div>
                                    <div class="col d-none d-sm-flex">Traders</div>
                                    <div class="col d-none d-sm-flex">Trucks</div>
                                    <div class="col">Arrival Date</div>
                                    <div class="col">Time Until Arrival</div>
                                </div>
                                <div v-for="transport in userTransports" :key="transport._id" class="row">
                                    <div class="col">{{ transport.actionDescription }} {{ transport.target.name }}</div>
                                    <div class="col">
                                        <div
                                            v-for="resource in transport.originalData.resourceToGet"
                                            :key="resource.name"
                                            class="d-flex gap-1"
                                        >
                                            <img
                                                :src="require(`../../assets/icons/${resource.name}_icon.svg`)"
                                                class="offer-icon"
                                            />
                                            <span class="offer-quantity">{{ resource.quantity }}</span>
                                        </div>
                                    </div>
                                    <div class="col d-none d-sm-flex">{{ transport.originalData.transports }}</div>
                                    <div class="col d-none d-sm-flex">100</div>
                                    <div class="col">{{ transport.originalData.arrivalDate }}</div>
                                    <div class="col">{{ transport.progressTracking.dynamicDurationString }}</div>
                                </div>
                            </div>
                            <div class="incoming-transports-wrapper">
                                <h6 class="section-title">Incoming Transports</h6>
                                <div class="row label">
                                    <div class="col">Action</div>
                                    <div class="col">Payload</div>
                                    <div class="col">Arrival Date</div>
                                    <div class="col">Time Until Arrival</div>
                                </div>
                                <div v-for="transport in outsideTransports" :key="transport._id" class="row">
                                    <div class="col">{{ transport.actionDescription }} {{ transport.target.name }}</div>
                                    <div class="col">
                                        <div
                                            v-for="resource in transport.originalData.resourceToGet"
                                            :key="resource.name"
                                            class="d-flex gap-1"
                                        >
                                            <img
                                                :src="require(`../../assets/icons/${resource.name}_icon.svg`)"
                                                class="offer-icon"
                                            />
                                            <span class="offer-quantity">{{ resource.quantity }}</span>
                                        </div>
                                    </div>
                                    <div class="col">{{ transport.originalData.arrivalDate }}</div>
                                    <div class="col">{{ transport.progressTracking.dynamicDurationString }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <trade-confirmation-modal
                        id="trade-confirmation-modal"
                        :offer="selectedOffer"
                        :resources="resources"
                        :available-traders="tradersAvailable"
                        :single-trader-capacity="singleTraderCapacity"
                        :available-trucks="trucksAvailable"
                        :single-truck-capacity="trucks.carryCapacity"
                        :trucks="trucks"
                        :current-city-id="currentCityId"
                        :reinforcements="reinforcements"
                    />
                </div>
            </div>
        </div>
        <confirmation-modal
            id="confirmation-modal-commercial-zone"
            :title="confirmationModalProps.title"
            :action="confirmationModalProps.action"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { ResourceIdentifier } from '@/utils/constants/trading';
import TradeConfirmationModal from '@/components/TradeConfirmationModal';
import TableWithPagination from '@/components/TableWithPagination';
import { getTravelTimeInHours, getTravelTimeInHoursFormatted } from '@/utils/functions/cityUtils';
import CityFinder from '@/components/CityFinder';
import ConfirmationModal from '@/components/ConfirmationModal';
import TruckSelect from '@/components/CommercialCenter/TruckSelect';
import { ActionTypeIdentifier } from '@/utils/constants/actions';

export default {
    name: 'CommercialCenterModal',
    components: { TruckSelect, CityFinder, TradeConfirmationModal, TableWithPagination, ConfirmationModal },
    props: {
        commercialZone: {
            type: Object,
            default: () => {},
        },
        allCities: {
            type: Array,
            default: () => [],
        },
        outsideOffers: {
            type: Array,
            default: () => [],
        },
        resources: {
            type: Object,
            default: () => {},
        },
        trucks: {
            type: Object,
            default: () => {},
        },
        currentCityCoords: {
            type: Object,
            default: () => {},
        },
        currentCityId: {
            type: String,
            default: '',
        },
        reinforcements: {
            type: Array,
            default: () => [],
        },
        mapActions: {
            type: Set,
            default: () => new Set(),
        },
    },
    data() {
        return {
            ResourceIdentifier,
            ActionTypeIdentifier,

            wantedResourceExchange: null,
            offeredResourceExchange: null,
            maxTravelTimeExchange: Infinity,

            wantedResourceCreate: ResourceIdentifier.ANY,
            offeredResourceCreate: ResourceIdentifier.ALUMINUM,

            wantedResourceValueCreate: 0,
            offeredResourceValueCreate: 0,
            numberOfOffersCreate: 1,
            maxTravelTimeCreate: 1,

            selectedOfferId: null,

            confirmationModalProps: {
                title: '',
                action: () => {},
            },

            resourcesToSend: {
                aluminum: 0,
                oil: 0,
                metal: 0,
                rations: 0,
            },
            sendResourcesTarget: null,
        };
    },
    computed: {
        selectedOffer() {
            return this.outsideOffers.find(offer => offer._id === this.selectedOfferId);
        },
        currentCityOffers() {
            return this.getCurrentCityOffers() || [];
        },
        occupiedTransports() {
            let total = 0;

            this.userTransports.forEach(transport => {
                total += transport.originalData.transports;
            });

            return total;
        },
        tradersAvailable() {
            return this.commercialZone?.maxTransports - this.occupiedTransports;
        },
        singleTraderCapacity() {
            return 1000;
        },
        maximumTraderCapacity() {
            return this.tradersAvailable * this.singleTraderCapacity;
        },
        tradersAvailableString() {
            return `${this.tradersAvailable}/${this.commercialZone?.maxTransports}`;
        },
        trucksAvailable() {
            return this.trucks.numOfTroops + this.trucks.controlledReinforcements;
        },
        maximumTruckCapacity() {
            return this.trucksAvailable * this.trucks.carryCapacity;
        },
        // we compute the travel time for each offer locally to be used for filtering and to be displayed
        formattedOffers() {
            return this.outsideOffers.map(offer => ({
                ...offer,
                travelTimeInHours: this.getTravelTimeInHours({
                    first_city_x: this.currentCityCoords.x,
                    first_city_y: this.currentCityCoords.y,
                    second_city_x: offer.x,
                    second_city_y: offer.y,
                }),
                travelTimeToShow: this.getTravelTimeInHoursFormatted({
                    first_city_x: this.currentCityCoords.x,
                    first_city_y: this.currentCityCoords.y,
                    second_city_x: offer.x,
                    second_city_y: offer.y,
                }),
            }));
        },
        filteredOffers() {
            return this.formattedOffers
                .filter(offer =>
                    this.wantedResourceExchange ? offer.resourceToOffer.name === this.wantedResourceExchange : true,
                )
                .filter(offer =>
                    this.offeredResourceExchange ? offer.resourceToGet.name === this.offeredResourceExchange : true,
                )
                .filter(
                    offer =>
                        offer.maximumTime >= offer.travelTimeInHours && offer.travelTimeInHours <= this.maxTravelTimeExchange,
                );
        },
        tradersNeededForTrade() {
            return Math.ceil(this.offeredResourceValueCreate / this.singleTraderCapacity) * this.numberOfOffersCreate;
        },
        trucksNeededForTrade() {
            return Math.ceil(this.offeredResourceValueCreate / this.trucks.carryCapacity) * this.numberOfOffersCreate;
        },
        userTransports() {
            const RelevantActions = [
                ActionTypeIdentifier.OUTGOING_TRADING_TRANSPORT,
                ActionTypeIdentifier.OUTGOING_SEND_RESOURCES_TRANSPORT,
                ActionTypeIdentifier.RETURNING_TRADING_TRANSPORT,
                ActionTypeIdentifier.RETURNING_SEND_RESOURCES_TRANSPORT,
            ];
            const FilteredActions = new Set();

            this.mapActions.forEach(action => {
                if (RelevantActions.includes(action.actionType)) {
                    FilteredActions.add(action);
                }
            });
            return FilteredActions;
        },
        outsideTransports() {
            const RelevantActions = [
                ActionTypeIdentifier.INCOMING_TRADING_TRANSPORT,
                ActionTypeIdentifier.INCOMING_SEND_RESOURCES_TRANSPORT,
            ];
            const FilteredActions = new Set();

            this.mapActions.forEach(action => {
                if (RelevantActions.includes(action.actionType)) {
                    FilteredActions.add(action);
                }
            });
            return FilteredActions;
        },
        outgoingResources() {
            const Resources = [];

            this.userTransports.forEach(transport => {
                if (!transport.originalData.resourceToGet) {
                    return;
                }
                transport.originalData.resourceToGet.forEach(resource => {
                    console.log(':::', resource);
                    const Index = Resources.findIndex(res => res.name === resource.name);
                    if (Index === -1) {
                        console.log(resource);
                        Resources.push(resource);
                    } else {
                        console.log(Resources, Index, resource);
                        Resources[Index].quantity += resource.quantity;
                    }
                });
            });

            return Resources;
        },
        incomingResources() {
            const Resources = [];

            this.outsideTransports.forEach(transport => {
                if (!transport.originalData.resourceToGet) {
                    return;
                }
                transport.originalData.resourceToGet.forEach(resource => {
                    const Index = Resources.findIndex(res => res.name === resource.name);
                    if (Index === -1) {
                        Resources.push(resource);
                    } else {
                        Resources[Index].quantity += resource.quantity;
                    }
                });
            });

            return Resources;
        },
    },
    created() {},
    methods: {
        ...mapGetters(['getCurrentCityOffers']),
        ...mapActions(['makeOffer', 'cancelOffer', 'sendResources']),
        ...mapMutations(['removeInactiveOffers']),
        getTravelTimeInHours,
        getTravelTimeInHoursFormatted,

        closeModal() {
            document.getElementById('commercial-center-modal').style.transition =
                'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
            document.getElementById('commercial-center-modal').style.transform = 'translateY(-20px)';
            document.getElementById('commercial-center-modal').style.opacity = '0';
            setTimeout(() => {
                document.getElementById('commercial-center-modal').style.display = 'none';
                document.getElementById('commercial-center-modal').classList.remove('show');
                document.getElementById('commercial-center-modal').style.removeProperty('transform');
                document.getElementById('commercial-center-modal').style.removeProperty('opacity');
            }, 150);

            this.removeInactiveOffers();
        },

        openTradeConfirmationModal(offer_id) {
            if (offer_id) {
                this.setSelectedOfferId(offer_id);
            }

            document.getElementById('trade-confirmation-modal').style.transform = 'translateY(-20px)';
            document.getElementById('trade-confirmation-modal').style.opacity = '0';
            document.getElementById('trade-confirmation-modal').style.display = 'block';
            document.getElementById('trade-confirmation-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('trade-confirmation-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('trade-confirmation-modal').style.transform = 'translateY(0)';
                document.getElementById('trade-confirmation-modal').style.opacity = '1';
            }, 0);
        },

        createOfferCommand() {
            this.makeOffer({
                wanted: { name: this.wantedResourceCreate, quantity: this.wantedResourceValueCreate },
                offered: { name: this.offeredResourceCreate, quantity: this.offeredResourceValueCreate },
                repeats: this.numberOfOffersCreate,
                maximumTime: this.maxTravelTimeCreate,
            });
        },

        cancelOfferCommand(offer_id) {
            this.cancelOffer({ offer_id });
        },

        sendResourcesCommand() {
            this.sendResources({
                resources: this.getResourcesForSending(),
                destination: this.sendResourcesTarget._id,
            });
        },

        getResourcesForSending() {
            const Resources = [];

            Object.keys(this.resourcesToSend).forEach(resource => {
                if (this.resourcesToSend[resource] > 0) {
                    Resources.push({ name: resource, quantity: this.resourcesToSend[resource] });
                }
            });

            return Resources;
        },

        setSelectedOfferId(offer_id) {
            this.selectedOfferId = offer_id;
        },

        disableWantedIfSelected(resource) {
            if (resource === this.wantedResourceExchange) {
                this.wantedResourceExchange = null;
            }
        },

        disableOfferedIfSelected(resource) {
            if (resource === this.offeredResourceExchange) {
                this.offeredResourceExchange = null;
            }
        },

        canAcceptOffer(offer) {
            if (this.maximumTraderCapacity < offer.resourceToGet?.quantity) {
                return false;
            }
            if (this.maximumTruckCapacity < offer.resourceToGet?.quantity) {
                return false;
            }

            const NeedToOfferAny = offer.resourceToGet?.name === ResourceIdentifier.ANY;
            if (NeedToOfferAny) {
                let quantity = 0;
                Object.keys(this.resources).forEach(resource => {
                    if (resource !== offer.resourceToOffer?.name) {
                        quantity += this.resources[resource];
                    }
                });

                if (quantity < offer.resourceToGet?.quantity) {
                    return false;
                }
            } else {
                if (this.resources[offer.resourceToGet?.name] < offer.resourceToGet?.quantity) {
                    return false;
                }
            }
            return true;
        },

        getValidationMessage(offer) {
            if (this.maximumTraderCapacity < offer.resourceToGet?.quantity) {
                return 'Not enough traders';
            }
            if (this.maximumTruckCapacity < offer.resourceToGet?.quantity) {
                return 'Not enough trucks';
            }

            const NeedToOfferAny = offer.resourceToGet?.name === ResourceIdentifier.ANY;
            if (NeedToOfferAny) {
                let quantity = 0;
                Object.keys(this.resources).forEach(resource => {
                    if (resource !== offer.resourceToOffer?.name) {
                        quantity += this.resources[resource];
                    }
                });

                if (quantity < offer.resourceToGet?.quantity) {
                    return 'Not enough resources';
                }
            } else {
                if (this.resources[offer.resourceToGet?.name] < offer.resourceToGet?.quantity) {
                    return `Not enough ${offer.resourceToGet?.name}`;
                }
            }
            return '';
        },

        setConfirmationModalProps({ title, action }) {
            this.confirmationModalProps.title = title;
            this.confirmationModalProps.action = action;
            this.openConfirmationModal();
        },

        setSendResourcesTarget(city) {
            console.log('send resources target ::::::', city);
            this.sendResourcesTarget = city;
        },

        setMaxResourcesToSend(resource) {
            this.resourcesToSend[resource] = this.resources[resource];
        },

        getFormattedSearchResult(city) {
            return `${city.name} (${city.coords.x} | ${city.coords.y})${city.isBarbarian ? '' : ' - ' + city.owner}`;
        },

        openConfirmationModal() {
            document.getElementById('confirmation-modal-commercial-zone').style.transform = 'translateY(-20px)';
            document.getElementById('confirmation-modal-commercial-zone').style.opacity = '0';
            document.getElementById('confirmation-modal-commercial-zone').style.display = 'block';
            document.getElementById('confirmation-modal-commercial-zone').classList.add('show');
            setTimeout(() => {
                document.getElementById('confirmation-modal-commercial-zone').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('confirmation-modal-commercial-zone').style.transform = 'translateY(0)';
                document.getElementById('confirmation-modal-commercial-zone').style.opacity = '1';
            }, 0);
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header {
    border: none;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
}

.modal-title {
    width: 60%;
    font-family: 'bebas', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    padding-bottom: 0.75rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
    font-size: 1.5rem;
}

.building-description {
    width: 60%;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    padding-right: 1rem;
    max-height: 2.5rem;
    overflow-y: overlay;
    color: #b7b6b6;
    text-align: justify;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.building-description::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.building-description::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 5vh;
    padding: 4rem;
    background-image: url('../../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding: 3rem 1rem 1rem 1rem;
}

.nav-tabs {
    border: none;
}

.nav-tabs .nav-link {
    border-radius: 0;
    border: 2px solid #434343;
    border-bottom: none;
    color: #afafaf;
    transform: translateY(15px);
}

.nav-tabs .nav-item {
    margin-right: 0.25rem;
}

.nav-tabs .nav-item:nth-last-child(2) {
    margin-right: 0;
}

.nav-tabs .nav-link:hover {
    color: lightgray;
    border: 2px solid #434343;
    border-bottom: none;
}

.nav-tabs .nav-link.active {
    background: none;
    border: 2px solid #434343;
    border-bottom: none;
    color: ghostwhite;
    padding-top: 1rem;
    padding-bottom: 1rem;
    transform: translateY(-1px);
}

.nav-filler {
    flex-grow: 1;
    border-bottom: 2px solid #434343;
}

.tab-pane {
    padding: 3rem 1.5rem 1.5rem 1.5rem;
    background: none;
    border: 2px solid #434343;
    border-top: none;
}

.row {
    margin: 0;
}

.form-check-input {
    cursor: pointer;
}

.form-check-input:checked {
    background-color: #d34848;
    border: none;
    background-size: 10px;
}

.form-check-input:focus {
    box-shadow: none;
}

.form-select {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
    color: ghostwhite;
    border-color: ghostwhite;
    filter: brightness(10);
    font-size: 0.875rem;
    cursor: pointer;
    height: 2rem;
}

.form-select:focus {
    box-shadow: none;
    border-color: ghostwhite;
}

.large {
    flex-basis: 200% !important;
}

.small {
    flex-basis: 5% !important;
}

/*          EXCHANGE          */

.trade-offers-wrapper,
.created-offers-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.offers-select-group {
    min-width: 2rem;
    gap: 0.25rem;
}

.trade-offers-wrapper .form-control {
    height: 1.75rem;
    padding: 5%;
}

.max-offers {
    color: wheat;
}

.max-offers:hover {
    color: cornflowerblue;
    cursor: pointer;
}

.trade-filters-wrapper,
.create-offer-wrapper,
.send-resources-wrapper {
    max-width: 30rem;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.trade-filters,
.create-offer-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.trade-filters-wrapper .col,
.create-offer-wrapper .col {
    min-height: 3rem;
}

.trade-filters-wrapper .col:not(.standalone-label),
.create-offer-wrapper .col:not(.standalone-label) {
    display: flex;
    justify-content: space-between;
}

.trade-filters-wrapper .col.standalone-label {
    width: 8rem;
}

.trade-filters .form-check input {
    display: none;
}

.trade-filters .form-check label {
    padding: 0.25rem;
}

.trade-filters .form-check label:hover,
.trade-filters .form-check.checked label {
    border-bottom: 2px solid brown;
    padding-bottom: 2px;
}

.standalone-label {
    background: linear-gradient(to bottom, #2a2a2a, #181818) !important;
    color: ghostwhite;
    min-height: 2rem;
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
}

.row.standalone-label {
    flex-wrap: wrap;
}

.form-check-label .offer-icon {
    height: 1rem;
}

.row {
    flex-wrap: nowrap;
    gap: 2px;
}

.label .col {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    color: ghostwhite;
    height: 3rem;
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.col {
    flex: 1 1 auto;
    background: rgba(0, 0, 0, 0.5);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    gap: 0.5rem;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
    min-width: 4.5rem;
}

.offer-icon {
    height: 1rem;
}

.offer-quantity {
    font-size: 0.875rem;
}

.btn {
    padding: 0.25rem;
}

.red-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
    width: 100%;
}

.red-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

.yellow-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
    width: 100%;
}

.yellow-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.blue-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgb(34, 41, 58), rgba(255, 255, 255, 0.2));
    border: 3px solid dodgerblue;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.blue-button:hover {
    background: linear-gradient(to bottom, rgba(96, 95, 42, 0.2), rgb(52, 52, 70));
    color: ghostwhite;
}

/*          CREATE OFFER          */

.create-offer-wrapper {
    max-width: 36rem;
}

.form-control {
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    border: 1px solid whitesmoke;
    border-radius: 0;
    padding-left: 1rem;
    opacity: 0.8;
    color: ghostwhite;
    height: 2rem;
}

.form-control:focus {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    color: ghostwhite;
    border: 1px solid ghostwhite;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #1a1a1a inset;
    -webkit-text-fill-color: ghostwhite;
    caret-color: ghostwhite;
}

.create-offer-wrapper .col.standalone-label {
    width: 15rem;
}

.col.input-wrapper {
    flex-basis: 30%;
}

.created-offers-wrapper {
    margin-top: 5rem;
    position: relative;
}

.truck-select-table {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 1rem;
}

/*          SEND RESOURCES          */

.send-resources-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    margin-top: 5rem;
    gap: 1rem;
}

.resources-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 35%;
}

.city-finder-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 65%;
}

.send-resources-target {
    color: silver;
    font-style: italic;
}

.resources-wrapper > .row,
.city-finder > .row {
    background: rgba(0, 0, 0, 0.5);
    padding-left: 0.5rem;
    height: 3rem;
}

.send-resources-section .offer-icon {
    height: 1rem;
    min-width: 2rem;
}

.section-title {
    position: absolute;
    top: -2rem;
}

.max-send-resources {
    color: wheat;
    min-width: 2.5rem;
}

.max-send-resources:hover {
    color: cornflowerblue;
    cursor: pointer;
}

.resource-group {
    font-size: 0.875rem;
    display: flex;
    flex-grow: 1;
    gap: 0.25rem;
    position: relative;
    min-width: 7rem;
}

/*          TRANSPORTS          */

.transports-wrapper {
    display: flex;
    flex-direction: row;
    gap: 2px;
}

.transports-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 50%;
}

.transports-info-group {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: auto;
}

.transports-info-icon {
    height: 1rem;
}

.your-transports-wrapper,
.incoming-transports-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-top: 5rem;
}

.icon-tooltip {
    z-index: 1;
    opacity: 0;
    filter: blur(3px);
    position: absolute;
    top: -2rem;
    pointer-events: none;
    transition: opacity 0.3s, filter 0.3s;
    background: black;
    color: ghostwhite;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 0.25rem;
    border: 1px solid #434343;
    width: max-content;
}

.form-check-label {
    position: relative;
    cursor: pointer;
}

.resource-column {
    position: relative;
}

.form-check-label:hover .icon-tooltip,
.resource-column:hover .icon-tooltip {
    opacity: 1;
    filter: blur(0);
}

.capitalized {
    text-transform: capitalize;
}

.row.inactive {
    opacity: 0.5;
}

.row.inactive .col {
    background: rgba(160, 160, 160, 0.5);
}

.row.outline {
    outline: 1px solid silver;
}

@media screen and (max-width: 1199px) {
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 850px) {
    .col,
    .label .col {
        font-size: 0.75rem;
        min-width: 3rem;
        padding: 0.25rem;
    }
    .col {
        gap: 0.25rem;
    }
    .standalone-label {
        font-size: 0.75rem;
        display: flex;
        flex-wrap: wrap;
    }
    .trade-filters-wrapper .col.standalone-label {
        width: 4rem;
    }
    .create-offer-wrapper .col.standalone-label {
        width: 8rem;
    }
    .form-check-label .offer-icon,
    .transports-info-icon {
        height: 0.75rem;
    }
    .form-check-input {
        transform: scale(0.8);
    }
    .nav-link {
        font-size: 0.875rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .trade-offers-wrapper .col,
    .created-offers-wrapper .col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .offer-quantity {
        font-size: 0.75rem;
    }
    .btn {
        font-size: 0.75rem;
        padding: 0.25rem;
    }
    .modal-content {
        padding: 4rem 0 1rem 0;
    }
    #exchange-page,
    #create-offer-page,
    #send-resources-page,
    #transports-page {
        padding: 3rem 0.5rem 0.5rem 0.5rem;
    }
    .form-control {
        padding: 0.25rem;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 96%;
    }
    .send-resources-section {
        flex-direction: column;
    }
    .resources-wrapper {
        width: 50%;
    }
    .city-finder {
        width: 100%;
    }
    .transports-wrapper {
        flex-direction: column;
    }
    .transports-info-wrapper {
        width: 100%;
    }
    .building-description {
        width: 100%;
        font-size: 0.75rem;
        line-height: 0.75rem;
    }
}

@media screen and (max-width: 500px) {
    .nav-tabs .nav-item {
        margin-right: 0.125rem;
    }
    .nav-link {
        font-size: 0.75rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
}

@media screen and (max-width: 450px) {
    #create-offer-page .trade-filters .wrap {
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
}

@media screen and (max-width: 400px) {
    .resources-wrapper {
        width: 100%;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
