import axios from 'axios';
import { GENERAL_TEMPLATE } from '@/utils/state/templates/generalTemplate';
import { CITY_TEMPLATE } from '@/utils/state/templates/cityTemplate';
import { COALITION_TEMPLATE } from '@/utils/state/templates/coalitionTemplate';
import { OFFERS_TEMPLATE } from '@/utils/state/templates/offersTemplate';
import parseTemplateForQuery from '@/utils/state/parsers/parseTemplateForQuery';
import parseTemplateForInitialization from '@/utils/state/parsers/parseTemplateForInitialization';
import MapActionsCollection from '@/classes/mapActions/MapActionsCollection';
import MapActionFactory from '@/classes/mapActions/MapActionFactory';
import { CityPropertiesThatAreActions } from '@/utils/constants/actions';
import { CITIES_PER_TILE_HORIZONTALLY, CITIES_PER_TILE_VERTICALLY } from '@/utils/constants/map';
import Collection from '@/classes/generic/Collection';
import { addActionToMapArrows } from '@/utils/functions/mapArrowsUtils';

const ActionFactory = new MapActionFactory();

export default {
    state: {
        userState: parseTemplateForInitialization(GENERAL_TEMPLATE),
        currentCity: parseTemplateForInitialization(CITY_TEMPLATE),
        offers: [],
        awaitingResponse: false,
        allUsers: [],
        countdownStarted: false,
        countdownStartedTroopCommands: false,
        isSubscribed: false,
        mapMatrix: [],
        decorationsMatrix: [],
        mapActionTarget: null, // { id: String, name: String, coords: { x: Number, y: Number, } }

        allMapActions: [],
        currentCityMapActions: {
            cityId: null,
            collection: null,
        },

        mapArrows: new Collection([]),
    },

    mutations: {
        setAwaitingResponse(state, value) {
            state.awaitingResponse = value;
        },

        setUserInfo(state, data) {
            Object.keys(data).forEach(key => {
                if (data[key]) {
                    state.userState[key] = data[key];
                }
            });
        },

        setCurrentCity(state, data) {
            // data: { city_id, city_index }

            let CITY_INDEX;

            if (data.city_id) {
                CITY_INDEX = state.userState.cities.findIndex(city => city._id === data.city_id);
            } else {
                CITY_INDEX = data.city_index;
            }

            if (CITY_INDEX !== -1) {
                state.currentCity = state.userState.cities[CITY_INDEX];
                ActionFactory.setCurrentCityId(state.currentCity._id);
                console.log('--- state.currentCity ---', state.currentCity);
            } else {
                console.error('City not found for index:', CITY_INDEX);
            }
        },

        setAllMapActions(state, cities) {
            cities.forEach(city => {
                const CurrentCityActionsObject = {
                    cityId: city._id,
                    collection: new MapActionsCollection([]),
                };

                Object.values(CityPropertiesThatAreActions).forEach(property => {
                    city[property].forEach(action => {
                        const Action = ActionFactory.createAction({
                            action_data: action,
                            property_name: property,
                            all_users: state.allUsers,
                        });
                        if (Action) {
                            CurrentCityActionsObject.collection.addItem(Action);
                        }
                    });
                });

                state.allMapActions.push(CurrentCityActionsObject);
            });
        },

        setCurrentCityMapActions(state, data) {
            // data: { city_id, city_index }

            let COLLECTION_INDEX;

            if (data.city_id) {
                COLLECTION_INDEX = state.allMapActions.findIndex(collection => collection.cityId === data.city_id);
            } else {
                COLLECTION_INDEX = data.city_index;
            }

            if (COLLECTION_INDEX !== -1) {
                state.currentCityMapActions = state.allMapActions[COLLECTION_INDEX];
                state.currentCityMapActions.collection.startCountdowns();
            } else {
                console.error('Map action collection not found for index:', COLLECTION_INDEX);
            }

            console.log('>>><<<>><><<', state.currentCityMapActions);
        },

        setAllMapArrows(state) {
            state.allMapActions.forEach(action_group => {
                action_group.collection.items.forEach(action => addActionToMapArrows(state.mapArrows, action));
            });

            console.log('@@@@@@@', state.mapArrows);
        },

        setOutsideOffers(state, data) {
            state.offers = [...data];
        },

        lowerResources(state, resources) {
            const CurrentCity = state.userState.cities.find(city => city._id === state.currentCity._id);

            CurrentCity.metal -= resources.metal || 0;
            CurrentCity.aluminum -= resources.aluminum || 0;
            CurrentCity.oil -= resources.oil || 0;
            CurrentCity.rations -= resources.rations || 0;
        },

        increaseResources(state, resources) {
            const CurrentCity = state.userState.cities.find(city => city._id === state.currentCity._id);

            CurrentCity.metal += resources.metal || 0;
            CurrentCity.aluminum += resources.aluminum || 0;
            CurrentCity.oil += resources.oil || 0;
            CurrentCity.rations -= resources.rations || 0;
        },

        setCountdownStarted(state, value) {
            state.countdownStarted = value;
        },

        setCountdownStartedTroopCommands(state, value) {
            state.countdownStartedTroopCommands = value;
        },

        updateDataLive(state, { data }) {
            // "identifier" being null means we need to travel to the object property inside "name"

            // "identifier" not being null means we need to find each array element with property "identifier" === "name"[i];
            // for each array element inside "name" we traverse the rest of the "path" array and apply the provided
            // action with the data in "liveData" obtained by traversing the same paths

            // actions = [
            //     {
            //         path: [
            //             {
            //                 name: 'city',
            //                 identifier: null,
            //             },
            //             {
            //                 name: 'attacks',
            //                 identifier: null,
            //             },
            //             {
            //                 name: ['abc987654321', 'fgh987654321'],
            //                 identifier: '_id',
            //             },
            //             {
            //                 name: 'fromCity',
            //                 identifier: null,
            //             },
            //             {
            //                 name: '_id',
            //                 identifier: null,
            //             },
            //         ],
            //         action: 'replace',
            //     }
            // ]

            console.log(`LIVE DATA: `, JSON.parse(JSON.stringify(data)));

            let { data: parsedData } = data?.data;
            const paths = [];
            const actions = [...parsedData.actions];
            const indexes = [];
            const toReplace = [];

            for (let i = 0; i < parsedData.paths.length; i++) {
                paths.push(parsedData.paths[i].split('.'));
                if (actions[i] === 'remove') {
                    toReplace.push(null);
                    indexes.push(parsedData.indexes[0]);
                    parsedData.indexes.splice(0, 1);
                } else if (actions[i] === 'replacePartial' || actions[i] === 'addPartial' || actions[i] === 'addPartialArray') {
                    indexes.push(parsedData.indexes[0]);
                    toReplace.push(parsedData.toReplace[0]);
                    parsedData.toReplace.splice(0, 1);
                    parsedData.indexes.splice(0, 1);
                } else {
                    indexes.push(null);
                    toReplace.push(null);
                }
            }

            console.log(`indexes: `, indexes);
            console.log(`toReplace: `, toReplace);

            for (let i = 0; i < paths.length; i++) {
                let copyData = structuredClone(parsedData);

                let selector = state.userState;
                let accessFinalPath = true;

                // FOR EACH NESTING WE UPDATE THE SELECTOR AND THE DATA
                for (let j = 0; j < paths[i].length; j++) {
                    console.log('\npaths[i]: ', paths[i]);
                    console.log('\npaths[i][j]: ', paths[i][j]);

                    if (j === paths[i].length - 1) {
                        const testSelector = selector[paths[i][j]];

                        if (
                            typeof testSelector === 'boolean' ||
                            typeof testSelector === 'string' ||
                            typeof testSelector === 'number'
                        ) {
                            accessFinalPath = false;
                        }
                    }

                    if (accessFinalPath) {
                        selector = selector[paths[i][j]];
                        copyData = copyData[paths[i][j]];
                    }

                    console.log('\nSELECTOR: ', selector);
                    console.log('\ncopyData: ', copyData);

                    if (j < paths[i].length - 1) {
                        console.log('\nIF');

                        if (Array.isArray(selector)) {
                            for (let z = 0; z < selector.length; z++) {
                                if (selector[z]._id === copyData[0]._id) {
                                    selector = selector[z];
                                    break;
                                }
                            }

                            copyData = copyData[0];
                        }
                    }
                }

                if (actions[i] === 'push') {
                    selector.push(copyData[0]);
                    Object.values(CityPropertiesThatAreActions).forEach(property => {
                        if (paths[i].includes(property)) {
                            const ReceivedAction = copyData[0];
                            const Action = ActionFactory.createAction({
                                action_data: ReceivedAction,
                                property_name: property,
                                all_users: state.allUsers,
                            });

                            if (Action) {
                                // TO DO: add to specific city, not current city
                                state.currentCityMapActions.collection.addItem(Action);
                                addActionToMapArrows(state.mapArrows, Action);
                                Action.startCountdown();

                                console.log('@@@@@@@ live', state.mapArrows);
                            }
                        }
                    });
                } else if (actions[i] === 'remove') {
                    if (indexes[i] !== 'none') {
                        // TO DO: search for way to not use multiIndexes
                        const multiIndexes = indexes[i].split('.');
                        let multiIndex = false;

                        if (multiIndexes.length > 1) {
                            multiIndex = true;
                        }

                        for (let j = 0; j < selector.length; j++) {
                            if (multiIndex) {
                                let temporarySelector = selector[j];
                                let temporaryCopyData = copyData[0];

                                for (let z = 0; z < multiIndexes.length; z++) {
                                    temporarySelector = temporarySelector[multiIndexes[z]];
                                    temporaryCopyData = temporaryCopyData[multiIndexes[z]];
                                }

                                if (temporarySelector === temporaryCopyData) {
                                    selector.splice(j, 1);
                                    break;
                                }
                            } else {
                                if (selector[j][indexes[i]] === copyData[0][indexes[i]]) {
                                    selector.splice(j, 1);
                                    break;
                                }
                            }
                        }
                    } else {
                        for (let j = 0; j < selector.length; j++) {
                            if (selector[j] === copyData[0]) {
                                selector.splice(j, 1);
                                break;
                            }
                        }
                    }
                } else if (actions[i] === 'removeMany') {
                    for (let j = 0; j < selector.length; j++) {
                        for (let z = 0; z < copyData.length; z++) {
                            if (selector[j][indexes[i]] === copyData[z][indexes[i]]) {
                                selector.splice(j, 1);
                                copyData.splice(z, 1);
                                j--;
                                break;
                            }
                        }
                    }
                } else if (actions[i] === 'replacePartial') {
                    for (let j = 0; j < selector.length; j++) {
                        if (selector[j][indexes[i]] === copyData[0][indexes[i]]) {
                            selector[j][toReplace[i]] = copyData[0][toReplace[i]];
                            break;
                        }
                    }
                } else if (actions[i] === 'replacePartialArray') {
                    for (let j = 0; j < selector.length; j++) {
                        for (let z = 0; z < copyData.length; z++) {
                            if (selector[j][indexes[i]] === copyData[z][indexes[i]]) {
                                selector[j][toReplace[i]] = copyData[z][toReplace[i]];
                                break;
                            }
                        }
                    }
                } else if (actions[i] === 'replace') {
                    if (paths[i][0] === 'coalition' && paths[i].length === 1 && !copyData) {
                        Object.assign(selector, parseTemplateForInitialization(COALITION_TEMPLATE));
                    } else if (!accessFinalPath) {
                        selector[paths[i][paths[i].length - 1]] = copyData[paths[i][paths[i].length - 1]];
                    } else {
                        Object.assign(selector, copyData);
                    }
                } else if (actions[i] === 'add') {
                    selector += copyData;
                } else if (actions[i] === 'substract') {
                    selector[paths[i][paths[i].length - 1]] -= copyData[paths[i][paths[i].length - 1]];
                } else if (actions[i] === 'substractPartial') {
                    for (let j = 0; j < selector.length; j++) {
                        if (selector[j][indexes[i]] === copyData[0][indexes[i]]) {
                            selector[j][toReplace[i]] -= copyData[0][toReplace[i]];
                            break;
                        }
                    }
                } else if (actions[i] === 'addPartial') {
                    for (let j = 0; j < selector.length; j++) {
                        if (selector[j][indexes[i]] === copyData[0][indexes[i]]) {
                            selector[j][toReplace[i]] += copyData[0][toReplace[i]];
                            break;
                        }
                    }
                } else if (actions[i] === 'addPartialArray') {
                    for (let j = 0; j < selector.length; j++) {
                        for (let z = 0; z < copyData.length; z++) {
                            if (selector[j][indexes[i]] === copyData[z][indexes[i]]) {
                                selector[j][toReplace[i]] += copyData[z][toReplace[i]];
                                break;
                            }
                        }
                    }
                }
            }
        },

        updateOffersLive(state, { data }) {
            const LiveOffer = data.data.offer;
            console.log('OFFERS DATA:', LiveOffer);
            if (data.data.offer.actions.includes('push')) {
                state.offers.push(LiveOffer);
            }
            if (data.data.offer.actions.includes('remove')) {
                const OfferInState = state.offers.find(offer => offer._id === LiveOffer._id);
                OfferInState.toBeRemoved = true;
                OfferInState.repeats = 0;
            }
            if (data.data.offer.actions.includes('replacePartial')) {
                const OfferInState = state.offers.find(offer => offer._id === LiveOffer._id);
                LiveOffer.toReplace.forEach(property => (OfferInState[property] = LiveOffer[property]));
                OfferInState.newValue = true;
                setTimeout(() => {
                    OfferInState.newValue = false;
                }, 2000);
            }
        },

        updateGameDataLive(state, { data }) {
            console.log(`UPDATE GAME DATA LIVE: `, data);
        },

        removeInactiveOffers(state) {
            state.offers = state.offers.filter(offer => !offer.toBeRemoved);
        },

        removeOffer(state, offer_id) {
            const CurrentCity = state.userState.cities.find(city => city._id === state.currentCity._id);
            CurrentCity.offers = CurrentCity.offers.filter(offer => offer._id !== offer_id);
        },

        updateSubscriptions(state) {
            state.isSubscribed = true;
        },

        updatePactInvitations(state, data) {
            const PACT_INVITATION_INDEX = state.userState.coalition.pactInvitations.findIndex(
                invitation => invitation.id === data,
            );
            if (PACT_INVITATION_INDEX !== -1) {
                state.userState.coalition.pactInvitations.splice(PACT_INVITATION_INDEX, 1);
            }
        },

        updateMemberRank(state, data) {
            // data: id, rank
            const MEMBER = state.userState.coalition.rights.find(member => member.user === data.id);
            MEMBER.rank = data.rank;
        },

        updateMemberRights(state, data) {
            // data: ids, permissions
            data.ids.forEach((id, index) => {
                const MEMBER = state.userState.coalition.rights.find(member => member.user === id);
                MEMBER.permissions.forEach(permission_to_change => {
                    data.permissions[index].forEach(new_permission => {
                        if (permission_to_change.permission === new_permission.permission) {
                            permission_to_change.hasIt = new_permission.hasIt;
                        }
                    });
                });
            });
        },

        updateMembers(state, data) {
            // data: member_to_add (to be handled later), member_to_remove
            const ALL_MEMBERS_ENTRY_INDEX = state.userState.coalition.allMembers.findIndex(
                member => member.memberId === data.member_to_remove,
            );
            state.userState.coalition.allMembers.splice(ALL_MEMBERS_ENTRY_INDEX, 1);

            const RIGHTS_ENTRY_INDEX = state.userState.coalition.rights.findIndex(
                member => member.user === data.member_to_remove,
            );
            state.userState.coalition.rights.splice(RIGHTS_ENTRY_INDEX, 1);
        },

        setMapMatrix(state, matrix) {
            // TO DO: move to city constants
            const CityLevels = [
                [0, 500],
                [501, 1500],
                [1501, 2500],
                [2501, 9999999999],
            ];

            matrix.forEach((row, row_index) => {
                row.forEach((tile, tile_index) => {
                    tile.x = tile_index;
                    tile.y = row_index;

                    if (!tile.cities) {
                        tile.cities = [];
                    }
                    if (!tile.decorations) {
                        tile.decorations = [];
                    }

                    tile.cities.forEach((city, city_index) => {
                        state.allUsers.forEach(user => {
                            user.cities.forEach(user_city => {
                                if (city._id === user_city._id) {
                                    user_city.localX = city.x % CITIES_PER_TILE_HORIZONTALLY;
                                    user_city.localY = city.y % CITIES_PER_TILE_VERTICALLY;
                                    // TO DO: extract util
                                    user_city.level =
                                        CityLevels.findIndex(
                                            range => user_city.points >= range[0] && user_city.points <= range[1],
                                        ) + 1;
                                    user_city.isBarbarian = user.isBarbarian;
                                    // TO DO: also add map/minimap color to city object

                                    tile.cities[city_index] = user_city;
                                }
                            });
                        });
                    });

                    tile.decorations.forEach(decoration => {
                        decoration.localX = decoration.x % CITIES_PER_TILE_HORIZONTALLY;
                        decoration.localY = decoration.y % CITIES_PER_TILE_VERTICALLY;
                    });
                });
            });

            state.mapMatrix = matrix;
            console.log('*****', state.mapMatrix);
        },

        // setDecorationsMatrix(state, data) {
        //     state.decorationsMatrix = data;
        // },

        setAllUsers(state, data) {
            state.allUsers = data;
            console.log('>> All Users >>', state.allUsers);
        },

        setMapActionTarget(state, data) {
            state.mapActionTarget = data;
        },
        updateOwnedTroopsNumber(state, data) {
            // data: troop_name, number
            const INDEX = state.currentCity.troops.findIndex(troop => troop.name === data.troop_name);
            state.currentCity.troops[INDEX].numOfTroops -= data.number;
        },
        updateControlledTroopsNumber(state, data) {
            // data: troop_name, number
            const INDEX = state.currentCity.troops.findIndex(troop => troop.name === data.troop_name);
            state.currentCity.troops[INDEX].controlledReinforcements -= data.number;
        },
        updateReinforcementNumber(state, data) {
            // data: troop_name, reinforcement_id, number
            const REINFORCEMENT_INDEX = state.currentCity.reinforcements.findIndex(
                reinforcement => reinforcement._id === data.reinforcement_id,
            );
            const TROOP_INDEX = state.currentCity.reinforcements[REINFORCEMENT_INDEX].troopsInCities.findIndex(
                troop => troop.troopName === data.troop_name,
            );

            if (
                parseInt(state.currentCity.reinforcements[REINFORCEMENT_INDEX].troopsInCities[TROOP_INDEX].numOfTroops, 10) ===
                parseInt(data.number, 10)
            ) {
                state.currentCity.reinforcements[REINFORCEMENT_INDEX].troopsInCities.splice(TROOP_INDEX, 1);
                return;
            }
            state.currentCity.reinforcements[REINFORCEMENT_INDEX].troopsInCities[TROOP_INDEX].numOfTroops -= data.number;
        },
        updateOwnedForeignSpiesNumber(state, data) {
            // data: city_id, number
            const SPIES_INDEX = state.currentCity.foreignSpies.findIndex(spies => spies.toCity?._id === data.city_id);
            const SPIES_GROUP_INDEX = state.currentCity.foreignSpies[SPIES_INDEX].spies.findIndex(spies => spies.owned);
            state.currentCity.foreignSpies[SPIES_INDEX].spies[SPIES_GROUP_INDEX].numOfTroops -= data.number;

            // delete infiltrated spies row if no spies remaining
            if (state.currentCity.foreignSpies[SPIES_INDEX].spies.find(spies => spies.numOfTroops > 0)) {
                return;
            }
            state.currentCity.foreignSpies.splice(SPIES_INDEX, 1);
        },
        updateControlledForeignSpiesNumber(state, data) {
            // data: city_id, reinforcement_id, number
            const SPIES_INDEX = state.currentCity.foreignSpies.findIndex(spies => spies.toCity?._id === data.city_id);
            const SPIES_GROUP_INDEX = state.currentCity.foreignSpies[SPIES_INDEX].spies.findIndex(
                spies => spies.reinforcement === data.reinforcement_id,
            );
            if (
                parseInt(state.currentCity.foreignSpies[SPIES_INDEX].spies[SPIES_GROUP_INDEX].numOfTroops, 10) ===
                parseInt(data.number, 10)
            ) {
                state.currentCity.foreignSpies[SPIES_INDEX].spies.splice(SPIES_GROUP_INDEX, 1);
                // delete infiltrated spies row if no spies remaining
                if (state.currentCity.foreignSpies[SPIES_INDEX].spies.find(spies => spies.numOfTroops > 0)) {
                    return;
                }
                state.currentCity.foreignSpies.splice(SPIES_INDEX, 1);
                return;
            }
            state.currentCity.foreignSpies[SPIES_INDEX].spies[SPIES_GROUP_INDEX].numOfTroops -= data.number;
        },
        removeWarEffects(state, { war_id }) {
            const WAR = state.userState.coalition.wars.find(war => war._id === war_id);
            WAR.effectsInitiator = [];
            WAR.effectsTarget = [];
        },
    },

    actions: {
        setCurrentCityAction({ commit }, { city_id, city_index }) {
            commit('setCurrentCity', { city_id, city_index });
            commit('setCurrentCityMapActions', { city_id, city_index });
        },

        updateSubscriptionsAction({ commit }) {
            commit('updateSubscriptions');
        },

        updateDataLiveAction({ commit }, data) {
            commit(`updateDataLive`, data);
        },

        updateOffersLiveAction({ commit }, data) {
            commit('updateOffersLive', data);
        },

        updateGameDataLiveAction({ commit }, data) {
            commit('updateGameDataLive', data);
        },

        async getUserInformation({ commit }) {
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `{
                          getUserInformation ${parseTemplateForQuery(GENERAL_TEMPLATE)}}`,
                    },
                    { withCredentials: true },
                )
                .then(response => {
                    console.log('>>>>>>>>>>>>>', response);
                    commit('setUserInfo', response.data.data.getUserInformation);
                    commit('setCurrentCity', { city_id: null, city_index: 0 });

                    commit('setAllMapActions', response.data.data.getUserInformation.cities);
                    commit('setAllMapArrows', response.data.data.getUserInformation.cities);
                    commit('setCurrentCityMapActions', { city_id: null, city_index: 0 });
                });
        },

        async getMap({ commit }) {
            await axios
                .get(
                    `${process.env.VUE_APP_ADDRESS_MAIN}?query={
                        getMap{
                            matrix{
                                piece,
                                tile,
                                decorations{
                                    name,
                                    piece,
                                    x,
                                    y
                                },
                                cities{
                                    _id,
                                    x,
                                    y
                                }
                            }
                        }
                    }`,
                    { withCredentials: true },
                )
                .then(response => {
                    if (!response.data) return;

                    commit('setMapMatrix', response.data.data.getMap.matrix);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        async getAllUsers({ commit }) {
            await axios
                .get(
                    `${process.env.VUE_APP_ADDRESS_MAIN}?query={
                        getGameData{
                            _id
                            isBarbarian
                            username
                            cities {
                              x
                              y
                              points
                              _id
                              name
                              color
                            }
                            points
                            ranking
                            coalition {
                              _id
                              name
                              abbreviation
                              numOfMembers
                              points
                              maxNumOfMembers
                              additionRule
                            }
                        }
                    }`,
                    { withCredentials: true },
                )
                .then(response => {
                    if (!response.data) return;

                    commit('setAllUsers', response.data.data.getGameData);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        async sendAttack({ commit }, data) {
            console.log('sendAttack', data.troops);
            commit('setAwaitingResponse', true);
            let troops = `[`;

            for (let i = 0; i < data.troops.length; i++) {
                troops += `{troopsName: ${JSON.stringify(data.troops[i].troopsName)}, numOfTroops: [${
                    data.troops[i].numOfTroops
                }], owned: ${data.troops[i].owned}}`;
                if (i === data.troops.length - 1) {
                    troops += `]`;
                } else {
                    troops += `, `;
                }
            }

            axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{attack(attackedCity: "${data.attackedCity}", troops: ${troops}, ${
                            data.reinforcements.length ? 'reinforcements:' + JSON.stringify(data.reinforcements) + ',' : ''
                        } oil: ${data.oil}, rations: ${data.rations})}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async sendReinforcement({ commit }, data) {
            console.log('sendReinforcement', data.troops);
            commit('setAwaitingResponse', true);
            let troops = `[`;

            for (let i = 0; i < data.troops.length; i++) {
                troops += `{troopsName: ${JSON.stringify(data.troops[i].troopsName)}, numOfTroops: [${
                    data.troops[i].numOfTroops
                }], owned: ${data.troops[i].owned}}`;
                if (i === data.troops.length - 1) {
                    troops += `]`;
                } else {
                    troops += `, `;
                }
            }

            axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{
              reinforcement(cityId: "${data.cityId}", troops: ${troops}, ${
                            data.reinforcements.length ? 'reinforcements:' + JSON.stringify(data.reinforcements) + ',' : ''
                        } targetCanControl: ${data.targetCanControl}, targetCanPassControl: ${data.targetCanPassControl})
            }
            `,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async sendDetectSpiesMission({ commit }, data) {
            console.log('sendDetectSpiesMission', data);
            commit('setAwaitingResponse', true);
            let troops = `[`;

            for (let i = 0; i < data.troops.length; i++) {
                troops += `{numOfTroops: ${data.troops[i].numOfTroops}, owned: ${data.troops[i].owned}}`;
                if (i === data.troops.length - 1) {
                    troops += `]`;
                } else {
                    troops += `, `;
                }
            }

            axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{
              detectInfiltratedSpies(troops: ${troops}, ${
                            data.reinforcements.length ? 'reinforcements:' + JSON.stringify(data.reinforcements) + ',' : ''
                        })
            }
            `,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(fulfilled => {
                    commit('setAwaitingResponse', false);
                    if (fulfilled) {
                        for (let i = 0; i < data.troops.length; i++) {
                            if (data.troops[i].owned) {
                                commit('updateOwnedTroopsNumber', {
                                    troop_name: 'Spy',
                                    number: parseInt(data.troops[i].numOfTroops, 10),
                                });
                            }
                        }

                        let SUM_TO_SUBTRACT = 0;
                        if (data.reinforcements.length) {
                            data.reinforcements.forEach(reinforcement_id => {
                                const INDEX = data.reinforcements.indexOf(reinforcement_id);
                                const NUMBER_TO_SUBTRACT = parseInt(data.troops[INDEX].numOfTroops, 10);
                                SUM_TO_SUBTRACT += parseInt(data.troops[INDEX].numOfTroops, 10);
                                commit('updateReinforcementNumber', {
                                    troop_name: 'Spy',
                                    reinforcement_id: reinforcement_id,
                                    number: NUMBER_TO_SUBTRACT,
                                });
                            });
                            commit('updateControlledTroopsNumber', { troop_name: 'Spy', number: SUM_TO_SUBTRACT });
                        }
                    }
                });
        },

        async sendSpyMission({ commit }, data) {
            console.log('sendSpyMission', data.troops);
            commit('setAwaitingResponse', true);
            let troops = `[`;

            for (let i = 0; i < data.troops.length; i++) {
                troops += `{numOfTroops: ${data.troops[i].numOfTroops}, owned: ${data.troops[i].owned}}`;
                if (i === data.troops.length - 1) {
                    troops += `]`;
                } else {
                    troops += `, `;
                }
            }

            axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{
              spy(targetCityId: "${data.cityId}", troops: ${troops}, ${
                            data.reinforcements.length ? 'reinforcements:' + JSON.stringify(data.reinforcements) + ',' : ''
                        })
            }
            `,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(fulfilled => {
                    commit('setAwaitingResponse', false);
                    if (fulfilled) {
                        for (let i = 0; i < data.troops.length; i++) {
                            if (data.troops[i].owned) {
                                commit('updateOwnedTroopsNumber', {
                                    troop_name: 'Spy',
                                    number: parseInt(data.troops[i].numOfTroops, 10),
                                });
                            }
                        }

                        let SUM_TO_SUBTRACT = 0;
                        if (data.reinforcements.length) {
                            data.reinforcements.forEach(reinforcement_id => {
                                const INDEX = data.reinforcements.indexOf(reinforcement_id);
                                const NUMBER_TO_SUBTRACT = parseInt(data.troops[INDEX].numOfTroops, 10);
                                SUM_TO_SUBTRACT += parseInt(data.troops[INDEX].numOfTroops, 10);
                                commit('updateReinforcementNumber', {
                                    troop_name: 'Spy',
                                    reinforcement_id: reinforcement_id,
                                    number: NUMBER_TO_SUBTRACT,
                                });
                            });
                            commit('updateControlledTroopsNumber', { troop_name: 'Spy', number: SUM_TO_SUBTRACT });
                        }
                    }
                });
        },

        async callBackSpies({ commit }, data) {
            console.log('callBackSpies', data.troops);
            commit('setAwaitingResponse', true);
            let troops = `[`;

            for (let i = 0; i < data.troops.length; i++) {
                troops += `{numOfTroops: ${data.troops[i].numOfTroops}, owned: ${data.troops[i].owned}}`;
                if (i === data.troops.length - 1) {
                    troops += `]`;
                } else {
                    troops += `, `;
                }
            }

            axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{
              callBackSpies(targetCityId: "${data.cityId}", troops: ${troops}, ${
                            data.reinforcements.length ? 'reinforcements:' + JSON.stringify(data.reinforcements) + ',' : ''
                        })
            }`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                    for (let i = 0; i < data.troops.length; i++) {
                        if (data.troops[i].owned) {
                            commit('updateOwnedForeignSpiesNumber', {
                                city_id: data.cityId,
                                number: parseInt(data.troops[i].numOfTroops, 10),
                            });
                        }
                    }

                    if (data.reinforcements.length) {
                        data.reinforcements.forEach(reinforcement_id => {
                            const INDEX = data.reinforcements.indexOf(reinforcement_id);
                            const NUMBER_TO_SUBTRACT = parseInt(data.troops[INDEX].numOfTroops, 10);
                            commit('updateControlledForeignSpiesNumber', {
                                city_id: data.cityId,
                                reinforcement_id: reinforcement_id,
                                number: NUMBER_TO_SUBTRACT,
                            });
                        });
                    }
                });
        },

        async callBackReinforcements({ commit }, data) {
            console.log('callBackReinforcements', data);
            commit('setAwaitingResponse', true);
            axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{callBackReinforcement(reinforcement: "${data}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async sendBackReinforcements({ commit }, data) {
            console.log('sendBackReinforcements', data);
            commit('setAwaitingResponse', true);
            axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{sendingBackReinforcement(reinforcement: "${data}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async sendPatrol({ commit }, data) {
            console.log('sendPatrol', data.troops);
            commit('setAwaitingResponse', true);
            let troops = `[`;

            for (let i = 0; i < data.troops.length; i++) {
                troops += `{troopsName: ${JSON.stringify(data.troops[i].troopsName)}, numOfTroops: [${
                    data.troops[i].numOfTroops
                }], owned: ${data.troops[i].owned}}`;
                if (i === data.troops.length - 1) {
                    troops += `]`;
                } else {
                    troops += `, `;
                }
            }

            axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{patrolWithPlanes(range: ${data.range}, troops: ${troops}, ${
                            data.reinforcements.length ? 'reinforcements:' + JSON.stringify(data.reinforcements) + ',' : ''
                        } attackOnSight: ${data.attackOnSight}, duration: ${data.duration})}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async sendInterceptingAttack({ commit }, data) {
            console.log('sendInterceptingAttack', data.troops);
            commit('setAwaitingResponse', true);
            let troops = `[`;

            for (let i = 0; i < data.troops.length; i++) {
                troops += `{troopsName: ${JSON.stringify(data.troops[i].troopsName)}, numOfTroops: [${
                    data.troops[i].numOfTroops
                }], owned: ${data.troops[i].owned}}`;
                if (i === data.troops.length - 1) {
                    troops += `]`;
                } else {
                    troops += `, `;
                }
            }

            axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{interceptingAttack(attack: "${data.attackId}", troops: ${troops}, ${
                            data.reinforcements.length ? 'reinforcements:' + JSON.stringify(data.reinforcements) + ',' : ''
                        })}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async modifyResourcesPercentage(obj, data) {
            let percentages = `[`;

            for (let i = 0; i < data.percentages.length; i++) {
                if (i === data.percentages.length - 1) {
                    percentages += `${data.percentages[i]}]`;
                } else {
                    percentages += `${data.percentages[i]}, `;
                }
            }

            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{
              modifyPercentagesStorage(percentages:${percentages})
            }`,
                    },
                    { withCredentials: true },
                )
                .then(response => {
                    if (!response.data) return false;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        async fetchOutsideOffers({ commit }) {
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `{
              getOffers ${parseTemplateForQuery(OFFERS_TEMPLATE)}}`,
                    },
                    { withCredentials: true },
                )
                .then(response => {
                    if (response.data) {
                        console.log('OUTSIDE OFFERS:::', response.data?.data?.getOffers);
                        commit('setOutsideOffers', response.data?.data?.getOffers);
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        },

        async sendBuildingCommand({ commit }, data) {
            commit('setAwaitingResponse', true);
            axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{
                  commands(name: "${data.name}")
                    }
                    `,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async startTroopRecruitmentCommand({ commit }, data) {
            commit('setAwaitingResponse', true);
            axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{
                      troopCommand(
                          name: "${data.name}", 
                          numOfTroops: ${data.numOfTroops}
                          )
                    }
                    
                    `,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async makeOffer({ commit }, { wanted, offered, repeats, maximumTime }) {
            // wanted/offered: { name: string, quantity: number }, repeats: number, maximumTime: number
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{makeOffer(
                            resourceToOffer: {name: "${offered.name}",quantity: ${offered.quantity}},
                            resourceToGet: {name: "${wanted.name}", quantity: ${wanted.quantity}},
                            repeats: ${repeats},
                            maximumTime: ${maximumTime})
                        }`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                    commit('lowerResources', { [offered.name]: offered.quantity });
                });
        },

        async acceptOffer({ commit }, { offer, repeats, resources }) {
            // offer: string, repeats: number, resources: {name: string, quantity: number}[]
            let resourcesString = `[`;

            for (let i = 0; i < resources.length; i++) {
                resourcesString += `{name: "${resources[i].name}", quantity: ${resources[i].quantity}}`;
                if (i === resources.length - 1) {
                    resourcesString += `]`;
                } else {
                    resourcesString += `, `;
                }
            }

            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{
                            acceptOffer(
                            offer: "${offer}",
                            repeats: ${repeats},
                            resources: ${resourcesString})
                        }`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);

                    const ResourcesToLower = {};
                    resources.forEach(resource => {
                        ResourcesToLower[resource.name] = resource.quantity;
                    });

                    commit('lowerResources', ResourcesToLower);
                });
        },

        async cancelOffer({ commit, getters }, { offer_id }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{
                            cancelOffer(offerId: "${offer_id}")
                        }`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);

                    const Offer = getters.getCurrentCityOffers.find(offer => offer._id === offer_id);
                    commit('increaseResources', { [Offer.resourceToOffer.name]: Offer.resourceToOffer.quantity });

                    commit('removeOffer', offer_id);
                });
        },

        async sendResources({ commit }, { resources, destination }) {
            // offer: string, repeats: number, resources: {name: string, quantity: number}[]
            let resourcesString = `[`;

            for (let i = 0; i < resources.length; i++) {
                resourcesString += `{name: "${resources[i].name}", quantity: ${resources[i].quantity}}`;
                if (i === resources.length - 1) {
                    resourcesString += `]`;
                } else {
                    resourcesString += `, `;
                }
            }

            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{
                            sendResources(resources: ${resourcesString},
                            destination: "${destination}")
                        }`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async createCoalition({ commit }, { name, abbreviation }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{createCoalition(name: "${name}", abbreviation: "${abbreviation}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async dissolveCoalition({ commit }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{dissolveCoalition}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async invitePlayerToCoalition({ commit }, { user_id }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{inviteUserToCoalition(id: "${user_id}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async retractInviteToCoalition({ commit }, { invite_id }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{retractInviteToCoalition(inviteId: "${invite_id}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                    // remove invite from state
                });
        },

        async acceptCoalitionInvite({ commit }, { invite_id }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{acceptInviteToCoalition(inviteId: "${invite_id}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async leaveCoalition({ commit }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{leaveCoalition}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async changeCoalitionMemberRank({ commit }, { promote, userId }) {
            const MEMBER_DEFAULT_RIGHTS = [
                { permission: 'SendAllMessage', hasIt: false },
                { permission: 'Diplomacy', hasIt: false },
                { permission: 'Invitation', hasIt: false },
                { permission: 'Exclude_Members', hasIt: false },
                { permission: 'Modify_Members_Rights', hasIt: false },
                { permission: 'Modify_Coalition', hasIt: false },
                { permission: 'Dissolute_Coalition', hasIt: false },
            ];
            const LIEUTENANT_DEFAULT_RIGHTS = [
                { permission: 'SendAllMessage', hasIt: true },
                { permission: 'Diplomacy', hasIt: false },
                { permission: 'Invitation', hasIt: true },
                { permission: 'Exclude_Members', hasIt: true },
                { permission: 'Modify_Members_Rights', hasIt: true },
                { permission: 'Modify_Coalition', hasIt: false },
                { permission: 'Dissolute_Coalition', hasIt: false },
            ];
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{promoteOrDemoteMember(promote: ${promote}, id: "${userId}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                    commit('updateMemberRank', { id: userId, rank: promote ? 'Lieutenant' : 'Member' });
                    commit('updateMemberRights', {
                        ids: [userId],
                        permissions: promote ? [LIEUTENANT_DEFAULT_RIGHTS] : [MEMBER_DEFAULT_RIGHTS],
                    });
                });
        },

        async kickCoalitionMember({ commit }, { userId }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{kickMemberOutOfCoalition(userId: "${userId}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async changeCoalitionRights({ commit }, { ids, permissions }) {
            function parsePermissions(array) {
                let string = '[';
                array.forEach(member_array => {
                    string += '[';
                    member_array.forEach(permission_object => {
                        string += '{';
                        const keys = Object.keys(permission_object);
                        const values = Object.values(permission_object);
                        keys.forEach((k, index) => {
                            string += `${k}: ${typeof values[index] === 'boolean' ? values[index] : '"' + values[index] + '"'},`;
                        });
                        string = string.substring(0, string.length - 1);
                        string += '},';
                    });
                    string = string.substring(0, string.length - 1);
                    string += '],';
                });
                string = string.substring(0, string.length - 1);
                string += ']';
                return string;
            }

            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{modifyRightsOfMembers(ids: ${JSON.stringify(ids)}, permissions: ${parsePermissions(
                            permissions,
                        )})}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                    commit('updateMemberRights', { ids, permissions });
                });
        },

        async changeCoalitionProperties({ commit }, { properties }) {
            // properties: name, abbreviation, canAttackAllies
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{changedPropertiesOfCoalition(name: "${properties.name}", abbreviation: "${properties.abbreviation}", canAttackAllies: ${properties.canAttackAllies})}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async changeCoalitionDescription({ commit }, { description }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{changeDescriptionOfCoalition(description: "${description}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async changeCoalitionEnlistmentOption({ commit }, { option }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{changeCoalitionAdditionRule(additionRule: "${option}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async joinCoalition({ commit }, { coalition_id }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{enterCoalition(coalitionId: "${coalition_id}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async requestToJoinCoalition({ commit }, { coalition_id }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{requestToJoinCoalition(coalitionId: "${coalition_id}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async acceptRequestToJoinCoalition({ commit }, { request_id }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{acceptRequestToJoinCoalition(requestId: "${request_id}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async rejectRequestToJoinCoalition({ commit }, { request_id }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{rejectRequestToJoinCoalition(requestId: "${request_id}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                    // remove request from state
                });
        },

        async retractRequestToJoinCoalition({ commit }, { request_id }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{retractRequestToJoinCoalition(requestId: "${request_id}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                    // remove request from state
                });
        },

        async rejectDiplomacyRequest({ commit }, { invite_id }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{rejectInviteToPact(inviteId: "${invite_id}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                    commit('updatePactInvitations', invite_id);
                });
        },

        async retractDiplomacyRequest({ commit }, { invite_id }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{retractInviteToPact(inviteId: "${invite_id}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                    commit('updatePactInvitations', invite_id);
                });
        },

        async declareWarToCoalition({ commit }, { coalition_id }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{declareWarToCoalition(coalitionId: "${coalition_id}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async proposePeaceWithCoalition({ commit }, { coalition_id, war_id, type_of_peace, tribute }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{proposePeaceWithCoalition(coalitionId: "${coalition_id}", warId: "${war_id}", typeOfPeace: "${type_of_peace}", tribute: ${tribute})}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async acceptPeaceProposal({ commit }, { invite_id }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{acceptPeaceProposal(inviteId: "${invite_id}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                    commit('updatePactInvitations', invite_id);
                });
        },

        async inviteCoalitionToNonAggressionPact({ commit }, { coalition_id }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{inviteCoalitionToNonAggresionPact(coalitionId: "${coalition_id}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async acceptInviteToNonAggressionPact({ commit }, { invite_id }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{acceptInviteToNonAggresionPact(inviteId: "${invite_id}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                    commit('updatePactInvitations', invite_id);
                });
        },

        async breakNonAggressionPact({ commit }, { coalition_id }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{breakNonAggressionPact(coalitionId: "${coalition_id}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async inviteCoalitionToAlliance({ commit }, { coalition_id }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{inviteCoalitionToAlliance(coalitionId: "${coalition_id}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        async acceptInviteToAlliance({ commit }, { invite_id }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{acceptInviteToAlliance(inviteId: "${invite_id}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                    commit('updatePactInvitations', invite_id);
                });
        },

        async breakAlliance({ commit }, { coalition_id }) {
            commit('setAwaitingResponse', true);
            await axios
                .post(
                    `${process.env.VUE_APP_ADDRESS_MAIN}`,
                    {
                        query: `mutation{breakAlliance(coalitionId: "${coalition_id}")}`,
                    },
                    { withCredentials: true },
                )
                .catch(e => {
                    console.log(e);
                })
                .then(() => {
                    commit('setAwaitingResponse', false);
                });
        },

        lowerResourcesAfterBuildingCommand({ commit }, resources) {
            commit('lowerResources', resources);
        },

        assignCountdownStarted({ commit }, value) {
            commit('setCountdownStarted', value);
        },

        assignCountdownStartedTroopCommands({ commit }, value) {
            commit('setCountdownStartedTroopCommands', value);
        },

        removeWarEffectsAction({ commit }, { war_id }) {
            commit('removeWarEffects', { war_id });
        },
    },
    getters: {
        getAwaitingResponse(state) {
            return state.awaitingResponse;
        },

        getAttacks(state) {
            return state.currentCity.attacks;
        },

        getAttacksReturning(state) {
            return state.currentCity.attacksReturning;
        },

        getReinforcements(state) {
            return state.currentCity.reinforcements;
        },

        getReinforcementsReturning(state) {
            return state.currentCity.reinforcementsReturning;
        },

        getDetectSpiesMissions(state) {
            return state.currentCity.detectSpiesMissions;
        },

        getSpyMissions(state) {
            return state.currentCity.spyMovements;
        },

        getReturningSpies(state) {
            return state.currentCity.spyMovementsReturning;
        },

        getPatrols(state) {
            return state.currentCity.patrols;
        },

        getIntercepts(state) {
            return state.currentCity.attackIntercepts;
        },

        getReturningIntercepts(state) {
            return state.currentCity.attackInterceptsReturning;
        },

        getTransports(state) {
            return state.currentCity.transports;
        },

        getReturningTransports(state) {
            return state.currentCity.transportsReturning;
        },

        getForeignSpies(state) {
            return state.currentCity.foreignSpies;
        },

        getLastAttack(state) {
            if (state.currentCity.attacks.length) return state.currentCity.attacks[state.currentCity.attacks.length - 1];
        },

        getBuildings(state) {
            return state.currentCity.buildings;
        },

        getBuildingsCommands(state) {
            return state.currentCity.commands;
        },

        getCountdownStarted(state) {
            return state.countdownStarted;
        },

        getCountdownStartedTroopCommands(state) {
            return state.countdownStartedTroopCommands;
        },

        getResources(state) {
            return {
                aluminum: state.currentCity.aluminum,
                oil: state.currentCity.oil,
                metal: state.currentCity.metal,
                rations: state.currentCity.rations,
            };
        },

        getResourcesProductions(state) {
            return {
                aluminumProduction: state.currentCity.aluminumProduction,
                oilProduction: state.currentCity.oilProduction,
                metalProduction: state.currentCity.metalProduction,
                rationsProduction: state.currentCity.population,
            };
        },

        getTroops(state) {
            return state.currentCity.troops;
        },

        getTroopsCommands(state) {
            return state.currentCity.troopCommands;
        },

        getIsSubscribed(state) {
            return state.isSubscribed;
        },

        getMapMatrix(state) {
            return state.mapMatrix;
        },

        getDecorationsMatrix(state) {
            return state.decorationsMatrix;
        },

        getAllUsersFromState(state) {
            return state.allUsers;
        },

        getUserInfo(state) {
            return {
                email: state.userState.email,
                username: state.userState.username,
                _id: state.userState._id,
                verified: state.userState.verified,
            };
        },

        getAllUserCities(state) {
            return state.userState.cities;
        },

        getCurrentCityName(state) {
            return state.currentCity.name;
        },

        getCurrentCityTile(state) {
            return state.currentCity.tile;
        },

        getCurrentCityId(state) {
            return state.currentCity._id;
        },

        getCurrentCityCoords(state) {
            return {
                x: state.currentCity.x,
                y: state.currentCity.y,
            };
        },

        getMapActionTarget(state) {
            return state.mapActionTarget;
        },

        getCoalitionInfo(state) {
            return state.userState.coalition;
        },

        getReports(state) {
            return state.userState.reports;
        },

        getRequests(state) {
            return state.userState.requests;
        },

        getCloseCoalitions(state) {
            return state.currentCity.closeCoalitions;
        },

        getCurrentCityOffers(state) {
            return state.currentCity.offers;
        },

        getOutsideOffers(state) {
            return state.offers;
        },

        getCurrentCityMapActions(state) {
            return state.currentCityMapActions.collection.items;
        },

        getMapArrows(state) {
            return state.mapArrows;
        },
    },
};
