<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">General Office</h5>
                    <div class="building-description">
                        {{ description }}
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    {{ numberOfOwnedSpiesToCallBack }}<br />
                    <!--					{{ getControlledReinforcementsIdArray('detect_mission') }}<br />-->
                    <!--                    {{ getTroopsAndReinforcementsForCommand('call_back') }}<br />-->
                    <!--                    {{ getControlledReinforcementsIdArray('call_back') }}-->
                    <ul class="nav nav-tabs" id="general-office-nav" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link active"
                                id="commands"
                                data-bs-toggle="tab"
                                data-bs-target="#commands-page"
                                type="button"
                                role="tab"
                                aria-controls="commands-tab"
                                aria-selected="true"
                            >
                                Commands
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="espionage"
                                data-bs-toggle="tab"
                                data-bs-target="#espionage-page"
                                type="button"
                                role="tab"
                                aria-controls="espionage-tab"
                                aria-selected="false"
                            >
                                Espionage
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="reinforcements"
                                data-bs-toggle="tab"
                                data-bs-target="#reinforcements-page"
                                type="button"
                                role="tab"
                                aria-controls="reinforcements-tab"
                                aria-selected="false"
                            >
                                Reinforcements
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="commands"
                                data-bs-toggle="tab"
                                data-bs-target="#troops-page"
                                type="button"
                                role="tab"
                                aria-controls="troops-tab"
                                aria-selected="false"
                            >
                                Troops
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="recruitment"
                                data-bs-toggle="tab"
                                data-bs-target="#recruitment-page"
                                type="button"
                                role="tab"
                                aria-controls="recruitment-tab"
                                aria-selected="false"
                            >
                                Recruitment
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="simulator"
                                data-bs-toggle="tab"
                                data-bs-target="#simulator-page"
                                type="button"
                                role="tab"
                                aria-controls="simulator-tab"
                                aria-selected="false"
                            >
                                Simulator
                            </button>
                        </li>
                        <li class="nav-filler" />
                    </ul>
                    <div class="tab-content" id="general-office-pages">
                        <div class="tab-pane fade show active" id="commands-page" role="tabpanel" aria-labelledby="commands-tab">
                            <div class="send-command-wrapper">
                                <div class="coords-wrapper">
                                    <div class="row">
                                        <div class="col label">Target</div>
                                    </div>
                                    <div class="row">
                                        <div class="col justify-content-around">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault4"
                                                    id="coords"
                                                    checked
                                                />
                                                <label class="form-check-label" for="coords">Coordinates</label>
                                            </div>
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault4"
                                                    id="city-name"
                                                />
                                                <label class="form-check-label" for="city-name">City Name</label>
                                            </div>
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault4"
                                                    id="player-name"
                                                />
                                                <label class="form-check-label" for="player-name">Player Name</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <input class="form-control" :placeholder="formattedTargetCity" />
                                        </div>
                                    </div>
                                </div>
                                <div class="command-buttons-wrapper">
                                    <button class="btn red-button" :disabled="awaitingResponse" @click="validateAndSendAttack">
                                        Attack
                                    </button>
                                    <button
                                        class="btn yellow-button"
                                        :disabled="awaitingResponse"
                                        @click="validateAndSendReinforcement"
                                    >
                                        Support
                                    </button>
                                </div>
                            </div>
                            <div class="troops-table-wrapper">
                                <div class="troops-table">
                                    <div class="row">
                                        <div class="col label">Infantry</div>
                                    </div>
                                    <div class="row">
                                        <div class="col label">Unit</div>
                                        <div class="col label">Owned</div>
                                        <div class="col label">Controlled</div>
                                    </div>
                                    <div
                                        v-for="(troop, index) in troops.soldiers.filter(
                                            trp => trp.numOfTroops > 0 || trp.controlledReinforcements > 0,
                                        )"
                                        :key="index"
                                        class="row"
                                    >
                                        <div class="col">
                                            <div class="troop-image-wrapper">
                                                <img
                                                    :src="require(`../assets/icons/troops/${toKebabCase(troop.name)}-tall.avif`)"
                                                    alt=""
                                                    class="troop-image"
                                                    @click="openTroopDetailsModal(troop)"
                                                />
                                            </div>
                                        </div>
                                        <div class="col">
                                            <!-- TO DO: refactor v-model -->
                                            <input
                                                v-model="
                                                    numberOfOwnedTroopsToSend['soldiers'][
                                                        numberOfOwnedTroopsToSend['soldiers'].find(trp => trp.name === troop.name)
                                                            .index
                                                    ]['number']
                                                "
                                                min="0"
                                                :max="troop.numOfTroops"
                                                class="form-control"
                                            />
                                            <span
                                                class="max-troops-to-send"
                                                @click="
                                                    assignNumberOfOwnedTroopsToSend(
                                                        'soldiers',
                                                        numberOfOwnedTroopsToSend['soldiers'].find(trp => trp.name === troop.name)
                                                            .index,
                                                        'number',
                                                        troop.numOfTroops,
                                                    )
                                                "
                                                >({{ troop.numOfTroops.toLocaleString('ro') }})</span
                                            >
                                        </div>
                                        <div class="col controlled-reinforcements-select-column">
                                            <input
                                                v-if="getControlledReinforcementsPerTroop(troop.name)?.length === 0"
                                                value="0"
                                                class="form-control"
                                                disabled
                                            />
                                            <input
                                                v-else-if="getControlledReinforcementsPerTroop(troop.name)?.length === 1"
                                                v-model="getControlledReinforcementsPerTroop(troop.name)[0].toSend"
                                                class="form-control"
                                            />
                                            <input
                                                v-else
                                                :value="
                                                    calculateTroopsSelectedFromDropdown(
                                                        getControlledReinforcementsPerTroop(troop.name),
                                                    )
                                                "
                                                class="form-control"
                                                disabled
                                            />
                                            <span
                                                class="max-troops-to-send"
                                                @click="assignAllControlledReinforcementsByTroop('all_troops', troop.name)"
                                                >({{ troop.controlledReinforcements.toLocaleString('ro') }})</span
                                            >
                                            <button
                                                v-if="getControlledReinforcementsPerTroop(troop.name)?.length > 1"
                                                class="btn neutral-button reinforcements-dropdown-button"
                                                @click="toggleExtraReinforcementOptionsVisibility('soldiers', index)"
                                            >
                                                <span>&#10095;</span>
                                            </button>
                                            <Transition name="tooltip-transition">
                                                <div
                                                    v-if="extraReinforcementOptionsVisible['soldiers'][index]"
                                                    class="reinforcements-dropdown-wrapper"
                                                >
                                                    <div class="row">
                                                        <div class="col label">From</div>
                                                        <div class="col label">City</div>
                                                        <div class="col label">Troops</div>
                                                    </div>
                                                    <div
                                                        v-for="(
                                                            reinforcement, reinforcement_index
                                                        ) in getControlledReinforcementsPerTroop(troop.name)"
                                                        :key="reinforcement_index"
                                                        class="row"
                                                    >
                                                        <div class="col">
                                                            {{ reinforcement.fromUser?.username }}
                                                        </div>
                                                        <!-- TO DO: add city image before name -->
                                                        <div class="col">
                                                            {{ reinforcement.fromCity?.name }}
                                                        </div>
                                                        <div class="col">
                                                            <div class="troop-image-wrapper">
                                                                <img
                                                                    :src="
                                                                        require(`../assets/icons/troops/${toKebabCase(
                                                                            reinforcement.troopName,
                                                                        )}-tall.avif`)
                                                                    "
                                                                    alt=""
                                                                    class="troop-image"
                                                                    @click="
                                                                        openTroopDetailsModal(
                                                                            getTroopFromControlledReinforcements(
                                                                                reinforcement.troopName,
                                                                            ),
                                                                        )
                                                                    "
                                                                />
                                                            </div>
                                                            <input
                                                                v-model="reinforcement.toSend"
                                                                min="0"
                                                                :max="reinforcement.numOfTroops"
                                                                class="form-control"
                                                            />
                                                            <span
                                                                class="max-troops-to-send"
                                                                @click="
                                                                    assignNumberOfControlledReinforcementsToSend(
                                                                        'all_troops',
                                                                        reinforcement.index,
                                                                        reinforcement.numOfTroops,
                                                                    )
                                                                "
                                                                >({{ reinforcement.numOfTroops.toLocaleString('ro') }})</span
                                                            >
                                                        </div>
                                                    </div>
                                                </div>
                                            </Transition>
                                        </div>
                                    </div>
                                </div>
                                <div class="troops-table">
                                    <div class="row">
                                        <div class="col label">Armored</div>
                                    </div>
                                    <div class="row">
                                        <div class="col label">Unit</div>
                                        <div class="col label">Owned</div>
                                        <div class="col label">Controlled</div>
                                    </div>
                                    <div
                                        v-for="(troop, index) in troops.tanks.filter(
                                            troop => troop.numOfTroops > 0 || troop.controlledReinforcements > 0,
                                        )"
                                        :key="index"
                                        class="row"
                                    >
                                        <div class="col">
                                            <div class="troop-image-wrapper">
                                                <img
                                                    :src="require(`../assets/icons/troops/${toKebabCase(troop.name)}-tall.avif`)"
                                                    alt=""
                                                    class="troop-image"
                                                    @click="openTroopDetailsModal(troop)"
                                                />
                                            </div>
                                        </div>
                                        <div class="col">
                                            <!-- TO DO: refactor v-model -->
                                            <input
                                                v-model="
                                                    numberOfOwnedTroopsToSend['tanks'][
                                                        numberOfOwnedTroopsToSend['tanks'].find(trp => trp.name === troop.name)
                                                            .index
                                                    ]['number']
                                                "
                                                min="0"
                                                :max="troop.numOfTroops"
                                                class="form-control"
                                            />
                                            <span
                                                class="max-troops-to-send"
                                                @click="
                                                    assignNumberOfOwnedTroopsToSend(
                                                        'tanks',
                                                        numberOfOwnedTroopsToSend['tanks'].find(trp => trp.name === troop.name)
                                                            .index,
                                                        'number',
                                                        troop.numOfTroops,
                                                    )
                                                "
                                                >({{ troop.numOfTroops.toLocaleString('ro') }})</span
                                            >
                                        </div>
                                        <div class="col controlled-reinforcements-select-column">
                                            <input
                                                v-if="getControlledReinforcementsPerTroop(troop.name)?.length === 0"
                                                value="0"
                                                class="form-control"
                                                disabled
                                            />
                                            <input
                                                v-else-if="getControlledReinforcementsPerTroop(troop.name)?.length === 1"
                                                v-model="getControlledReinforcementsPerTroop(troop.name)[0].toSend"
                                                class="form-control"
                                            />
                                            <input
                                                v-else
                                                :value="
                                                    calculateTroopsSelectedFromDropdown(
                                                        getControlledReinforcementsPerTroop(troop.name),
                                                    )
                                                "
                                                class="form-control"
                                                disabled
                                            />
                                            <span
                                                class="max-troops-to-send"
                                                @click="assignAllControlledReinforcementsByTroop('all_troops', troop.name)"
                                                >({{ troop.controlledReinforcements.toLocaleString('ro') }})</span
                                            >
                                            <button
                                                v-if="getControlledReinforcementsPerTroop(troop.name)?.length > 1"
                                                class="btn neutral-button reinforcements-dropdown-button"
                                                @click="toggleExtraReinforcementOptionsVisibility('tanks', index)"
                                            >
                                                <span>&#10095;</span>
                                            </button>
                                            <Transition name="tooltip-transition">
                                                <div
                                                    v-if="extraReinforcementOptionsVisible['tanks'][index]"
                                                    class="reinforcements-dropdown-wrapper"
                                                >
                                                    <div class="row">
                                                        <div class="col label">From</div>
                                                        <div class="col label">City</div>
                                                        <div class="col label">Troops</div>
                                                    </div>
                                                    <div
                                                        v-for="(
                                                            reinforcement, reinforcement_index
                                                        ) in getControlledReinforcementsPerTroop(troop.name)"
                                                        :key="reinforcement_index"
                                                        class="row"
                                                    >
                                                        <div class="col">
															{{ reinforcement.fromUser?.username }}
                                                        </div>
                                                        <!-- TO DO: add city image before name -->
                                                        <div class="col">
                                                            {{ reinforcement.fromCity?.name }}
                                                        </div>
                                                        <div class="col">
                                                            <div class="troop-image-wrapper">
                                                                <img
                                                                    :src="
                                                                        require(`../assets/icons/troops/${toKebabCase(
                                                                            reinforcement.troopName,
                                                                        )}-tall.avif`)
                                                                    "
                                                                    alt=""
                                                                    class="troop-image"
                                                                    @click="
                                                                        openTroopDetailsModal(
                                                                            getTroopFromControlledReinforcements(
                                                                                reinforcement.troopName,
                                                                            ),
                                                                        )
                                                                    "
                                                                />
                                                            </div>
                                                            <input
                                                                v-model="reinforcement.toSend"
                                                                min="0"
                                                                :max="reinforcement.numOfTroops"
                                                                class="form-control"
                                                            />
                                                            <span
                                                                class="max-troops-to-send"
                                                                @click="
                                                                    assignNumberOfControlledReinforcementsToSend(
                                                                        'all_troops',
                                                                        reinforcement.index,
                                                                        reinforcement.numOfTroops,
                                                                    )
                                                                "
                                                                >({{ reinforcement.numOfTroops.toLocaleString('ro') }})</span
                                                            >
                                                        </div>
                                                    </div>
                                                </div>
                                            </Transition>
                                        </div>
                                    </div>
                                </div>
                                <div class="troops-table">
                                    <div class="row">
                                        <div class="col label">Planes</div>
                                    </div>
                                    <div class="row">
                                        <div class="col label">Unit</div>
                                        <div class="col label">Owned</div>
                                        <div class="col label">Controlled</div>
                                    </div>
                                    <div
                                        v-for="(troop, index) in troops.planes.filter(
                                            troop => troop.numOfTroops > 0 || troop.controlledReinforcements > 0,
                                        )"
                                        :key="index"
                                        class="row"
                                    >
                                        <div class="col">
                                            <div class="troop-image-wrapper">
                                                <img
                                                    :src="require(`../assets/icons/troops/${toKebabCase(troop.name)}-tall.avif`)"
                                                    alt=""
                                                    class="troop-image"
                                                    @click="openTroopDetailsModal(troop)"
                                                />
                                            </div>
                                        </div>
                                        <div class="col">
                                            <!-- TO DO: refactor v-model -->
                                            <input
                                                v-model="
                                                    numberOfOwnedTroopsToSend['planes'][
                                                        numberOfOwnedTroopsToSend['planes'].find(trp => trp.name === troop.name)
                                                            .index
                                                    ]['number']
                                                "
                                                min="0"
                                                :max="troop.numOfTroops"
                                                class="form-control"
                                            />
                                            <span
                                                class="max-troops-to-send"
                                                @click="
                                                    assignNumberOfOwnedTroopsToSend(
                                                        'planes',
                                                        numberOfOwnedTroopsToSend['planes'].find(trp => trp.name === troop.name)
                                                            .index,
                                                        'number',
                                                        troop.numOfTroops,
                                                    )
                                                "
                                                >({{ troop.numOfTroops.toLocaleString('ro') }})</span
                                            >
                                        </div>
                                        <div class="col controlled-reinforcements-select-column">
                                            <input
                                                v-if="getControlledReinforcementsPerTroop(troop.name)?.length === 0"
                                                value="0"
                                                class="form-control"
                                                disabled
                                            />
                                            <input
                                                v-else-if="getControlledReinforcementsPerTroop(troop.name)?.length === 1"
                                                v-model="getControlledReinforcementsPerTroop(troop.name)[0].toSend"
                                                class="form-control"
                                            />
                                            <input
                                                v-else
                                                :value="
                                                    calculateTroopsSelectedFromDropdown(
                                                        getControlledReinforcementsPerTroop(troop.name),
                                                    )
                                                "
                                                class="form-control"
                                                disabled
                                            />
                                            <span
                                                class="max-troops-to-send"
                                                @click="assignAllControlledReinforcementsByTroop('all_troops', troop.name)"
                                                >({{ troop.controlledReinforcements.toLocaleString('ro') }})</span
                                            >
                                            <button
                                                v-if="getControlledReinforcementsPerTroop(troop.name)?.length > 1"
                                                class="btn neutral-button reinforcements-dropdown-button"
                                                @click="toggleExtraReinforcementOptionsVisibility('planes', index)"
                                            >
                                                <span>&#10095;</span>
                                            </button>
                                            <Transition name="tooltip-transition">
                                                <div
                                                    v-if="extraReinforcementOptionsVisible['planes'][index]"
                                                    class="reinforcements-dropdown-wrapper"
                                                >
                                                    <div class="row">
                                                        <div class="col label">From</div>
                                                        <div class="col label">City</div>
                                                        <div class="col label">Troops</div>
                                                    </div>
                                                    <div
                                                        v-for="(
                                                            reinforcement, reinforcement_index
                                                        ) in getControlledReinforcementsPerTroop(troop.name)"
                                                        :key="reinforcement_index"
                                                        class="row"
                                                    >
                                                        <div class="col">
															{{ reinforcement.fromUser?.username }}
                                                        </div>
                                                        <!-- TO DO: add city image before name -->
                                                        <div class="col">
                                                            {{ reinforcement.fromCity?.name }}
                                                        </div>
                                                        <div class="col">
                                                            <div class="troop-image-wrapper">
                                                                <img
                                                                    :src="
                                                                        require(`../assets/icons/troops/${toKebabCase(
                                                                            reinforcement.troopName,
                                                                        )}-tall.avif`)
                                                                    "
                                                                    alt=""
                                                                    class="troop-image"
                                                                    @click="
                                                                        openTroopDetailsModal(
                                                                            getTroopFromControlledReinforcements(
                                                                                reinforcement.troopName,
                                                                            ),
                                                                        )
                                                                    "
                                                                />
                                                            </div>
                                                            <input
                                                                v-model="reinforcement.toSend"
                                                                min="0"
                                                                :max="reinforcement.numOfTroops"
                                                                class="form-control"
                                                            />
                                                            <span
                                                                class="max-troops-to-send"
                                                                @click="
                                                                    assignNumberOfControlledReinforcementsToSend(
                                                                        'all_troops',
                                                                        reinforcement.index,
                                                                        reinforcement.numOfTroops,
                                                                    )
                                                                "
                                                                >({{ reinforcement.numOfTroops.toLocaleString('ro') }})</span
                                                            >
                                                        </div>
                                                    </div>
                                                </div>
                                            </Transition>
                                        </div>
                                    </div>
                                </div>
                                <div class="troops-table">
                                    <div class="row">
                                        <div class="col label">Other</div>
                                    </div>
                                    <div class="row">
                                        <div class="col label">Unit</div>
                                        <div class="col label">Owned</div>
                                        <div class="col label">Controlled</div>
                                    </div>
                                    <div
                                        v-for="(troop, index) in troops.other.filter(
                                            troop => troop.numOfTroops > 0 || troop.controlledReinforcements > 0,
                                        )"
                                        :key="index"
                                        class="row"
                                    >
                                        <div class="col">
                                            <div class="troop-image-wrapper">
                                                <img
                                                    :src="require(`../assets/icons/troops/${toKebabCase(troop.name)}-tall.avif`)"
                                                    alt=""
                                                    class="troop-image"
                                                    @click="openTroopDetailsModal(troop)"
                                                />
                                            </div>
                                        </div>
                                        <div class="col">
                                            <!-- TO DO: refactor v-model -->
                                            <input
                                                v-model="
                                                    numberOfOwnedTroopsToSend['other'][
                                                        numberOfOwnedTroopsToSend['other'].find(trp => trp.name === troop.name)
                                                            .index
                                                    ]['number']
                                                "
                                                min="0"
                                                :max="troop.numOfTroops"
                                                class="form-control"
                                            />
                                            <span
                                                class="max-troops-to-send"
                                                @click="
                                                    assignNumberOfOwnedTroopsToSend(
                                                        'other',
                                                        numberOfOwnedTroopsToSend['other'].find(trp => trp.name === troop.name)
                                                            .index,
                                                        'number',
                                                        troop.numOfTroops,
                                                    )
                                                "
                                                >({{ troop.numOfTroops.toLocaleString('ro') }})</span
                                            >
                                        </div>
                                        <div class="col controlled-reinforcements-select-column">
                                            <input
                                                v-if="getControlledReinforcementsPerTroop(troop.name)?.length === 0"
                                                value="0"
                                                class="form-control"
                                                disabled
                                            />
                                            <input
                                                v-else-if="getControlledReinforcementsPerTroop(troop.name)?.length === 1"
                                                v-model="getControlledReinforcementsPerTroop(troop.name)[0].toSend"
                                                class="form-control"
                                            />
                                            <input
                                                v-else
                                                :value="
                                                    calculateTroopsSelectedFromDropdown(
                                                        getControlledReinforcementsPerTroop(troop.name),
                                                    )
                                                "
                                                class="form-control"
                                                disabled
                                            />
                                            <span
                                                class="max-troops-to-send"
                                                @click="assignAllControlledReinforcementsByTroop('all_troops', troop.name)"
                                                >({{ troop.controlledReinforcements.toLocaleString('ro') }})</span
                                            >
                                            <button
                                                v-if="getControlledReinforcementsPerTroop(troop.name)?.length > 1"
                                                class="btn neutral-button reinforcements-dropdown-button"
                                                @click="toggleExtraReinforcementOptionsVisibility('other', index)"
                                            >
                                                <span>&#10095;</span>
                                            </button>
                                            <Transition name="tooltip-transition">
                                                <div
                                                    v-if="extraReinforcementOptionsVisible['other'][index]"
                                                    class="reinforcements-dropdown-wrapper"
                                                >
                                                    <div class="row">
                                                        <div class="col label">From</div>
                                                        <div class="col label">City</div>
                                                        <div class="col label">Troops</div>
                                                    </div>
                                                    <div
                                                        v-for="(
                                                            reinforcement, reinforcement_index
                                                        ) in getControlledReinforcementsPerTroop(troop.name)"
                                                        :key="reinforcement_index"
                                                        class="row"
                                                    >
                                                        <div class="col">
															{{ reinforcement.fromUser?.username }}
                                                        </div>
                                                        <!-- TO DO: add city image before name -->
                                                        <div class="col">
                                                            {{ reinforcement.fromCity?.name }}
                                                        </div>
                                                        <div class="col">
                                                            <div class="troop-image-wrapper">
                                                                <img
                                                                    :src="
                                                                        require(`../assets/icons/troops/${toKebabCase(
                                                                            reinforcement.troopName,
                                                                        )}-tall.avif`)
                                                                    "
                                                                    alt=""
                                                                    class="troop-image"
                                                                    @click="
                                                                        openTroopDetailsModal(
                                                                            getTroopFromControlledReinforcements(
                                                                                reinforcement.troopName,
                                                                            ),
                                                                        )
                                                                    "
                                                                />
                                                            </div>
                                                            <input
                                                                v-model="reinforcement.toSend"
                                                                min="0"
                                                                :max="reinforcement.numOfTroops"
                                                                class="form-control"
                                                            />
                                                            <span
                                                                class="max-troops-to-send"
                                                                @click="
                                                                    assignNumberOfControlledReinforcementsToSend(
                                                                        'all_troops',
                                                                        reinforcement.index,
                                                                        reinforcement.numOfTroops,
                                                                    )
                                                                "
                                                                >({{ reinforcement.numOfTroops.toLocaleString('ro') }})</span
                                                            >
                                                        </div>
                                                    </div>
                                                </div>
                                            </Transition>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="espionage-page" role="tabpanel" aria-labelledby="espionage-tab">
                            <div class="table-wrapper">
                                <div class="troops-table">
                                    <div class="row label">
                                        <div class="col">Unit</div>
                                        <div class="col">Total</div>
                                        <div class="col">Owned</div>
                                        <div class="col">Controlled</div>
                                        <div class="col">Stationed</div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="troop-image-wrapper">
                                                <img
                                                    src="../assets/icons/troops/spy-tall.avif"
                                                    alt=""
                                                    class="troop-image"
                                                    @click="openTroopDetailsModal(spies)"
                                                />
                                            </div>
                                            <span class="troop-name" @click="openTroopDetailsModal(spies)">{{ spies.name }}</span>
                                        </div>
                                        <div class="col">
                                            {{
                                                (
                                                    spies.numOfTroops +
                                                    spies.controlledReinforcements +
                                                    spies.uncontrolledReinforcements
                                                ).toLocaleString('ro')
                                            }}
                                        </div>
                                        <div class="col">
                                            {{ spies.numOfTroops.toLocaleString('ro') }}
                                        </div>
                                        <div class="col">
                                            {{ spies.controlledReinforcements.toLocaleString('ro') }}
                                        </div>
                                        <div class="col">
                                            {{ spies.uncontrolledReinforcements.toLocaleString('ro') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="send-command-wrapper">
                                <div class="coords-wrapper">
                                    <div class="row">
                                        <div class="col label">Target</div>
                                    </div>
                                    <div class="row">
                                        <div class="col justify-content-around">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault55"
                                                    id="coords"
                                                    checked
                                                />
                                                <label class="form-check-label" for="coords">Coordinates</label>
                                            </div>
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault55"
                                                    id="city-name"
                                                />
                                                <label class="form-check-label" for="city-name">City Name</label>
                                            </div>
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault55"
                                                    id="player-name"
                                                />
                                                <label class="form-check-label" for="player-name">Player Name</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <input class="form-control" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h4 class="table-header">Missions:</h4>
                            <div class="troops-table">
                                <div class="row">
                                    <div class="col label">Mission Name</div>
                                    <div class="col label">City</div>
                                    <div class="col label">Owned</div>
                                    <div class="col label">Controlled</div>
                                    <div class="col label button-column">Action</div>
                                </div>
                                <div class="row">
                                    <div class="col mission-name">Detect infiltrated spies</div>
                                    <div class="col">
                                        <div class="city-icon-wrapper">
                                            <!-- TO DO: add dynamic city image -->
                                            <img src="../assets/map_tiles/city_2_mobile.avif" class="city-icon" />
                                        </div>
                                        <span class="city-name-and-coords">
                                            {{ currentCityName }}
                                            <span class="city-coords">
                                                ( {{ currentCityCoords?.x }} | {{ currentCityCoords?.y }} )
                                            </span>
                                        </span>
                                    </div>
                                    <div class="col">
                                        <!-- TO DO: refactor v-model -->
                                        <input
                                            v-model="numberOfOwnedSpiesToSendDetecting[0].number"
                                            min="0"
                                            :max="spies.numOfTroops"
                                            class="form-control"
                                        />
                                        <span
                                            class="max-troops-to-send"
                                            @click="assignNumberOfOwnedSpiesToSend('detect', null, spies.numOfTroops)"
                                        >
                                            ({{ spies.numOfTroops.toLocaleString('ro') }})
                                        </span>
                                    </div>
                                    <div class="col controlled-reinforcements-select-column">
                                        <input
                                            v-if="numberOfControlledSpiesToSendDetecting?.length === 0"
                                            value="0"
                                            class="form-control"
                                            disabled
                                        />
                                        <input
                                            v-else-if="numberOfControlledSpiesToSendDetecting?.length === 1"
                                            v-model="numberOfControlledSpiesToSendDetecting[0].toSend"
                                            class="form-control"
                                        />
                                        <input
                                            v-else
                                            :value="calculateTroopsSelectedFromDropdown(numberOfControlledSpiesToSendDetecting)"
                                            class="form-control"
                                            disabled
                                        />
                                        <span
                                            class="max-troops-to-send"
                                            @click="assignAllControlledReinforcementsByTroop('detect_mission', null)"
                                            >({{ spies.controlledReinforcements.toLocaleString('ro') }})</span
                                        >
                                        <button
                                            v-if="getControlledReinforcementsPerTroop(spies.name).length > 1"
                                            class="btn neutral-button reinforcements-dropdown-button"
                                            @click="toggleExtraReinforcementOptionsVisibility('missions', 0)"
                                        >
                                            <span>&#10095;</span>
                                        </button>
                                        <Transition name="tooltip-transition">
                                            <div
                                                v-if="extraReinforcementOptionsVisible['missions'][0]"
                                                class="reinforcements-dropdown-wrapper"
                                            >
                                                <div class="row">
                                                    <div class="col label">From</div>
                                                    <div class="col label">City</div>
                                                    <div class="col label">Troops</div>
                                                </div>
                                                <div
                                                    v-for="(
                                                        reinforcement, reinforcement_index
                                                    ) in numberOfControlledSpiesToSendDetecting"
                                                    :key="reinforcement_index"
                                                    class="row"
                                                >
                                                    <div class="col">
														{{ reinforcement.fromUser?.username }}
                                                    </div>
                                                    <!-- TO DO: add city image before name -->
                                                    <div class="col">
                                                        {{ reinforcement.fromCity?.name }}
                                                    </div>
                                                    <div class="col">
                                                        <div class="troop-image-wrapper">
                                                            <img
                                                                src="../assets/icons/troops/spy-tall.avif"
                                                                alt=""
                                                                class="troop-image"
                                                                @click="
                                                                    openTroopDetailsModal(
                                                                        getTroopFromControlledReinforcements('Spy'),
                                                                    )
                                                                "
                                                            />
                                                        </div>
                                                        <input
                                                            v-model="reinforcement.toSend"
                                                            min="0"
                                                            :max="reinforcement.numOfTroops"
                                                            class="form-control"
                                                        />
                                                        <span
                                                            class="max-troops-to-send"
                                                            @click="
                                                                assignNumberOfControlledReinforcementsToSend(
                                                                    'detect_mission',
                                                                    reinforcement.index,
                                                                    reinforcement.numOfTroops,
                                                                )
                                                            "
                                                            >({{ reinforcement.numOfTroops.toLocaleString('ro') }})</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                    <div class="col button-column">
                                        <button
                                            class="btn blue-button"
                                            :disabled="awaitingResponse"
                                            @click="validateAndSendDetectSpiesMission"
                                        >
                                            Send
                                        </button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col mission-name">Espionage mission</div>
                                    <div class="col">
                                        <div v-if="targetCity?.name" class="city-icon-wrapper">
                                            <!-- TO DO: add dynamic city image -->
                                            <img src="../assets/map_tiles/city_2_mobile.avif" class="city-icon" />
                                        </div>
                                        <!-- TO DO: change to target city name and coordinates -->
                                        <span v-if="targetCity?.name" class="city-name-and-coords">
                                            {{ targetCity.name }}
                                            <span class="city-coords">
                                                ( {{ targetCity.coords.x }} | {{ targetCity.coords.y }} )
                                            </span>
                                        </span>
                                    </div>
                                    <div class="col">
                                        <!-- TO DO: refactor v-model -->
                                        <input
                                            v-model="numberOfOwnedSpiesToSendSpying[0].number"
                                            min="0"
                                            :max="spies.numOfTroops"
                                            class="form-control"
                                        />
                                        <span
                                            class="max-troops-to-send"
                                            @click="assignNumberOfOwnedSpiesToSend('spy', null, spies.numOfTroops)"
                                            >({{ spies.numOfTroops.toLocaleString('ro') }})</span
                                        >
                                    </div>
                                    <div class="col controlled-reinforcements-select-column">
                                        <input
                                            v-if="numberOfControlledSpiesToSendSpying?.length === 0"
                                            value="0"
                                            class="form-control"
                                            disabled
                                        />
                                        <input
                                            v-else-if="numberOfControlledSpiesToSendSpying?.length === 1"
                                            v-model="numberOfControlledSpiesToSendSpying[0].toSend"
                                            class="form-control"
                                        />
                                        <input
                                            v-else
                                            :value="calculateTroopsSelectedFromDropdown(numberOfControlledSpiesToSendSpying)"
                                            class="form-control"
                                            disabled
                                        />
                                        <span
                                            class="max-troops-to-send"
                                            @click="assignAllControlledReinforcementsByTroop('spy_mission', null)"
                                            >({{ spies.controlledReinforcements.toLocaleString('ro') }})</span
                                        >
                                        <button
                                            v-if="getControlledReinforcementsPerTroop(spies.name).length > 1"
                                            class="btn neutral-button reinforcements-dropdown-button"
                                            @click="toggleExtraReinforcementOptionsVisibility('missions', 1)"
                                        >
                                            <span>&#10095;</span>
                                        </button>
                                        <Transition name="tooltip-transition">
                                            <div
                                                v-if="extraReinforcementOptionsVisible['missions'][1]"
                                                class="reinforcements-dropdown-wrapper"
                                            >
                                                <div class="row">
                                                    <div class="col label">From</div>
                                                    <div class="col label">City</div>
                                                    <div class="col label">Troops</div>
                                                </div>
                                                <div
                                                    v-for="(
                                                        reinforcement, reinforcement_index
                                                    ) in numberOfControlledSpiesToSendSpying"
                                                    :key="reinforcement_index"
                                                    class="row"
                                                >
                                                    <div class="col">
														{{ reinforcement.fromUser?.username }}
                                                    </div>
                                                    <!-- TO DO: add city image before name -->
                                                    <div class="col">
                                                        {{ reinforcement.fromCity?.name }}
                                                    </div>
                                                    <div class="col">
                                                        <div class="troop-image-wrapper">
                                                            <img
                                                                src="../assets/icons/troops/spy-tall.avif"
                                                                alt=""
                                                                class="troop-image"
                                                                @click="
                                                                    openTroopDetailsModal(
                                                                        getTroopFromControlledReinforcements('Spy'),
                                                                    )
                                                                "
                                                            />
                                                        </div>
                                                        <input
                                                            v-model="reinforcement.toSend"
                                                            min="0"
                                                            :max="reinforcement.numOfTroops"
                                                            class="form-control"
                                                        />
                                                        <span
                                                            class="max-troops-to-send"
                                                            @click="
                                                                assignNumberOfControlledReinforcementsToSend(
                                                                    'spy_mission',
                                                                    reinforcement.index,
                                                                    reinforcement.numOfTroops,
                                                                )
                                                            "
                                                            >({{ reinforcement.numOfTroops.toLocaleString('ro') }})</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                    <div class="col button-column">
                                        <button
                                            class="btn blue-button"
                                            :disabled="awaitingResponse"
                                            @click="validateAndSendSpyMission"
                                        >
                                            Send
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <h4 class="table-header">Active Missions:</h4>
                            <div class="troops-table active-missions-table">
                                <div class="row">
                                    <div class="col label">Mission</div>
                                    <div class="col label">Spies</div>
                                    <div class="col label">Duration</div>
                                    <div class="col label button-column">Action</div>
                                </div>
                                <div v-for="spy_mission in spyMissions" :key="spy_mission.originalData._id" class="row">
                                    <div class="col">
                                        <div class="city-icon-wrapper">
                                            <!-- TO DO: add dynamic city image -->
                                            <img src="../assets/map_tiles/city_2_mobile.avif" class="city-icon" />
                                        </div>
                                        <span class="city-name-and-coords">
                                            {{ spy_mission.actionDescription }} {{ spy_mission.target.name }}
                                        </span>
                                    </div>
                                    <div class="col">
                                        {{ numberOfSpiesPerMission[spy_mission.originalData._id].toLocaleString('ro') }}
                                    </div>
                                    <div class="col">{{ spy_mission.progressTracking.dynamicDurationString }}</div>
                                    <div class="col button-column">
                                        <button class="btn red-button" :disabled="awaitingResponse">Cancel</button>
                                    </div>
                                </div>
                            </div>
                            <h4 class="table-header">Infiltrated Spies:</h4>
                            <div class="troops-table">
                                <div class="row">
                                    <div class="col label">Spies</div>
                                    <div class="col label">City</div>
                                    <div class="col label">Owned</div>
                                    <div class="col label">Controlled</div>
                                    <div class="col label button-column">Action</div>
                                </div>
                                <div
                                    v-for="(foreign_spies, foreign_spies_index) in foreignSpies"
                                    :key="foreign_spies_index"
                                    class="row"
                                >
                                    <div class="col">{{ numberOfSpiesPerMission[foreign_spies_index].toLocaleString('ro') }}</div>
                                    <div class="col">
                                        <div class="city-icon-wrapper">
                                            <!-- TO DO: add dynamic city image -->
                                            <img src="../assets/map_tiles/city_2_mobile.avif" class="city-icon" />
                                        </div>
                                        <span class="city-name-and-coords">
                                            {{ currentCityName }}
                                            <span class="city-coords">
                                                ( {{ currentCityCoords?.x }} | {{ currentCityCoords?.y }} )
                                            </span>
                                        </span>
                                    </div>
                                    <div class="col">
                                        <!-- TO DO: refactor v-model -->
                                        <input
                                            v-if="
                                                numberOfOwnedSpiesToCallBack.find(spies => spies.toCity?._id === foreign_spies.toCity?._id)
                                            "
                                            v-model="
                                                numberOfOwnedSpiesToCallBack[
                                                    numberOfOwnedSpiesToCallBack.findIndex(
                                                        spies => spies.toCity?._id === foreign_spies.toCity?._id,
                                                    )
                                                ].number
                                            "
                                            min="0"
                                            :max="
                                                numberOfOwnedSpiesToCallBack[
                                                    numberOfOwnedSpiesToCallBack.findIndex(
                                                        spies => spies.toCity?._id === foreign_spies.toCity?._id,
                                                    )
                                                ]?.numOfTroops
                                            "
                                            class="form-control"
                                        />
                                        <input v-else class="form-control" value="0" disabled />
                                        <span
                                            v-if="
                                                numberOfOwnedSpiesToCallBack.find(spies => spies.toCity?._id === foreign_spies.toCity?._id)
                                            "
                                            class="max-troops-to-send"
                                            @click="
                                                assignNumberOfOwnedSpiesToSend(
                                                    'call_back',
                                                    numberOfOwnedSpiesToCallBack.findIndex(
                                                        spies => spies.toCity?._id === foreign_spies.toCity?._id,
                                                    ),
                                                    numberOfOwnedSpiesToCallBack[
                                                        numberOfOwnedSpiesToCallBack.findIndex(
                                                            spies => spies.toCity?._id === foreign_spies.toCity?._id,
                                                        )
                                                    ]?.numOfTroops,
                                                )
                                            "
                                            >({{
                                                numberOfOwnedSpiesToCallBack[
                                                    numberOfOwnedSpiesToCallBack.findIndex(
                                                        spies => spies.toCity?._id === foreign_spies.toCity?._id,
                                                    )
                                                ]?.numOfTroops || 0
                                            }})</span
                                        >
                                        <span v-else class="max-troops-to-send"> (0) </span>
                                    </div>
                                    <div class="col controlled-reinforcements-select-column">
                                        <input
                                            v-if="getInfiltratedControlledSpiesByCity(foreign_spies.toCity?._id).length === 0"
                                            value="0"
                                            class="form-control"
                                            disabled
                                        />
                                        <input
                                            v-else-if="getInfiltratedControlledSpiesByCity(foreign_spies.toCity?._id).length === 1"
                                            v-model="getInfiltratedControlledSpiesByCity(foreign_spies.toCity?._id)[0].toSend"
                                            class="form-control"
                                        />
                                        <input
                                            v-else
                                            :value="
                                                calculateTroopsSelectedFromDropdown(
                                                    getInfiltratedControlledSpiesByCity(foreign_spies.toCity?._id),
                                                )
                                            "
                                            class="form-control"
                                            disabled
                                        />
                                        <span
                                            class="max-troops-to-send"
                                            @click="assignAllControlledReinforcementsByTroop('call_back', null)"
                                            >({{
                                                calculateControlledInfiltratedSpies(
                                                    getInfiltratedControlledSpiesByCity(foreign_spies.toCity?._id),
                                                )
                                            }})</span
                                        >
                                        <button
                                            v-if="getInfiltratedControlledSpiesByCity(foreign_spies.toCity?._id).length > 1"
                                            class="btn neutral-button reinforcements-dropdown-button"
                                            @click="
                                                toggleExtraReinforcementOptionsVisibility('callBackSpies', foreign_spies_index)
                                            "
                                        >
                                            <span>&#10095;</span>
                                        </button>
                                        <Transition name="tooltip-transition">
                                            <div
                                                v-if="extraReinforcementOptionsVisible['callBackSpies'][foreign_spies_index]"
                                                class="reinforcements-dropdown-wrapper"
                                            >
                                                <div class="row">
                                                    <div class="col label">From</div>
                                                    <div class="col label">City</div>
                                                    <div class="col label">Troops</div>
                                                </div>
                                                <div
                                                    v-for="(spy_group, spy_group_index) in getInfiltratedControlledSpiesByCity(
                                                        foreign_spies.toCity?._id,
                                                    )"
                                                    :key="spy_group_index"
                                                    class="row"
                                                >
                                                    <div class="col">
                                                        {{ spy_group.fromUser?.username }}
                                                    </div>
                                                    <!-- TO DO: add city image before name -->
                                                    <div class="col">
                                                        {{ spy_group.fromCity?.name }}
                                                    </div>
                                                    <div class="col">
                                                        <div class="troop-image-wrapper">
                                                            <img
                                                                src="../assets/icons/troops/spy-tall.avif"
                                                                alt=""
                                                                class="troop-image"
                                                                @click="
                                                                    openTroopDetailsModal(
                                                                        getTroopFromControlledReinforcements('Spy'),
                                                                    )
                                                                "
                                                            />
                                                        </div>
                                                        <input
                                                            v-model="spy_group.toSend"
                                                            min="0"
                                                            :max="spy_group.numOfTroops"
                                                            class="form-control"
                                                        />
                                                        <span
                                                            class="max-troops-to-send"
                                                            @click="
                                                                assignNumberOfControlledReinforcementsToSend(
                                                                    'call_back',
                                                                    spy_group.index,
                                                                    spy_group.numOfTroops,
                                                                )
                                                            "
                                                            >({{ spy_group.numOfTroops.toLocaleString('ro') }})</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                    <div class="col button-column">
                                        <button
                                            class="btn yellow-button"
                                            :disabled="awaitingResponse"
                                            @click="validateAndCallBackSpies(foreign_spies.toCity?._id)"
                                        >
                                            Call Back
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="reinforcements-page" role="tabpanel" aria-labelledby="reinforcements-tab">
                            <h4 class="table-header">Reinforcements Sent:</h4>
                            <div class="troops-table">
                                <div class="row">
                                    <div class="col label">Type</div>
                                    <div class="col label">City</div>
                                    <div class="col label">Owned</div>
                                    <div class="col label">Controlled</div>
                                    <div class="col label">Troops</div>
                                    <div class="col label button-column">Action</div>
                                </div>
                                <div
                                    v-for="(city, city_index) in Object.keys(reinforcementsToCallBack['controlled'])"
                                    :key="city_index"
                                    class="table-section"
                                >
                                    <div
                                        v-for="(reinforcement, reinforcement_index) in Object.keys(
                                            reinforcementsToCallBack['controlled'][city],
                                        )"
                                        :key="reinforcement_index"
                                        class="row"
                                    >
                                        <div class="col mission-name">Can be controlled</div>
                                        <div class="col">
                                            {{ city.substring(city.length - 5) }}
                                            <!--										<div class="city-icon-wrapper">-->
                                            <!-- 											TO DO: add dynamic city image -->
                                            <!--											<img src="../assets/map_tiles/city_2.png" class="city-icon" />-->
                                            <!--										</div>-->
                                            <!--										<span class="city-name-and-coords">-->
                                            <!--                                            {{ currentCityName }}-->
                                            <!--                                            <span class="city-coords">-->
                                            <!--                                                ( {{ currentCityCoords?.x }} |-->
                                            <!--                                                {{ currentCityCoords?.y }} )-->
                                            <!--                                            </span>-->
                                            <!--                                        </span>-->
                                        </div>
                                        <div class="col">
                                            <span
                                                >{{
                                                    calculateNumberOfReinforcements(
                                                        'sent',
                                                        'controlled',
                                                        city,
                                                        reinforcement,
                                                        true,
                                                        true,
                                                    )
                                                }}
                                                ({{
                                                    calculateNumberOfReinforcements(
                                                        'sent',
                                                        'controlled',
                                                        city,
                                                        reinforcement,
                                                        true,
                                                        false,
                                                    )
                                                }})</span
                                            >
                                        </div>
                                        <div class="col">
                                            <span
                                                >{{
                                                    calculateNumberOfReinforcements(
                                                        'sent',
                                                        'controlled',
                                                        city,
                                                        reinforcement,
                                                        false,
                                                        true,
                                                    )
                                                }}
                                                ({{
                                                    calculateNumberOfReinforcements(
                                                        'sent',
                                                        'controlled',
                                                        city,
                                                        reinforcement,
                                                        false,
                                                        false,
                                                    )
                                                }})</span
                                            >
                                        </div>
                                        <div class="col">
                                            <button
                                                class="btn neutral-button reinforcements-dropdown-button"
                                                @click="toggleReinforcementsDropdownVisibility('controlledSent', reinforcement)"
                                            >
                                                <span>&#10095;</span>
                                            </button>
                                            <Transition name="tooltip-transition">
                                                <div
                                                    v-if="extraReinforcementOptionsVisible['controlledSent'] === reinforcement"
                                                    class="reinforcements-dropdown-wrapper"
                                                >
                                                    <div class="row">
                                                        <div class="col label">Unit</div>
                                                        <div class="col label">Number</div>
                                                        <div class="col label">Destination</div>
                                                        <div class="col label">Available</div>
                                                        <div class="col label">Mission</div>
                                                    </div>
                                                    <div
                                                        v-for="(troop, troop_index) in reinforcementsToCallBack['controlled'][
                                                            city
                                                        ][reinforcement]"
                                                        :key="troop_index"
                                                        class="row"
                                                    >
                                                        <div class="col">
                                                            <div class="troop-image-wrapper">
                                                                <img
                                                                    :src="
                                                                        require(`../assets/icons/troops/${toKebabCase(
                                                                            troop.troopName,
                                                                        )}-tall.avif`)
                                                                    "
                                                                    alt=""
                                                                    class="troop-image"
                                                                    @click="
                                                                        openTroopDetailsModal(
                                                                            getTroopFromControlledReinforcements(troop.troopName),
                                                                        )
                                                                    "
                                                                />
                                                            </div>
                                                            <span
                                                                class="troop-name"
                                                                @click="
                                                                    openTroopDetailsModal(
                                                                        getTroopFromControlledReinforcements(troop.troopName),
                                                                    )
                                                                "
                                                                >{{ troop.troopName }}</span
                                                            >
                                                        </div>
                                                        <div class="col">{{ troop.numOfTroops }}</div>
                                                        <div class="col">{{ troop.toCity?.name }}</div>
                                                        <div class="col">{{ troop.arrived ? '&#10003;' : '&#10006;' }}</div>
                                                        <div class="col">{{ troop.mission }}</div>
                                                    </div>
                                                </div>
                                            </Transition>
                                        </div>
                                        <div class="col button-column">
                                            <button
                                                class="btn blue-button"
                                                :disabled="awaitingResponse"
                                                @click="validateAndCallBackReinforcements(reinforcement)"
                                            >
                                                Call Back
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-for="(city, city_index) in Object.keys(reinforcementsToCallBack['uncontrolled'])"
                                    :key="city_index"
                                    class="table-section"
                                >
                                    <div
                                        v-for="(reinforcement, reinforcement_index) in Object.keys(
                                            reinforcementsToCallBack['uncontrolled'][city],
                                        )"
                                        :key="reinforcement_index"
                                        class="row"
                                    >
                                        <div class="col mission-name">Cannot be controlled</div>
                                        <div class="col">
                                            {{ city.substring(city.length - 5) }}
                                            <!--										<div class="city-icon-wrapper">-->
                                            <!-- 											TO DO: add dynamic city image -->
                                            <!--											<img src="../assets/map_tiles/city_2.png" class="city-icon" />-->
                                            <!--										</div>-->
                                            <!--										<span class="city-name-and-coords">-->
                                            <!--                                            {{ currentCityName }}-->
                                            <!--                                            <span class="city-coords">-->
                                            <!--                                                ( {{ currentCityCoords?.x }} |-->
                                            <!--                                                {{ currentCityCoords?.y }} )-->
                                            <!--                                            </span>-->
                                            <!--                                        </span>-->
                                        </div>
                                        <div class="col">
                                            <span
                                                >{{
                                                    calculateNumberOfReinforcements(
                                                        'sent',
                                                        'uncontrolled',
                                                        city,
                                                        reinforcement,
                                                        true,
                                                        true,
                                                    )
                                                }}
                                                ({{
                                                    calculateNumberOfReinforcements(
                                                        'sent',
                                                        'uncontrolled',
                                                        city,
                                                        reinforcement,
                                                        true,
                                                        false,
                                                    )
                                                }})</span
                                            >
                                        </div>
                                        <div class="col">
                                            <span
                                                >{{
                                                    calculateNumberOfReinforcements(
                                                        'sent',
                                                        'uncontrolled',
                                                        city,
                                                        reinforcement,
                                                        false,
                                                        true,
                                                    )
                                                }}
                                                ({{
                                                    calculateNumberOfReinforcements(
                                                        'sent',
                                                        'uncontrolled',
                                                        city,
                                                        reinforcement,
                                                        false,
                                                        false,
                                                    )
                                                }})</span
                                            >
                                        </div>
                                        <div class="col">
                                            <button
                                                class="btn neutral-button reinforcements-dropdown-button"
                                                @click="toggleReinforcementsDropdownVisibility('uncontrolledSent', reinforcement)"
                                            >
                                                <span>&#10095;</span>
                                            </button>
                                            <Transition name="tooltip-transition">
                                                <div
                                                    v-if="extraReinforcementOptionsVisible['uncontrolledSent'] === reinforcement"
                                                    class="reinforcements-dropdown-wrapper"
                                                >
                                                    <div class="row">
                                                        <div class="col label">Unit</div>
                                                        <div class="col label">Number</div>
                                                        <div class="col label">Destination</div>
                                                        <div class="col label">Available</div>
                                                        <div class="col label">Mission</div>
                                                    </div>
                                                    <div
                                                        v-for="(troop, troop_index) in reinforcementsToCallBack['uncontrolled'][
                                                            city
                                                        ][reinforcement]"
                                                        :key="troop_index"
                                                        class="row"
                                                    >
                                                        <div class="col">
                                                            <div class="troop-image-wrapper">
                                                                <img
                                                                    :src="
                                                                        require(`../assets/icons/troops/${toKebabCase(
                                                                            troop.troopName,
                                                                        )}-tall.avif`)
                                                                    "
                                                                    alt=""
                                                                    class="troop-image"
                                                                    @click="
                                                                        openTroopDetailsModal(
                                                                            getTroopFromControlledReinforcements(troop.troopName),
                                                                        )
                                                                    "
                                                                />
                                                            </div>
                                                            <span
                                                                class="troop-name"
                                                                @click="
                                                                    openTroopDetailsModal(
                                                                        getTroopFromControlledReinforcements(troop.troopName),
                                                                    )
                                                                "
                                                                >{{ troop.troopName }}</span
                                                            >
                                                        </div>
                                                        <div class="col">{{ troop.numOfTroops }}</div>
                                                        <div class="col">{{ troop.toCity?.name }}</div>
                                                        <div class="col">{{ troop.arrived ? '&#10003;' : '&#10006;' }}</div>
                                                        <div class="col">{{ troop.mission }}</div>
                                                    </div>
                                                </div>
                                            </Transition>
                                        </div>
                                        <div class="col button-column">
                                            <button
                                                class="btn blue-button"
                                                :disabled="awaitingResponse"
                                                @click="validateAndCallBackReinforcements(reinforcement)"
                                            >
                                                Call Back
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h4 class="table-header">Reinforcements Received:</h4>
                            <div class="troops-table">
                                <div class="row">
                                    <div class="col label">Type</div>
                                    <div class="col label">City</div>
                                    <div class="col label">Owned</div>
                                    <div class="col label">Controlled</div>
                                    <div class="col label">Troops</div>
                                    <div class="col label button-column">Action</div>
                                </div>
                                <div
                                    v-for="(city, city_index) in Object.keys(reinforcementsToSendBack['controlled'])"
                                    :key="city_index"
                                    class="table-section"
                                >
                                    <div
                                        v-for="(reinforcement, reinforcement_index) in Object.keys(
                                            reinforcementsToSendBack['controlled'][city],
                                        )"
                                        :key="reinforcement_index"
                                        class="row"
                                    >
                                        <div class="col mission-name">Can be controlled</div>
                                        <div class="col">
                                            {{ city.substring(city.length - 5) }}
                                            <!--										<div class="city-icon-wrapper">-->
                                            <!-- 											TO DO: add dynamic city image -->
                                            <!--											<img src="../assets/map_tiles/city_2.png" class="city-icon" />-->
                                            <!--										</div>-->
                                            <!--										<span class="city-name-and-coords">-->
                                            <!--                                            {{ currentCityName }}-->
                                            <!--                                            <span class="city-coords">-->
                                            <!--                                                ( {{ currentCityCoords?.x }} |-->
                                            <!--                                                {{ currentCityCoords?.y }} )-->
                                            <!--                                            </span>-->
                                            <!--                                        </span>-->
                                        </div>
                                        <div class="col">
                                            <span
                                                >{{
                                                    calculateNumberOfReinforcements(
                                                        'received',
                                                        'controlled',
                                                        city,
                                                        reinforcement,
                                                        true,
                                                        true,
                                                    )
                                                }}
                                                ({{
                                                    calculateNumberOfReinforcements(
                                                        'received',
                                                        'controlled',
                                                        city,
                                                        reinforcement,
                                                        true,
                                                        false,
                                                    )
                                                }})</span
                                            >
                                        </div>
                                        <div class="col">
                                            <span
                                                >{{
                                                    calculateNumberOfReinforcements(
                                                        'received',
                                                        'controlled',
                                                        city,
                                                        reinforcement,
                                                        false,
                                                        true,
                                                    )
                                                }}
                                                ({{
                                                    calculateNumberOfReinforcements(
                                                        'received',
                                                        'controlled',
                                                        city,
                                                        reinforcement,
                                                        false,
                                                        false,
                                                    )
                                                }})</span
                                            >
                                        </div>
                                        <div class="col">
                                            <button
                                                class="btn neutral-button reinforcements-dropdown-button"
                                                @click="
                                                    toggleReinforcementsDropdownVisibility('controlledReceived', reinforcement)
                                                "
                                            >
                                                <span>&#10095;</span>
                                            </button>
                                            <Transition name="tooltip-transition">
                                                <div
                                                    v-if="
                                                        extraReinforcementOptionsVisible['controlledReceived'] === reinforcement
                                                    "
                                                    class="reinforcements-dropdown-wrapper"
                                                >
                                                    <div class="row">
                                                        <div class="col label">Unit</div>
                                                        <div class="col label">Number</div>
                                                        <div class="col label">Destination</div>
                                                        <div class="col label">Available</div>
                                                        <div class="col label">Mission</div>
                                                    </div>
                                                    <div
                                                        v-for="(troop, troop_index) in reinforcementsToSendBack['controlled'][
                                                            city
                                                        ][reinforcement]"
                                                        :key="troop_index"
                                                        class="row"
                                                    >
                                                        <div class="col">
                                                            <div class="troop-image-wrapper">
                                                                <img
                                                                    :src="
                                                                        require(`../assets/icons/troops/${toKebabCase(
                                                                            troop.troopName,
                                                                        )}-tall.avif`)
                                                                    "
                                                                    alt=""
                                                                    class="troop-image"
                                                                    @click="
                                                                        openTroopDetailsModal(
                                                                            getTroopFromControlledReinforcements(troop.troopName),
                                                                        )
                                                                    "
                                                                />
                                                            </div>
                                                            <span
                                                                class="troop-name"
                                                                @click="
                                                                    openTroopDetailsModal(
                                                                        getTroopFromControlledReinforcements(troop.troopName),
                                                                    )
                                                                "
                                                                >{{ troop.troopName }}</span
                                                            >
                                                        </div>
                                                        <div class="col">{{ troop.numOfTroops }}</div>
                                                        <div class="col">{{ troop.toCity?.name }}</div>
                                                        <div class="col">{{ troop.arrived ? '&#10003;' : '&#10006;' }}</div>
                                                        <div class="col">{{ troop.mission }}</div>
                                                    </div>
                                                </div>
                                            </Transition>
                                        </div>
                                        <div class="col button-column">
                                            <button
                                                class="btn blue-button"
                                                :disabled="awaitingResponse"
                                                @click="validateAndSendBackReinforcements(reinforcement)"
                                            >
                                                Send Back
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-for="(city, city_index) in Object.keys(reinforcementsToSendBack['uncontrolled'])"
                                    :key="city_index"
                                    class="table-section"
                                >
                                    <div
                                        v-for="(reinforcement, reinforcement_index) in Object.keys(
                                            reinforcementsToSendBack['uncontrolled'][city],
                                        )"
                                        :key="reinforcement_index"
                                        class="row"
                                    >
                                        <div class="col mission-name">Cannot be controlled</div>
                                        <div class="col">
                                            {{ city.substring(city.length - 5) }}
                                            <!--										<div class="city-icon-wrapper">-->
                                            <!-- 											TO DO: add dynamic city image -->
                                            <!--											<img src="../assets/map_tiles/city_2.png" class="city-icon" />-->
                                            <!--										</div>-->
                                            <!--										<span class="city-name-and-coords">-->
                                            <!--                                            {{ currentCityName }}-->
                                            <!--                                            <span class="city-coords">-->
                                            <!--                                                ( {{ currentCityCoords?.x }} |-->
                                            <!--                                                {{ currentCityCoords?.y }} )-->
                                            <!--                                            </span>-->
                                            <!--                                        </span>-->
                                        </div>
                                        <div class="col">
                                            <span
                                                >{{
                                                    calculateNumberOfReinforcements(
                                                        'received',
                                                        'uncontrolled',
                                                        city,
                                                        reinforcement,
                                                        true,
                                                        true,
                                                    )
                                                }}
                                                ({{
                                                    calculateNumberOfReinforcements(
                                                        'received',
                                                        'uncontrolled',
                                                        city,
                                                        reinforcement,
                                                        true,
                                                        false,
                                                    )
                                                }})</span
                                            >
                                        </div>
                                        <div class="col">
                                            <span
                                                >{{
                                                    calculateNumberOfReinforcements(
                                                        'received',
                                                        'uncontrolled',
                                                        city,
                                                        reinforcement,
                                                        false,
                                                        true,
                                                    )
                                                }}
                                                ({{
                                                    calculateNumberOfReinforcements(
                                                        'received',
                                                        'uncontrolled',
                                                        city,
                                                        reinforcement,
                                                        false,
                                                        false,
                                                    )
                                                }})</span
                                            >
                                        </div>
                                        <div class="col">
                                            <button
                                                class="btn neutral-button reinforcements-dropdown-button"
                                                @click="
                                                    toggleReinforcementsDropdownVisibility('uncontrolledReceived', reinforcement)
                                                "
                                            >
                                                <span>&#10095;</span>
                                            </button>
                                            <Transition name="tooltip-transition">
                                                <div
                                                    v-if="
                                                        extraReinforcementOptionsVisible['uncontrolledReceived'] === reinforcement
                                                    "
                                                    class="reinforcements-dropdown-wrapper"
                                                >
                                                    <div class="row">
                                                        <div class="col label">Unit</div>
                                                        <div class="col label">Number</div>
                                                        <div class="col label">Destination</div>
                                                        <div class="col label">Available</div>
                                                        <div class="col label">Mission</div>
                                                    </div>
                                                    <div
                                                        v-for="(troop, troop_index) in reinforcementsToSendBack['uncontrolled'][
                                                            city
                                                        ][reinforcement]"
                                                        :key="troop_index"
                                                        class="row"
                                                    >
                                                        <div class="col">
                                                            <div class="troop-image-wrapper">
                                                                <img
                                                                    :src="
                                                                        require(`../assets/icons/troops/${toKebabCase(
                                                                            troop.troopName,
                                                                        )}-tall.avif`)
                                                                    "
                                                                    alt=""
                                                                    class="troop-image"
                                                                    @click="
                                                                        openTroopDetailsModal(
                                                                            getTroopFromControlledReinforcements(troop.troopName),
                                                                        )
                                                                    "
                                                                />
                                                            </div>
                                                            <span
                                                                class="troop-name"
                                                                @click="
                                                                    openTroopDetailsModal(
                                                                        getTroopFromControlledReinforcements(troop.troopName),
                                                                    )
                                                                "
                                                                >{{ troop.troopName }}</span
                                                            >
                                                        </div>
                                                        <div class="col">{{ troop.numOfTroops }}</div>
                                                        <div class="col">{{ troop.toCity?.name }}</div>
                                                        <div class="col">{{ troop.arrived ? '&#10003;' : '&#10006;' }}</div>
                                                        <div class="col">{{ troop.mission }}</div>
                                                    </div>
                                                </div>
                                            </Transition>
                                        </div>
                                        <div class="col button-column">
                                            <button
                                                class="btn blue-button"
                                                :disabled="awaitingResponse"
                                                @click="validateAndSendBackReinforcements(reinforcement)"
                                            >
                                                Send Back
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="troops-page" role="tabpanel" aria-labelledby="troops-tab">
                            <div class="table-wrapper">
                                <div class="troops-table">
                                    <div class="row label">
                                        <div class="col">Unit</div>
                                        <div class="col">Total</div>
                                        <div class="col">Owned</div>
                                        <div class="col">Controlled</div>
                                        <div class="col">Stationed</div>
                                    </div>
                                    <div
                                        v-for="(troop, index) in [
                                            ...troops.soldiers,
                                            ...troops.other,
                                            ...troops.tanks,
                                            ...troops.planes,
                                        ].filter(
                                            troop =>
                                                troop.numOfTroops > 0 ||
                                                troop.controlledReinforcements > 0 ||
                                                troop.uncontrolledReinforcements > 0,
                                        )"
                                        :key="index"
                                        class="row"
                                    >
                                        <div class="col">
                                            <div class="troop-image-wrapper">
                                                <img
                                                    :src="require(`../assets/icons/troops/${toKebabCase(troop.name)}-tall.avif`)"
                                                    alt=""
                                                    class="troop-image"
                                                    @click="openTroopDetailsModal(troop)"
                                                />
                                            </div>
                                            <span class="troop-name" @click="openTroopDetailsModal(troop)">{{ troop.name }}</span>
                                        </div>
                                        <div class="col">
                                            {{
                                                (
                                                    troop.numOfTroops +
                                                    troop.controlledReinforcements +
                                                    troop.uncontrolledReinforcements
                                                ).toLocaleString('ro')
                                            }}
                                        </div>
                                        <div class="col">
                                            {{ troop.numOfTroops.toLocaleString('ro') }}
                                        </div>
                                        <div class="col">
                                            {{ troop.controlledReinforcements.toLocaleString('ro') }}
                                        </div>
                                        <div class="col">
                                            {{ troop.uncontrolledReinforcements.toLocaleString('ro') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="recruitment-page" role="tabpanel" aria-labelledby="recruitment-tab">
                            <ul class="nav nav-tabs" id="office-recruitment-tabs" role="tablist">
                                <li
                                    :class="troop.name"
                                    v-for="(troop, troop_index) in troops.other"
                                    :key="troop_index"
                                    class="nav-item"
                                    role="presentation"
                                    @mouseover="toggleTroopNameVisibility(true, troop_index)"
                                    @mouseleave="toggleTroopNameVisibility(false, troop_index)"
                                >
                                    <button
                                        :class="{ active: troop_index === 0 }"
                                        class="nav-link"
                                        :id="`office_tab_id_${troop_index}`"
                                        data-bs-toggle="tab"
                                        :data-bs-target="`#office_content_id_${troops.other.indexOf(troop)}`"
                                        type="button"
                                        role="tab"
                                        :aria-controls="`office_aria_id_${troops.other.indexOf(troop)}`"
                                        aria-selected="true"
                                        @click="setCurrentTroop(troop)"
                                    >
                                        <Transition name="tooltip-transition">
                                            <div v-if="troopNameTooltipVisible[troop_index]" class="troop-name-tooltip">
                                                {{ troop.name }}
                                            </div>
                                        </Transition>
                                        <div class="tab-wrapper">
                                            <div class="current-troops">
                                                {{ troop.numOfTroops.toLocaleString('ro') }}
                                            </div>
                                            <div class="tab-img-wrapper">
                                                <img
                                                    :src="require(`../assets/icons/troops/${toKebabCase(troop.name)}-tall.avif`)"
                                                    alt=""
                                                    :class="{
                                                        unavailable: troopsWithRequirementsNotMet.includes(troop.name),
                                                    }"
                                                />
                                            </div>
                                            <div class="max-troops">
                                                +
                                                {{
                                                    troopsWithRequirementsNotMet.includes(troop.name)
                                                        ? 0
                                                        : calculateMaxTroops(troop)
                                                }}
                                            </div>
                                        </div>
                                    </button>
                                </li>
                                <li class="nav-filler" />
                            </ul>
                            <div class="tab-content" id="office-recruitment-content">
                                <div
                                    v-for="(troop, troop_index) in troops.other"
                                    :key="troop_index"
                                    :class="{
                                        show: troop_index === 0,
                                        active: troop_index === 0,
                                    }"
                                    class="tab-pane fade"
                                    :id="`office_content_id_${troop_index}`"
                                    role="tabpanel"
                                    :aria-labelledby="`office_aria_id_${troops.other.indexOf(troop)}`"
                                >
                                    <div
                                        v-if="troopsWithRequirementsNotMet.includes(troop.name)"
                                        class="building-requirements-section"
                                    >
                                        <span class="requirements-title">Needed:</span>
                                        <div
                                            v-for="(req, req_index) in troop.requirements"
                                            :key="req_index"
                                            class="requirements-group"
                                            :class="{
                                                'd-none': requirementsMetPerTroop[troop_index].requirementsMet?.includes(
                                                    req.name,
                                                ),
                                            }"
                                        >
                                            <img
                                                :src="
                                                    require(`../assets/icons/buildings/${toSnakeCase(
                                                        req.name,
                                                    )}_mini_${getBuildingAppearance(req)}.avif`)
                                                "
                                                alt=""
                                                class="requirement-icon"
                                                @click="openBuildingDetailsModal(getBuildingFromRequirementName(req.name))"
                                            />
                                            <div class="requirement-name-wrapper">
                                                <span
                                                    class="requirement-name"
                                                    @click="openBuildingDetailsModal(getBuildingFromRequirementName(req.name))"
                                                    >{{ req.name }}</span
                                                >
                                                <span class="requirement-level">Level {{ req.level }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="d-flex flex-row justify-content-between flex-wrap"
                                        :class="{
                                            'opacity-50': troopsWithRequirementsNotMet.includes(troop.name),
                                        }"
                                    >
                                        <div class="recruitment-window">
                                            <span class="section-title clickable" @click="openTroopDetailsModal()">{{
                                                troop.name
                                            }}</span>
                                            <div class="recruitment-image-wrapper">
                                                <img
                                                    :src="require(`../assets/icons/troops/${toKebabCase(troop.name)}-tall.avif`)"
                                                    alt=""
                                                />
                                            </div>
                                            <div
                                                class="recruitment-command-wrapper"
                                                :class="{
                                                    'pointer-events-none': troopsWithRequirementsNotMet.includes(troop.name),
                                                }"
                                            >
                                                <div class="recruitment-slider d-flex gap-3 align-items-center">
                                                    <span class="min-recruit" @click="recruitMin(troop_index)">{{
                                                        calculateMaxTroops(troop) ? 1 : 0
                                                    }}</span>
                                                    <vue-slider
                                                        v-model="numberOfTroopsToRecruit[troop_index]"
                                                        v-bind="{
                                                            ...options,
                                                            min: calculateMaxTroops(troop) ? 1 : 0,
                                                        }"
                                                        :max="calculateMaxTroops(troop)"
                                                    />
                                                    <span class="max-recruit" @click="recruitMax(troop_index)">{{
                                                        calculateMaxTroops(troop)
                                                    }}</span>
                                                </div>
                                                <input
                                                    class="form-control"
                                                    v-model="numberOfTroopsToRecruit[troop_index]"
                                                    type="number"
                                                    :min="calculateMaxTroops(troop) ? 1 : 0"
                                                    :max="calculateMaxTroops(troop)"
                                                />
                                                <button
                                                    class="btn red-button"
                                                    @click="sendCommand(troop)"
                                                    :disabled="
                                                        troopsWithRequirementsNotMet.includes(troop.name) ||
                                                        !calculateMaxTroops(troop) ||
                                                        numberOfTroopsToRecruit[troop_index] > calculateMaxTroops(troop) ||
                                                        numberOfTroopsToRecruit[troop_index] <= 0 ||
                                                        awaitingResponse
                                                    "
                                                >
                                                    Recruit
                                                </button>
                                            </div>
                                        </div>
                                        <div class="cost-window">
                                            <span class="section-title">Cost</span>
                                            <div class="resource-wrapper">
                                                <div class="icon-tooltip">Aluminium</div>
                                                <img src="../assets/icons/aluminum_circle_icon.svg" class="resource-icon" />
                                                <span
                                                    >{{ troop.aluminum * numberOfTroopsToRecruit[troop_index] }} (
                                                    {{ troop.aluminum }} )</span
                                                >
                                            </div>
                                            <div class="resource-wrapper">
                                                <div class="icon-tooltip">Oil</div>
                                                <img src="../assets/icons/oil_circle_icon.svg" class="resource-icon" />
                                                <span
                                                    >{{ troop.oil * numberOfTroopsToRecruit[troop_index] }} (
                                                    {{ troop.oil }} )</span
                                                >
                                            </div>
                                            <div class="resource-wrapper">
                                                <div class="icon-tooltip">Metal</div>
                                                <img src="../assets/icons/metal_circle_icon.svg" class="resource-icon" />
                                                <span
                                                    >{{ troop.metal * numberOfTroopsToRecruit[troop_index] }} (
                                                    {{ troop.metal }} )</span
                                                >
                                            </div>
                                            <div class="resource-wrapper">
                                                <div class="icon-tooltip">Population</div>
                                                <img src="../assets/icons/population_circle_icon.svg" class="resource-icon" />
                                                <span
                                                    >{{ troop.population * numberOfTroopsToRecruit[troop_index] }} (
                                                    {{ troop.population }} )</span
                                                >
                                            </div>
                                            <div class="resource-wrapper">
                                                <div class="icon-tooltip">Recruitment Time</div>
                                                <img src="../assets/icons/training_time_icon.svg" class="resource-icon" />
                                                <span
                                                    >{{ calculateTotalTime(numberOfTroopsToRecruit[troop_index], troop.time) }}
                                                    <span class="d-inline-block">( {{ troop.time }} )</span></span
                                                >
                                            </div>
                                        </div>
                                        <div class="troop-info-window">
                                            <span class="section-title">Statistics</span>
                                            <div class="troop-info-wrapper">
                                                <div class="icon-tooltip">Anti-air Defense</div>
                                                <img src="../assets/icons/anti_air_defense_icon.svg" class="troop-info-icon" />
                                                <span>{{ troop.antiAirDefense }}</span>
                                            </div>
                                            <div class="troop-info-wrapper">
                                                <div class="icon-tooltip">Anti-artillery Defense</div>
                                                <img
                                                    src="../assets/icons/anti_artillery_tank_defense_icon.svg"
                                                    class="troop-info-icon"
                                                />
                                                <span>{{ troop.antiArtilleryAndTankDefense }}</span>
                                            </div>
                                            <div class="troop-info-wrapper">
                                                <div class="icon-tooltip">Anti-infantry Defense</div>
                                                <img
                                                    src="../assets/icons/anti_infantry_defense_icon.svg"
                                                    class="troop-info-icon"
                                                />
                                                <span>{{ troop.antiInfantryDefense }}</span>
                                            </div>
                                            <div class="troop-info-wrapper">
                                                <div class="icon-tooltip">Attack</div>
                                                <img src="../assets/icons/attack_icon.svg" class="troop-info-icon" />
                                                <span>{{ troop.attack }}</span>
                                            </div>
                                            <div class="troop-info-wrapper">
                                                <div class="icon-tooltip">Carry Capacity</div>
                                                <img src="../assets/icons/carry_capacity_icon.svg" class="troop-info-icon" />
                                                <span>{{ troop.carryCapacity }}</span>
                                            </div>
                                            <div class="troop-info-wrapper">
                                                <div class="icon-tooltip">Movement Speed</div>
                                                <img src="../assets/icons/movement_speed_icon.svg" class="troop-info-icon" />
                                                <span>{{ troop.movementSpeed }}</span>
                                            </div>
                                            <div class="troop-info-wrapper">
                                                <div class="icon-tooltip">Oil Consumption</div>
                                                <img src="../assets/icons/oil_circle_icon.svg" class="troop-info-icon" />
                                                <span>{{ troop.oilConsumption }}</span>
                                            </div>
                                            <div class="troop-info-wrapper">
                                                <div class="icon-tooltip">Rations Consumption</div>
                                                <img src="../assets/icons/food_consumption_icon.svg" class="troop-info-icon" />
                                                <span>{{ troop.rationsConsumption }}</span>
                                            </div>
                                            <div class="troop-info-wrapper">
                                                <div class="icon-tooltip">Unit Type</div>
                                                <img src="../assets/icons/food_consumption_icon.svg" class="troop-info-icon" />
                                                <span>Infantry</span>
                                            </div>
                                            <div class="troop-info-wrapper">
                                                <div class="icon-tooltip">Type of Attack</div>
                                                <img src="../assets/icons/food_consumption_icon.svg" class="troop-info-icon" />
                                                <span>Light Attack</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="troop-commands-wrapper d-flex w-100 gap-1">
                                <span class="section-title">Recruiting</span>
                                <div v-for="command in troopsCommandsToShow" :key="command" class="troop-command">
                                    <div v-if="command" class="placed-command">
                                        <Transition name="tooltip-transition">
                                            <div
                                                v-if="commandFinishTooltipVisible[troopsCommandsToShow.indexOf(command)]"
                                                class="recruitment-command-tooltip"
                                                @mouseover="
                                                    toggleCommandTooltipVisibility(true, troopsCommandsToShow.indexOf(command))
                                                "
                                                @mouseleave="
                                                    toggleCommandTooltipVisibility(false, troopsCommandsToShow.indexOf(command))
                                                "
                                            >
                                                <span class="tooltip-troop-name">{{ command.name }}</span>
                                                <span>Finish Date: {{ command.finishDateToShow }}</span>
                                            </div>
                                        </Transition>
                                        <div
                                            class="recruitment-image-wrapper"
                                            @mouseover="
                                                toggleCommandTooltipVisibility(true, troopsCommandsToShow.indexOf(command))
                                            "
                                            @mouseleave="
                                                toggleCommandTooltipVisibility(false, troopsCommandsToShow.indexOf(command))
                                            "
                                        >
                                            <img
                                                :src="require(`../assets/icons/troops/${toKebabCase(command.name)}-tall.avif`)"
                                            />
                                            <div class="recruitment-number">
                                                {{ command.numOfTroops }}
                                            </div>
                                        </div>
                                        <div class="placed-command-info-wrapper">
                                            <span class="placed-command-total-time">{{ command.time }}</span>
                                            <div class="d-flex flex-row gap-1">
                                                <button class="btn recruit-button" :disabled="awaitingResponse">Cancel</button>
                                                <button class="btn premium-recruit-button" :disabled="awaitingResponse">
                                                    <img src="../assets/icons/aluminum_icon.svg" class="premium-icon" />
                                                    50
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="command-placeholder">
                                        <img src="../assets/icons/recruitment_icon.svg" class="placeholder-icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="simulator-page" role="tabpanel" aria-labelledby="simulator-tab">
                            <div class="troops-table">
                                <div class="row label">
                                    <div class="col">Unit</div>
                                    <div class="col">Attacker</div>
                                    <div class="col">Defender</div>
                                    <div class="col no-background"></div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="form-select" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../assets/icons/buildings/fortifications_mini_1.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Wall</span>
                                    </div>
                                    <div class="col"></div>
                                    <div class="col">Level: <input class="form-control" /></div>
                                    <div class="col no-background"></div>
                                </div>
                                <div class="row">
                                    <div class="col">Night Bonus</div>
                                    <div class="col"></div>
                                    <div class="col">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="night-bonus-check" />
                                            <label class="form-check-label" for="night-bonus-check"> +100% to Defense </label>
                                        </div>
                                    </div>
                                    <div class="col no-background"></div>
                                </div>
                                <div class="row">
                                    <div class="col">Luck (-25% to 25%)</div>
                                    <div class="col">
                                        <input class="form-control" />
                                    </div>
                                    <div class="col"></div>
                                    <div class="col no-background"></div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <button class="btn yellow-button" :disabled="awaitingResponse">Simulate</button>
                                    </div>
                                    <div class="col">
                                        <button class="btn red-button" :disabled="awaitingResponse">Reset Attackers</button>
                                    </div>
                                    <div class="col">
                                        <button class="btn red-button" :disabled="awaitingResponse">Reset Defenders</button>
                                    </div>
                                    <div class="col no-background"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// TO DO: find way to get rid of all the switch statements inside this modal's logic
import { paramCase, snakeCase } from 'change-case';
import { mapActions, mapGetters } from 'vuex';
import VueSlider from 'vue-slider-component';
import { buildControlledReinforcementsTemplate } from '@/utils/functions/troopUtils';
import { TroopsIdentifier } from '@/utils/constants/troops';
import { ActionTypeIdentifier } from '@/utils/constants/actions';

export default {
    name: 'GeneralsOfficeModal',
    props: {
        awaitingResponse: {
            type: Boolean,
            default: false,
        },
        allCities: {
            type: Array,
            default: () => [],
        },
        cityId: {
            type: String,
            default: '',
        },
        troops: {
            type: Object,
            default: () => {},
        },
        availableResources: {
            type: Object,
            default: () => {},
        },
        buildings: {
            type: Array,
            default: () => [],
        },
        description: {
            type: String,
            default: '',
        },
        troopsCommandsToShow: {
            type: Array,
            default: () => [],
        },
        reinforcements: {
            type: Array,
            default: () => [],
        },
        setCurrentTroop: {
            type: Function,
            default: () => null,
        },
        setCurrentBuilding: {
            type: Function,
            default: () => null,
        },
        sendAttack: {
            type: Function,
            default: () => null,
        },
        sendReinforcement: {
            type: Function,
            default: () => null,
        },
        sendDetectSpiesMission: {
            type: Function,
            default: () => null,
        },
        sendSpyMission: {
            type: Function,
            default: () => null,
        },
        callBackSpies: {
            type: Function,
            default: () => null,
        },
        callBackReinforcements: {
            type: Function,
            default: () => null,
        },
        sendBackReinforcements: {
            type: Function,
            default: () => null,
        },
        mapActions: {
            type: Set,
            default: () => new Set(),
        },
        foreignSpies: {
            type: Array,
            default: () => [],
        },
        currentCityName: {
            type: String,
            default: '',
        },
        currentCityTile: {
            type: Number,
            default: 0,
        },
        currentCityCoords: {
            type: Object,
            default: () => {},
        },
        targetCity: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        VueSlider,
    },
    data() {
        return {
            TroopsIdentifier,

            resources: this.getResources(),
            numberOfTroopsToRecruit: new Array(2).fill(0),
            numberOfOwnedTroopsToSend: this.buildOwnedTroopsTemplate(), // creates template to be used by v-model
            numberOfControlledReinforcementsToSend: this.buildControlledReinforcementsTemplate({
                reinforcements: this.reinforcements,
                troop_name: null,
                current_city_id: this.cityId,
            }), // creates template to be used by v-model
            numberOfOwnedSpiesToSendDetecting: [
                {
                    name: 'Spy',
                    number: 0,
                },
            ],
            numberOfOwnedSpiesToSendSpying: [
                {
                    name: 'Spy',
                    number: 0,
                },
            ],
            numberOfOwnedSpiesToCallBack: this.buildOwnedSpiesTemplate(),
            numberOfControlledSpiesToSendDetecting: this.buildControlledReinforcementsTemplate({
                reinforcements: this.reinforcements,
                troop_name: TroopsIdentifier.SPY,
                current_city_id: this.cityId,
            }), // creates template to be used by v-model
            numberOfControlledSpiesToSendSpying: this.buildControlledReinforcementsTemplate({
                reinforcements: this.reinforcements,
                troop_name: TroopsIdentifier.SPY,
                current_city_id: this.cityId,
            }), // creates template to be used by v-model
            numberOfControlledSpiesToCallBack: this.buildControlledSpiesTemplate(),
            reinforcementsToCallBack: this.buildReinforcementsTemplate('sent_reinforcements'),
            reinforcementsToSendBack: this.buildReinforcementsTemplate('received_reinforcements'),
            validationInputErrorMessage: '',
            validationInputErrorMessageIndex: 0,
            troopNameTooltipVisible: new Array(6).fill(false),
            commandFinishTooltipVisible: new Array(7).fill(false),
            extraReinforcementOptionsVisible: {
                // used for other tabs
                soldiers: new Array(this.troops.soldiers.length).fill(false),
                tanks: new Array(this.troops.tanks.length).fill(false),
                planes: new Array(this.troops.planes.length).fill(false),
                other: new Array(this.troops.other.length).fill(false),
                missions: new Array(2).fill(false),
                callBackSpies: new Array(this.foreignSpies.length).fill(false),

                // used for reinforcements tab
                controlledSent: null,
                uncontrolledSent: null,
                controlledReceived: null,
                uncontrolledReceived: null,
            },
            options: {
                dotSize: 14,
                width: 60,
                height: 4,
                contained: false,
                direction: 'ltr',
                data: null,
                dataLabel: 'label',
                dataValue: 'value',
                interval: 1,
                disabled: false,
                clickable: true,
                duration: 0,
                adsorb: true,
                lazy: false,
                tooltip: 'active',
                tooltipPlacement: 'top',
                useKeyboard: false,
                keydownHook: null,
                dragOnClick: false,
                enableCross: true,
                fixed: false,
                order: true,
                marks: false,
            },
        };
    },
    computed: {
        spies() {
            return this.troops.soldiers.find(troop => troop.name === 'Spy');
        },
        spyMissions() {
            const RelevantActions = [
                ActionTypeIdentifier.DETECT_SPIES_MISSION,
                ActionTypeIdentifier.INCOMING_SPY_MISSION,
                ActionTypeIdentifier.OUTGOING_SPY_MISSION,
                ActionTypeIdentifier.RETURNING_SPIES,
            ];
            const FilteredActions = new Set();

            this.mapActions.forEach(action => {
                if (RelevantActions.includes(action.actionType)) {
                    FilteredActions.add(action);
                }
            });
            return FilteredActions;
        },
        numberOfSpiesPerMission() {
            const numOfSpiesObj = {};
			this.spyMissions.forEach(mission => {
				numOfSpiesObj[mission.originalData._id] = mission.originalData.spies.reduce((accumulator, currentValue) => {
					return accumulator + currentValue.numOfTroops;
				}, 0);
			})
            return numOfSpiesObj;
        },
        troopsWithRequirementsNotMet() {
            const filteredTroops = [];
            const troops = this.troops.other;
            for (let i = 0; i < troops.length; i++) {
                if (!this.checkAllRequirementsAreMet(troops[i])) {
                    filteredTroops.push(troops[i].name);
                }
            }
            return filteredTroops;
        },
        requirementsMetPerTroop() {
            const troops = this.troops.other;
            const filteredTroops = [];
            for (let i = 0; i < troops.length; i++) {
                const troopObj = {
                    name: troops[i].name,
                    requirementsMet: [],
                };
                for (let j = 0; j < troops[i].requirements.length; j++) {
                    if (this.checkSingleRequirementIsMet(troops[i].requirements[j])) {
                        troopObj.requirementsMet.push(troops[i].requirements[j].name);
                    }
                }
                filteredTroops.push(troopObj);
            }
            return filteredTroops;
        },
        formattedTargetCity() {
            if (!this.targetCity) {
                return;
            }
            const city = this.targetCity;
            return `${city.name}` + ' ( ' + `${city.coords.x}` + ' | ' + `${city.coords.y}` + ' )';
        },
    },
    watch: {
        foreignSpies: {
            handler() {
                this.numberOfOwnedSpiesToCallBack = this.buildOwnedSpiesTemplate();
                this.numberOfControlledSpiesToCallBack = this.buildControlledSpiesTemplate();
                const diff = this.foreignSpies.length - this.extraReinforcementOptionsVisible.callBackSpies.length;
                if (diff > 0) {
                    for (let i = 1; i <= diff; i++) {
                        this.extraReinforcementOptionsVisible.callBackSpies.push(false);
                    }
                }
            },
            deep: true,
        },
        'reinforcements.length': {
            // TO DO: find a way to update existing templates instead of replacing them with new ones
            handler() {
                this.numberOfControlledReinforcementsToSend = this.buildControlledReinforcementsTemplate({
                    reinforcements: this.reinforcements,
                    troop_name: null,
                    current_city_id: this.cityId,
                });
                this.numberOfControlledSpiesToSendDetecting = this.buildControlledReinforcementsTemplate({
                    reinforcements: this.reinforcements,
                    troop_name: TroopsIdentifier.SPY,
                    current_city_id: this.cityId,
                });
                this.numberOfControlledSpiesToSendSpying = this.buildControlledReinforcementsTemplate({
                    reinforcements: this.reinforcements,
                    troop_name: TroopsIdentifier.SPY,
                    current_city_id: this.cityId,
                });
                this.numberOfControlledSpiesToCallBack = this.buildControlledSpiesTemplate();
                this.reinforcementsToCallBack = this.buildReinforcementsTemplate('sent_reinforcements');
                this.reinforcementsToSendBack = this.buildReinforcementsTemplate('received_reinforcements');
            },
            // deep: true,
        },
    },
    methods: {
        ...mapGetters(['getResources', 'getTroops']),
        ...mapActions(['startTroopRecruitmentCommand']),

        buildControlledReinforcementsTemplate,

        openTroopDetailsModal(troop) {
            if (troop) {
                this.setCurrentTroop(troop);
            } else {
                return;
            }
            document.getElementById('troop-details-modal').style.transform = 'translateY(-20px)';
            document.getElementById('troop-details-modal').style.opacity = '0';
            document.getElementById('troop-details-modal').style.display = 'block';
            document.getElementById('troop-details-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('troop-details-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('troop-details-modal').style.transform = 'translateY(0)';
                document.getElementById('troop-details-modal').style.opacity = '1';
            }, 0);
        },

        openBuildingDetailsModal(building) {
            if (building) {
                this.setCurrentBuilding(building);
            }
            document.getElementById('building-details-modal').style.transform = 'translateY(-20px)';
            document.getElementById('building-details-modal').style.opacity = '0';
            document.getElementById('building-details-modal').style.display = 'block';
            document.getElementById('building-details-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('building-details-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('building-details-modal').style.transform = 'translateY(0)';
                document.getElementById('building-details-modal').style.opacity = '1';
            }, 0);
        },

        getBuildingFromRequirementName(requirement_name) {
            return this.buildings.find(building => building.name === requirement_name);
        },

        sendCommand(data) {
            if (!this.verifyInput(this.numberOfTroopsToRecruit[this.troops.other.indexOf(data)])) {
                this.validationInputErrorMessage = 'Numar incorect';
                console.error(this.validationInputErrorMessage);
                this.validationInputErrorMessageIndex = this.troops.other.indexOf(data);
                return;
            } else {
                if (this.numberOfTroopsToRecruit[this.troops.other.indexOf(data)] <= 0) {
                    this.validationInputErrorMessage = 'Numarul nu poate fi negativ';
                    console.error(this.validationInputErrorMessage);
                    this.validationInputErrorMessageIndex = this.troops.other.indexOf(data);
                    return;
                }
            }

            if (
                this.resources.metal - data.metal * this.numberOfTroopsToRecruit[this.troops.other.indexOf(data)] < 0 ||
                this.resources.oil - data.oil * this.numberOfTroopsToRecruit[this.troops.other.indexOf(data)] < 0 ||
                this.resources.aluminum - data.aluminum * this.numberOfTroopsToRecruit[this.troops.other.indexOf(data)] < 0
            ) {
                this.validationInputErrorMessage = 'Nu ai suficiente resurse';
                console.error(this.validationInputErrorMessage);
                this.validationInputErrorMessageIndex = this.troops.other.indexOf(data);
                return;
            }

            this.startTroopRecruitmentCommand({
                name: data.name,
                numOfTroops: this.numberOfTroopsToRecruit[this.troops.other.indexOf(data)],
            });
        },

        calculateMaxTroops(troop) {
            const maxTroopsForMetal =
                this.availableResources.metal > troop.metal ? Math.floor(this.availableResources.metal / troop.metal) : 0;
            const maxTroopsForOil =
                this.availableResources.oil > troop.oil ? Math.floor(this.availableResources.oil / troop.oil) : 0;
            const maxTroopsForAluminum =
                this.availableResources.aluminum > troop.aluminum
                    ? Math.floor(this.availableResources.aluminum / troop.aluminum)
                    : 0;

            return Math.min(maxTroopsForMetal, maxTroopsForOil, maxTroopsForAluminum);
        },

        verifyInput(val) {
            return !(isNaN(val) || val === '');
        },

        calculateTotalTime(numOfTroops, timeStr) {
            // Convert time string to total seconds
            const timeArr = timeStr.split(':');
            const totalSeconds = parseInt(timeArr[0]) * 3600 + parseInt(timeArr[1]) * 60 + parseInt(timeArr[2]);

            // Multiply total seconds by integer parameter
            const multipliedSeconds = totalSeconds * numOfTroops;

            // Convert multiplied seconds back to time string format
            const hours = Math.floor(multipliedSeconds / 3600);
            const minutes = Math.floor((multipliedSeconds % 3600) / 60);
            const seconds = multipliedSeconds % 60;

            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
                .toString()
                .padStart(2, '0')}`;
        },

        toKebabCase(string) {
            return paramCase(string);
        },

        toSnakeCase(string) {
            return snakeCase(string);
        },

        recruitMax(troop) {
            this.numberOfTroopsToRecruit[troop] = this.calculateMaxTroops(this.troops.other[troop]);
        },

        recruitMin(troop) {
            this.numberOfTroopsToRecruit[troop] = this.calculateMaxTroops(this.troops.other[troop]) ? 1 : 0;
        },

        toggleTroopNameVisibility(value, index) {
            this.troopNameTooltipVisible[index] = value;
        },

        toggleCommandTooltipVisibility(value, index) {
            this.commandFinishTooltipVisible[index] = value;
        },

        // used for other tabs
        toggleExtraReinforcementOptionsVisibility(troop_type, index) {
            this.extraReinforcementOptionsVisible[troop_type][index] = !this.extraReinforcementOptionsVisible[troop_type][index];
        },

        // used for reinforcements tab
        toggleReinforcementsDropdownVisibility(type, reinforcement_id) {
            const keys = ['controlledSent', 'uncontrolledSent', 'controlledReceived', 'uncontrolledReceived'];
            if (this.extraReinforcementOptionsVisible[type] === reinforcement_id) {
                this.extraReinforcementOptionsVisible[type] = null;
                return;
            }
            for (const key of keys) {
                if (this.extraReinforcementOptionsVisible[key] !== null) {
                    this.extraReinforcementOptionsVisible[key] = null;
                }
            }
            this.extraReinforcementOptionsVisible[type] = reinforcement_id;
        },

        closeModal() {
            document.getElementById('generals-office-modal').style.transition =
                'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
            document.getElementById('generals-office-modal').style.transform = 'translateY(-20px)';
            document.getElementById('generals-office-modal').style.opacity = '0';
            setTimeout(() => {
                document.getElementById('generals-office-modal').style.display = 'none';
                document.getElementById('generals-office-modal').classList.remove('show');
                document.getElementById('generals-office-modal').style.removeProperty('transform');
                document.getElementById('generals-office-modal').style.removeProperty('opacity');
            }, 150);
        },

        getBuildingAppearance(requirement) {
            const building = this.buildings.filter(bld => bld.name === requirement.name)[0];
            if (building) {
                for (let i = building?.appearance?.length; i >= 0; i--) {
                    if (requirement.level >= building.appearance[i]) {
                        return i + 2;
                    }
                }
            }
            return 1;
        },

        checkSingleRequirementIsMet(requirement) {
            const allBuildings = this.buildings;
            const requiredBuilding = allBuildings.find(building => building.name === requirement.name);
            return requiredBuilding?.level >= requirement.level;
        },

        checkAllRequirementsAreMet(troop) {
            const allBuildings = this.buildings;
            let requirementsMet = true;
            for (let i = 0; i < allBuildings.length; i++) {
                for (let j = 0; j < troop.requirements.length; j++) {
                    if (!this.checkSingleRequirementIsMet(troop.requirements[j])) {
                        requirementsMet = false;
                    }
                }
            }
            return requirementsMet;
        },

        buildOwnedTroopsTemplate() {
            const troops = this.troops;
            const toSendPerTroop = {};
            const keys = Object.keys(troops);
            for (let i = 0; i < keys.length; i++) {
                let index = 0;
                const troopsTypeArray = [];
                for (let j = 0; j < troops[keys[i]].length; j++) {
                    const troopObj = {
                        name: troops[keys[i]][j].name,
                        number: 0,
                        index: index++,
                    };
                    troopsTypeArray.push(troopObj);
                }
                toSendPerTroop[keys[i]] = troopsTypeArray;
            }
            return toSendPerTroop;
        },

        buildOwnedSpiesTemplate() {
            const spies = this.foreignSpies;
            const foreignSpiesArray = [];
            for (let i = 0; i < spies.length; i++) {
                const spiesObj = spies[i].spies;
                for (let j = 0; j < spiesObj.length; j++) {
                    if (spiesObj[j].owned) {
                        foreignSpiesArray.push({
                            name: 'Spy',
                            numOfTroops: spiesObj[j].numOfTroops,
                            number: 0,
                            toCity: spies[i].toCity,
                        });
                    }
                }
            }
            return foreignSpiesArray;
        },

        buildControlledSpiesTemplate() {
            let index = 0;
            const spies = this.foreignSpies;
            const foreignSpiesArray = [];
            for (let i = 0; i < spies.length; i++) {
                const spiesObj = spies[i].spies;
                for (let j = 0; j < spiesObj.length; j++) {
                    if (!spiesObj[j].owned) {
                        foreignSpiesArray.push({
                            troopName: 'Spy',
                            numOfTroops: spiesObj[j].numOfTroops,
                            toSend: 0,
                            id: spiesObj[j].reinforcement,
                            fromUser: spies[i].fromUser,
                            fromCity: spies[i].fromCity,
                            toCity: spies[i].toCity,
                            index: index++,
                        });
                    }
                }
            }
            return foreignSpiesArray;
        },

        buildReinforcementsTemplate(type) {
            // TO DO: filter out reinforcements with power less than 100
            // console.time('buildReinforcementsTemplate')
            let index = 0;
            const reinforcements = this.reinforcements;
            const reinforcementsByPermissions = {};
            // const reinforcementsByCity = {};
            for (let i = 0; i < reinforcements.length; i++) {
                const reinforcementObj = reinforcements[i];
                const permissions = reinforcementObj.targetCanControl ? 'controlled' : 'uncontrolled';
                for (let j = 0; j < reinforcementObj.troopsInCities.length; j++) {
                    const troopsInCitiesObj = reinforcementObj.troopsInCities[j];
                    let isValid;
                    let discriminator;
                    switch (type) {
                        case 'sent_reinforcements':
                            isValid =
                                troopsInCitiesObj.currentCity.city !== this.cityId && reinforcementObj.toCity?._id !== this.cityId;
                            discriminator = reinforcementObj.toCity?._id;
                            break;
                        case 'received_reinforcements':
                            isValid =
                                troopsInCitiesObj.currentCity.city === this.cityId && reinforcementObj.fromCity?._id !== this.cityId;
                            discriminator = reinforcementObj.fromCity?._id;
                            break;
                    }
                    if (!isValid) {
                        continue;
                    }
                    const REINFORCEMENT = {
                        id: reinforcementObj._id,
                        troopName: troopsInCitiesObj.troopName,
                        numOfTroops: troopsInCitiesObj.numOfTroops,
                        fromCity: reinforcementObj.fromCity,
                        fromUser: reinforcementObj.fromUser,
                        toCity: reinforcementObj.toCity,
                        toUser: reinforcementObj.toUser,
                        arrived: troopsInCitiesObj.arrived,
                        mission: troopsInCitiesObj.mission,
                        owned: troopsInCitiesObj.pastCities[0].city === this.cityId,
                        index: index++,
                    };

                    if (!reinforcementsByPermissions[permissions]) {
                        reinforcementsByPermissions[permissions] = {};
                    }
                    if (!reinforcementsByPermissions[permissions][discriminator]) {
                        reinforcementsByPermissions[permissions][discriminator] = {};
                    }
                    if (!reinforcementsByPermissions[permissions][discriminator][reinforcementObj._id]) {
                        reinforcementsByPermissions[permissions][discriminator][reinforcementObj._id] = [];
                    }
                    reinforcementsByPermissions[permissions][discriminator][reinforcementObj._id].push(REINFORCEMENT);
                }
            }
            if (!reinforcementsByPermissions.controlled) {
                reinforcementsByPermissions.controlled = {};
            }
            if (!reinforcementsByPermissions.uncontrolled) {
                reinforcementsByPermissions.uncontrolled = {};
            }
            // console.timeEnd('buildReinforcementsTemplate')
            return reinforcementsByPermissions;
        },

        calculateNumberOfReinforcements(type, permissions, city, reinforcement, owned, include_all_troops) {
            let reinforcements;

            switch (type) {
                case 'sent':
                    reinforcements = this.reinforcementsToCallBack[permissions][city][reinforcement];
                    break;
                case 'received':
                    reinforcements = this.reinforcementsToSendBack[permissions][city][reinforcement];
                    break;
            }
            // if (arrived !== null) {
            // 	reinforcements = this.reinforcementsToCallBack[type][city].filter(troops => troops.arrived === arrived);
            // } else {
            // 	reinforcements = this.reinforcementsToCallBack[type][city];
            // }
            if (include_all_troops) {
                return (
                    reinforcements.reduce((accumulator, currentValue) => {
                        if (currentValue.owned === owned) {
                            return accumulator + currentValue.numOfTroops;
                        }
                    }, 0) || 0
                );
            }
            return (
                reinforcements.reduce((accumulator, currentValue) => {
                    if (currentValue.arrived && currentValue.owned === owned) {
                        return accumulator + currentValue.numOfTroops;
                    }
                }, 0) || 0
            );
        },

        assignNumberOfOwnedSpiesToSend(mission, index, number) {
            switch (mission) {
                case 'detect':
                    this.numberOfOwnedSpiesToSendDetecting[0].number = number;
                    break;
                case 'spy':
                    this.numberOfOwnedSpiesToSendSpying[0].number = number;
                    break;
                case 'call_back':
                    this.numberOfOwnedSpiesToCallBack[index].number = number;
                    break;
            }
        },

        assignNumberOfOwnedTroopsToSend(type_of_troop, index, origin, number) {
            this.numberOfOwnedTroopsToSend[type_of_troop][index][origin] = number;
        },

        assignAllControlledReinforcementsByTroop(location, troop_name) {
            let reinforcements;
            switch (location) {
                case 'all_troops':
                    reinforcements = this.getControlledReinforcementsPerTroop(troop_name);
                    break;
                case 'detect_mission':
                    reinforcements = this.numberOfControlledSpiesToSendDetecting;
                    break;
                case 'spy_mission':
                    reinforcements = this.numberOfControlledSpiesToSendSpying;
                    break;
                case 'call_back':
                    reinforcements = this.numberOfControlledSpiesToCallBack;
                    break;
            }
            for (let i = 0; i < reinforcements.length; i++) {
                this.assignNumberOfControlledReinforcementsToSend(
                    location,
                    reinforcements[i].index,
                    reinforcements[i].numOfTroops,
                );
            }
        },

        assignNumberOfControlledReinforcementsToSend(location, index, number) {
            switch (location) {
                case 'all_troops':
                    this.numberOfControlledReinforcementsToSend[index].toSend = number;
                    break;
                case 'detect_mission':
                    this.numberOfControlledSpiesToSendDetecting[index].toSend = number;
                    break;
                case 'spy_mission':
                    this.numberOfControlledSpiesToSendSpying[index].toSend = number;
                    break;
                case 'call_back':
                    this.numberOfControlledSpiesToCallBack[index].toSend = number;
                    break;
            }
        },

        calculateControlledInfiltratedSpies(spies) {
            return spies.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.numOfTroops;
            }, 0);
        },

        calculateTroopsSelectedFromDropdown(troops) {
            return troops.reduce((accumulator, currentValue) => {
                if (!currentValue.toSend) {
                    return accumulator;
                }
                return accumulator + parseInt(currentValue.toSend, 10);
            }, 0);
        },

        getInfiltratedControlledSpiesByCity(city_id) {
            return this.numberOfControlledSpiesToCallBack.filter(sp => sp.toCity?._id === city_id);
        },

        getControlledReinforcementsPerTroop(troop_name) {
            const reinforcements = this.numberOfControlledReinforcementsToSend;
            return reinforcements.filter(
                reinforcement => reinforcement.troopName === troop_name && reinforcement.fromCity?._id !== this.cityId,
            );
        },

        // need better way to get player name
        getPlayerNameFromId(player_id) {
            // const cityOwnedByPlayer = this.allCities.find(city => city.user._id === player_id);
            // return cityOwnedByPlayer.user.username;
            return player_id;
        },

        // to be used temporarily
        getCityNameFromId(city_id) {
            return this.allCities.find(city => city._id === city_id).name;
        },

        getTroopFromControlledReinforcements(troop_name) {
            const troops = [...this.troops.soldiers, ...this.troops.tanks, ...this.troops.planes, ...this.troops.other];
            return troops.find(troop => troop.name === troop_name);
        },

        getOwnedTroopsToSend(location) {
            const troopsWithoutTroopType = [
                ...this.numberOfOwnedTroopsToSend?.soldiers,
                ...this.numberOfOwnedTroopsToSend?.tanks,
                ...this.numberOfOwnedTroopsToSend?.planes,
                ...this.numberOfOwnedTroopsToSend?.other,
            ];
            let troopsNotZero;
            switch (location) {
                case 'all_troops':
                    troopsNotZero = troopsWithoutTroopType.filter(troop => troop.number > 0);
                    break;
                case 'detect_mission':
                    troopsNotZero = this.numberOfOwnedSpiesToSendDetecting.filter(troop => troop.number > 0);
                    break;
                case 'spy_mission':
                    troopsNotZero = this.numberOfOwnedSpiesToSendSpying.filter(troop => troop.number > 0);
                    break;
                case 'call_back':
                    troopsNotZero = this.numberOfOwnedSpiesToCallBack.filter(troop => troop.number > 0);
                    break;
            }
            const troopNamesArray = [];
            const numOfTroopsArray = [];

            for (let i = 0; i < troopsNotZero.length; i++) {
                troopNamesArray.push(troopsNotZero[i].name);
                numOfTroopsArray.push(parseInt(troopsNotZero[i].number));
            }

            if (troopsNotZero.length) {
                return {
                    troopsName: troopNamesArray,
                    numOfTroops: numOfTroopsArray,
                    owned: true,
                };
            }
            return null;
        },

        getControlledReinforcementsToSendGroupedById(location) {
            let reinforcements;
            switch (location) {
                case 'all_troops':
                    reinforcements = this.numberOfControlledReinforcementsToSend;
                    break;
                case 'detect_mission':
                    reinforcements = this.numberOfControlledSpiesToSendDetecting;
                    break;
                case 'spy_mission':
                    reinforcements = this.numberOfControlledSpiesToSendSpying;
                    break;
                case 'call_back':
                    reinforcements = this.numberOfControlledSpiesToCallBack;
                    break;
            }
            const reinforcementsGroupedById = {};
            const reinforcementsNotZero = reinforcements.filter(reinforcement => reinforcement.toSend > 0);

            for (let i = 0; i < reinforcementsNotZero.length; i++) {
                if (!reinforcementsGroupedById[reinforcementsNotZero[i].id]) {
                    reinforcementsGroupedById[reinforcementsNotZero[i].id] = {
                        troopsName: [reinforcementsNotZero[i].troopName],
                        numOfTroops: [reinforcementsNotZero[i].toSend],
                        owned: false,
                    };
                } else {
                    const IndexOfTroopAlreadyInArray = reinforcementsGroupedById[
                        reinforcementsNotZero[i].id
                    ].troopsName.findIndex(troop_name => troop_name === reinforcementsNotZero[i].troopName);
                    if (IndexOfTroopAlreadyInArray !== -1) {
                        reinforcementsGroupedById[reinforcementsNotZero[i].id].numOfTroops[IndexOfTroopAlreadyInArray] +=
                            reinforcementsNotZero[i].toSend;
                    } else {
                        reinforcementsGroupedById[reinforcementsNotZero[i].id].troopsName.push(
                            reinforcementsNotZero[i].troopName,
                        );
                        reinforcementsGroupedById[reinforcementsNotZero[i].id].numOfTroops.push(reinforcementsNotZero[i].toSend);
                    }
                }
            }

            return reinforcementsGroupedById;
        },

        getControlledReinforcementsToSend(location) {
            const reinforcementsGroupedById = this.getControlledReinforcementsToSendGroupedById(location);
            const reinforcementsToSendArray = [];
            const keys = Object.keys(reinforcementsGroupedById);
            for (let i = 0; i < keys.length; i++) {
                reinforcementsToSendArray.push(reinforcementsGroupedById[keys[i]]);
            }
            return reinforcementsToSendArray;
        },

        getControlledReinforcementsIdArray(location) {
            return Object.keys(this.getControlledReinforcementsToSendGroupedById(location));
        },

        getTroopsAndReinforcementsForCommand(location) {
            if (!this.getControlledReinforcementsToSend(location)) {
                return [this.getOwnedTroopsToSend(location)];
            }
            if (!this.getOwnedTroopsToSend(location)) {
                return [...this.getControlledReinforcementsToSend(location)];
            }
            return [this.getOwnedTroopsToSend(location), ...this.getControlledReinforcementsToSend(location)];
        },

        validateAndSendAttack() {
            // TO DO: add validations
            this.sendAttack({
                // attackedCity: "64d07d3a90dc3f33904cbc46", // pofin 50|15
                // attackedCity: "64d3e331fa97a24d79c419ed", // alex2
                // attackedCity: "64d0f20190dc3f33904cbc66", // geligo 37|3
                // attackedCity: "64d3c017ba60fc48ac524cbf", // wojen 38|2
                attackedCity: this.targetCity._id,
                troops: this.getTroopsAndReinforcementsForCommand('all_troops'),
                reinforcements: this.getControlledReinforcementsIdArray('all_troops'),
                oil: 500,
                rations: 500,
            });
        },

        validateAndSendReinforcement() {
            // TO DO: add validations
            this.sendReinforcement({
                // cityId: "64d07d3a90dc3f33904cbc46", // pofin 50|15
                // cityId: "64d3e331fa97a24d79c419ed", // alex2
                // cityId: "64d0f20190dc3f33904cbc66", // geligo 37|3
                // cityId: "64d3c017ba60fc48ac524cbf", // wojen 38|2
                cityId: this.targetCity._id,
                troops: this.getTroopsAndReinforcementsForCommand('all_troops'),
                reinforcements: this.getControlledReinforcementsIdArray('all_troops'),
                targetCanControl: true,
                targetCanPassControl: true,
            });
        },

        validateAndSendDetectSpiesMission() {
            // TO DO: add validations
            this.sendDetectSpiesMission({
                troops: this.getTroopsAndReinforcementsForCommand('detect_mission'),
                reinforcements: this.getControlledReinforcementsIdArray('detect_mission'),
            });
        },

        validateAndSendSpyMission() {
            // TO DO: add validations
            this.sendSpyMission({
                // cityId: "64d07d3a90dc3f33904cbc46", // pofin 50|15
                // cityId: "64d3e331fa97a24d79c419ed", // alex2
                // cityId: '64d0f20190dc3f33904cbc66', // geligo 37|3
                // cityId: "64d3c017ba60fc48ac524cbf", // wojen 38|2
                cityId: this.targetCity._id,
                troops: this.getTroopsAndReinforcementsForCommand('spy_mission'),
                reinforcements: this.getControlledReinforcementsIdArray('spy_mission'),
            });
        },

        validateAndCallBackSpies(city_id) {
            // TO DO: add validations
            this.callBackSpies({
                // cityId: "64d07d3a90dc3f33904cbc46", // pofin 50|15
                // cityId: "64d3e331fa97a24d79c419ed", // alex2
                // cityId: '64d0f20190dc3f33904cbc66', // geligo 37|3
                // cityId: "64d3c017ba60fc48ac524cbf", // wojen 38|2
                cityId: city_id,
                troops: this.getTroopsAndReinforcementsForCommand('call_back'),
                reinforcements: this.getControlledReinforcementsIdArray('call_back'),
            });
        },

        validateAndCallBackReinforcements(reinforcement_id) {
            // TO DO: add validations
            this.callBackReinforcements(reinforcement_id);
        },

        validateAndSendBackReinforcements(reinforcement_id) {
            // TO DO: add validations
            this.sendBackReinforcements(reinforcement_id);
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header {
    border: none;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
}

.modal-title {
    width: 60%;
    font-family: 'bebas', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    padding-bottom: 0.75rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
    font-size: 1.5rem;
}

.building-description {
    width: 60%;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    padding-right: 1rem;
    max-height: 2.5rem;
    overflow-y: overlay;
    color: #b7b6b6;
    text-align: justify;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.building-description::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.building-description::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 5vh;
    padding: 4rem;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding: 3rem 1rem 1rem 1rem;
}

.nav-tabs {
    border: none;
}

.nav-tabs .nav-link {
    border-radius: 0;
    border: 2px solid #434343;
    border-bottom: none;
    color: #afafaf;
    transform: translateY(15px);
}

.nav-tabs .nav-item {
    margin-right: 0.25rem;
}

.nav-tabs .nav-item:nth-last-child(2) {
    margin-right: 0;
}

.nav-tabs .nav-link:hover {
    color: lightgray;
    border: 2px solid #434343;
    border-bottom: none;
}

.nav-tabs .nav-link.active {
    background: none;
    border: 2px solid #434343;
    border-bottom: none;
    color: ghostwhite;
    padding-top: 1rem;
    padding-bottom: 1rem;
    transform: translateY(-1px);
}

.nav-filler {
    flex-grow: 1;
    border-bottom: 2px solid #434343;
}

.tab-pane {
    padding: 3rem 1.5rem 1.5rem 1.5rem;
    background: none;
    border: 2px solid #434343;
    border-top: none;
}

.row {
    flex-wrap: nowrap;
    gap: 2px;
    margin: 0;
    min-height: 2rem;
}

.label .col {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    color: ghostwhite;
    height: 3rem;
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.col {
    position: relative;
    flex: 1 1 auto;
    background: rgba(0, 0, 0, 0.5);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    gap: 0.5rem;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
    min-width: 4.5rem;
}

.col.label {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.form-control {
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    border: 1px solid whitesmoke;
    border-radius: 0;
    padding-left: 1rem;
    opacity: 0.8;
    color: ghostwhite;
    height: 2rem;
    text-align: center;
}

.form-control:focus {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    color: ghostwhite;
    border: 1px solid ghostwhite;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #1a1a1a inset;
    -webkit-text-fill-color: ghostwhite;
    caret-color: ghostwhite;
}

.form-control:disabled {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    color: ghostwhite;
    border: 1px solid ghostwhite;
    opacity: 0.25;
}

.form-check-input {
    cursor: pointer;
}

.form-check-input:checked {
    background-color: #d34848;
    border: none;
    background-size: 10px;
}

.form-check-input:focus {
    box-shadow: none;
}

.form-select {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
    color: ghostwhite;
    border-color: ghostwhite;
    filter: brightness(10);
    font-size: 0.75rem;
    cursor: pointer;
    height: 2rem;
    padding-right: 0.5rem;
}

.form-select:focus {
    box-shadow: none;
    border-color: ghostwhite;
}

.btn {
    padding: 0.25rem;
}

.red-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
    width: 100%;
}

.red-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

.yellow-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
    width: 100%;
}

.yellow-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.blue-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgb(34, 41, 58), rgba(255, 255, 255, 0.2));
    border: 3px solid dodgerblue;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
    width: 100%;
}

.blue-button:hover {
    background: linear-gradient(to bottom, rgba(96, 95, 42, 0.2), rgb(52, 52, 70));
    color: ghostwhite;
}

.neutral-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgb(30, 30, 30), rgba(255, 255, 255, 0.2));
    border: 3px solid dimgray;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.neutral-button:hover {
    background: linear-gradient(to bottom, rgba(40, 40, 40, 0.2), rgb(60, 60, 60));
    color: ghostwhite;
}

.add-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.25rem !important;
}

/*          COMMANDS          */

.send-command-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.coords-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 80%;
}

.command-buttons-wrapper {
    width: 18%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

#commands-page .troops-table {
    width: 49%;
}

.troops-table-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.coords-wrapper .form-control {
    text-align: left;
}

#commands-page .troops-table .form-control,
#simulator-page .troops-table .form-control {
    padding: 0.25rem;
    font-size: 0.75rem;
    text-align: left;
}

#commands-page .troops-table .col:first-child {
    width: 3rem;
}

.max-troops-to-send {
    color: wheat;
    font-size: 0.75rem;
}

.max-troops-to-send:hover {
    color: cornflowerblue;
    cursor: pointer;
}

.controlled-reinforcements-select-column {
    position: relative;
}

.reinforcements-dropdown-button > span {
    writing-mode: vertical-lr;
}

.reinforcements-dropdown-wrapper {
    position: absolute;
    top: 3.5rem;
    right: -1rem;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 2px;
    background: black;
    padding: 2px;
    width: max-content;
}

.reinforcements-dropdown-wrapper .col:not(.label) {
    background: rgba(150, 150, 150, 0.125);
}

.reinforcements-dropdown-wrapper .col:last-child {
    min-width: 14rem;
}

/*          ESPIONAGE          */

#espionage-page .send-command-wrapper {
    justify-content: center;
    margin-bottom: 0;
}

#espionage-page .table-wrapper {
    margin-bottom: 4rem;
}

#espionage-page .troop-name {
    color: burlywood;
    cursor: pointer;
}

#espionage-page .troop-name:hover {
    color: cornflowerblue;
}

#espionage-page .troops-table {
    width: 80%;
    margin: 0 auto;
}

.active-missions-table .col {
    flex-basis: 20%;
}

.active-missions-table .col:first-child {
    flex-basis: 40%;
}

#espionage-page .troops-table .form-control {
    padding: 0.25rem;
    font-size: 0.75rem;
    text-align: left;
}

.table-header {
    width: 80%;
    margin: 3rem auto 1rem auto;
    color: lightgoldenrodyellow;
}

.mission-name {
    color: lightgray;
}

.city-icon-wrapper {
    width: fit-content;
}

.city-icon {
    height: 2rem;
}

.city-name-and-coords {
    display: flex;
    column-gap: 0.25rem;
    flex-wrap: wrap;
}

.city-coords {
    text-wrap: none;
}

/*          REINFORCEMENTS          */

#reinforcements-page .troop-name {
    color: burlywood;
    cursor: pointer;
}

#reinforcements-page .troop-name:hover {
    color: cornflowerblue;
}

#reinforcements-page .table-header {
    margin-left: 0;
}

#reinforcements-page .table-header:first-child {
    margin-top: 0;
}

#reinforcements-page .reinforcements-dropdown-wrapper {
    top: 100%;
    right: 0;
}

/*          TROOPS          */

.troops-table {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 1rem;
}

.table-section {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

#troops-page .troops-table .col {
    flex-basis: 15%;
}

#troops-page .troops-table .col:first-child {
    flex-basis: 40%;
}

#troops-page .troop-name {
    color: burlywood;
    cursor: pointer;
}

#troops-page .troop-name:hover {
    color: cornflowerblue;
}

.troop-image-wrapper {
    min-width: 2.5rem;
    height: 2.5rem;
    overflow: hidden;
}

.troop-image {
    width: 2.5rem;
    cursor: pointer;
}

/*          RECRUITMENT         */

#recruitment-page .nav {
    align-items: baseline;
    border: none;
    transform: translateY(-2px);
}

#recruitment-page .nav-item {
    margin-right: 0.25rem;
}

#recruitment-page .nav-item:nth-last-child(2) {
    margin-right: 0;
}

#recruitment-page .tab-wrapper {
    height: 6.5rem;
    width: calc(3rem + 8px);
    overflow: hidden;
    padding: 2px;
    border: 2px solid #434343;
    border-bottom: none;
    position: relative;
}

#recruitment-page .tab-wrapper > .tab-img-wrapper {
    width: 3rem;
    transition: filter 0.3s;
    height: 4.25rem;
    overflow: hidden;
}

#recruitment-page .tab-img-wrapper > img {
    width: 3rem;
}

.tab-img-wrapper > img.unavailable {
    filter: grayscale(1) contrast(1.25) brightness(0.5);
}

.requirements-title {
    font-size: 1.125rem;
}

.building-requirements-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 2.75rem;
    margin-top: -1.75rem;
}

.requirements-group {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.requirement-icon {
    filter: grayscale(1) contrast(1.25);
    cursor: pointer;
    height: 2.5rem;
    width: auto;
}

.requirement-name-wrapper {
    display: flex;
    flex-direction: column;
}

.requirement-name {
    font-size: 1rem;
    line-height: 1rem;
    color: lightgray;
    cursor: pointer;
}

.requirement-name:hover {
    color: cornflowerblue;
}

.requirement-level {
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: darkgray;
}

.troop-name-tooltip {
    z-index: 1;
    position: absolute;
    background: black;
    color: ghostwhite;
    font-size: 0.75rem;
    line-height: 0.75rem;
    top: -1rem;
    padding: 0.25rem;
    display: flex;
    border: 1px solid #434343;
}

#recruitment-page .nav-tabs .nav-link {
    padding: 0;
    height: 6rem;
    border-radius: 0;
    border: none;
}

#recruitment-page .nav-tabs .nav-link.active {
    background: none;
    transform: translateY(-31px);
}

#recruitment-page .nav-tabs .nav-link:not(.active) {
    transform: translateY(-7px);
}

#recruitment-page .nav-link.active .tab-wrapper {
    height: 8rem;
    width: calc(4rem + 8px);
}

#recruitment-page .nav-link.active .tab-wrapper > .tab-img-wrapper {
    width: 4rem;
    height: 5.625rem;
}

#recruitment-page .nav-link.active .tab-wrapper > .tab-img-wrapper > img {
    width: 4rem;
}

#recruitment-page .nav-link:hover:not(.active) img {
    filter: brightness(1.2) contrast(1.1);
}

#recruitment-page .nav-link:hover:not(.active) img.unavailable {
    filter: grayscale(1) contrast(1.5) brightness(0.75);
}

#recruitment-page .nav-filler {
    flex-grow: 1;
    border-bottom: 2px solid #434343;
    transform: translateY(80px);
}

.current-troops {
    width: 100%;
    height: 1rem;
    /*background: darkred;*/
    font-size: 0.75rem;
    color: ghostwhite;
}

.max-troops {
    position: absolute;
    bottom: 0;
    width: calc(100% - 4px);
    height: 1rem;
    font-size: 0.75rem;
    color: ghostwhite;
}

.troop-name-tooltip,
.recruitment-command-tooltip {
    z-index: 1;
    position: absolute;
    background: black;
    color: ghostwhite;
    font-size: 0.75rem;
    line-height: 0.75rem;
    top: -1rem;
    padding: 0.25rem;
    display: flex;
    border: 1px solid #434343;
}

.recruitment-command-tooltip {
    padding: 1rem;
    gap: 1rem;
    align-items: center;
    flex-direction: column;
}

.tooltip-troop-name {
    font-size: 1rem;
    padding: 0.25rem 1rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
}

.tooltip-transition-enter-active,
.tooltip-transition-leave-active {
    transition: filter 0.05s linear;
}

.tooltip-transition-enter-from,
.tooltip-transition-leave-to {
    filter: blur(3px);
}

#recruitment-page .tab-pane {
    background: rgba(0, 0, 0, 0.25);
    border: 2px solid #434343;
    border-top: none;
    padding: 3.5rem 2rem 2rem 2rem;
}

.recruitment-window {
    position: relative;
    border: 2px solid #434343;
    display: flex;
    flex-direction: row;
    padding: 2px;
    width: 32%;
    background: black;
}

.recruitment-window .section-title:hover {
    cursor: pointer;
    color: cornflowerblue;
}

.recruitment-image-wrapper {
    position: relative;
}

.recruitment-image-wrapper > img {
    width: 6rem;
    height: 100%;
}

.recruitment-number {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 25%;
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    color: ghostwhite;
}

.recruitment-number::before {
    content: '+';
    margin-right: 0.125rem;
}

.recruitment-command-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0.5rem;
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    margin-left: 2px;
    width: 100%;
}

.min-recruit,
.max-recruit {
    display: block;
    width: 2rem;
    text-align: center;
    border: 2px solid darkgray;
}

.min-recruit:hover,
.max-recruit:hover {
    cursor: pointer;
    color: cornflowerblue;
}

.recruit-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 1rem;
    line-height: 1rem;
    color: ghostwhite;
    width: 100%;
}

.recruit-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

.premium-recruit-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 1rem;
    line-height: 1rem;
    color: ghostwhite;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.premium-recruit-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.premium-icon {
    height: 0.875rem;
}

.cost-window {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2px;
    width: 32%;
    border: 2px solid #434343;
    padding: 2px;
    background: black;
}

.troop-info-window {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2px;
    border: 2px solid #434343;
    padding: 2px;
    background: black;
    width: 32%;
}

.resource-wrapper,
.troop-info-wrapper {
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 1rem;
    width: 8rem;
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    padding: 0.25rem 0.5rem;
    position: relative;
}

.icon-tooltip {
    opacity: 0;
    filter: blur(3px);
    position: absolute;
    top: -1.375rem;
    pointer-events: none;
    transition: opacity 0.3s, filter 0.3s;
    background: black;
    color: ghostwhite;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 0.25rem;
    border: 1px solid #434343;
}

.resource-wrapper:hover .icon-tooltip,
.troop-info-wrapper:hover .icon-tooltip {
    opacity: 1;
    filter: blur(0);
}

.resource-icon,
.troop-info-icon {
    width: 1.5rem;
}

.section-title {
    position: absolute;
    top: -2rem;
}

.clickable {
    text-decoration: underline;
}

.pointer-events-none {
    pointer-events: none;
}

.troop-commands-wrapper {
    position: relative;
    margin-top: 3rem;
    flex-wrap: wrap;
}

.troop-command {
    display: flex;
    /*height: 6rem;*/
    border: 2px solid #434343;
    background: linear-gradient(to bottom, #2a2a2a, #181818);
}

.placed-command {
    border: 2px solid black;
    display: flex;
}

.placed-command .recruitment-image-wrapper {
    height: 6rem;
    width: initial;
}

.placed-command .recruitment-image-wrapper img {
    height: 6rem;
    width: initial;
}

.placed-command .recruitment-number {
    font-size: 0.875rem;
}

.placed-command .recruitment-number:before {
    display: none;
}

.placed-command-info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-left: 2px solid black;
    padding: 0 0.375rem;
}

.placed-command-info-wrapper .recruit-button {
    line-height: 1.5rem;
    font-size: 0.875rem;
    padding: 0 0.375rem;
}

.placed-command-total-time {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    border: 2px solid #606060;
    font-size: 0.875rem;
    line-height: 1.5rem;
    padding: 0 1rem;
}

.command-placeholder {
    width: 6rem;
    height: calc(6rem + 4px);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
}

.placeholder-icon {
    height: 2rem;
    filter: contrast(0);
}

/*          SIMULATOR         */

#simulator-page .no-background {
    background: none;
    flex-basis: 0;
    min-width: 2rem;
    justify-content: center;
    padding: 0 0 0 0.5rem;
}

@media screen and (max-width: 1199px) {
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }
    .recruitment-window {
        width: 48%;
    }
    .cost-window {
        width: 48%;
    }
    .troop-info-window {
        width: 100%;
        margin-top: 3rem;
    }
    .modal-title {
        width: 60%;
    }
    .building-description {
        width: 60%;
    }
}

@media screen and (max-width: 970px) {
    .modal-content {
        padding: 3rem 1rem;
    }
    .recruitment-window {
        width: 100%;
    }
    .cost-window {
        margin-top: 3rem;
        width: 100%;
    }
    .troop-info-window {
        width: 100%;
        margin-top: 3rem;
    }
    .modal-title {
        width: 80%;
    }
    .building-description {
        width: 80%;
    }
    #espionage-page .troops-table,
    #espionage-page .coords-wrapper {
        width: 100%;
    }
    .table-header {
        margin: 3rem auto 1rem 0;
    }
}

@media screen and (max-width: 850px) {
    .col,
    .label .col {
        font-size: 0.75rem;
        min-width: 3rem;
        padding: 0.25rem;
    }
    .col {
        gap: 0.25rem;
    }
    .form-check-input {
        transform: scale(0.8);
    }
    .nav-link {
        font-size: 0.875rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .trade-offers-wrapper .col,
    .created-offers-wrapper .col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .btn {
        font-size: 0.75rem;
        padding: 0.25rem;
    }
    .modal-content {
        padding: 4rem 0 1rem 0;
    }
    #commands-page,
    #espionage-page,
    #troops-page,
    #recruitment-page,
    #simulator-page {
        padding: 3rem 0.5rem 0.5rem 0.5rem;
    }
    .form-control {
        padding: 0.25rem;
    }
}

@media screen and (max-width: 790px) {
    .reinforcements-dropdown-wrapper {
        width: 75vw;
    }

    .reinforcements-dropdown-wrapper .col {
        width: 20%;
    }

    .reinforcements-dropdown-wrapper .col .troop-name {
        display: none;
    }

    .reinforcements-dropdown-wrapper .col:last-child {
        min-width: unset;
    }
}

@media screen and (max-width: 650px) {
    #commands-page .troops-table .col {
        flex-direction: column;
    }
    #commands-page .troops-table .form-control {
        height: 1.25rem;
    }
    #simulator-page .form-select {
        background-position: right 0.5rem center;
        background-size: 8px 12px;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 96%;
    }
    .building-description {
        width: 100%;
        font-size: 0.75rem;
        line-height: 0.75rem;
    }
}

@media screen and (max-width: 500px) {
    .nav-tabs .nav-item {
        margin-right: 0.125rem;
    }
    .nav-link {
        font-size: 0.75rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
    #espionage-page .troops-table .col:first-child,
    #troops-page .troops-table .col:first-child {
        flex-basis: 2rem;
    }
    #simulator-page .troops-table .col:first-child {
        flex-basis: 10rem;
    }
    #espionage-page .troop-name,
    #troops-page .troop-name,
    #simulator-page .troop-name {
        display: none;
    }
    #simulator-page .troops-table .col {
        flex-direction: column;
    }
}

@media screen and (max-width: 460px) {
    #commands-page .troops-table {
        width: 100%;
    }
    #commands-page .troops-table .col {
        flex-direction: row;
    }
    #recruitment-page {
        padding-top: 6rem;
    }
    #recruitment-page .tab-pane {
        padding: 3.5rem 1rem 1rem 1rem;
    }
    #recruitment-page .tab-wrapper {
        height: 5rem;
        width: calc(2rem + 8px);
    }
    #recruitment-page .tab-wrapper > .tab-img-wrapper {
        width: 2rem;
        height: 2.75rem;
    }
    #recruitment-page .tab-img-wrapper > img {
        width: 2rem;
    }
    #recruitment-page .units .tab-img-wrapper > img {
        transform: translateY(-5px);
    }
    #recruitment-page .nav-tabs .nav-link {
        height: 2rem;
    }
    #recruitment-page .nav-link.active .tab-wrapper {
        height: 6.375rem;
        width: calc(3rem + 8px);
    }
    #recruitment-page .nav-link.active .tab-wrapper > .tab-img-wrapper {
        width: 3rem;
        height: 4.125rem;
    }
    #recruitment-page .nav-link.active .tab-wrapper > .tab-img-wrapper > img {
        width: 3rem;
    }
    #recruitment-page .nav-filler {
        transform: translateY(18px);
    }
    .current-troops {
        height: 1rem;
        font-size: 0.625rem;
    }
    #commands-page .troops-table .form-control {
        font-size: 0.625rem;
        height: 2rem;
    }
    .max-troops {
        height: 1rem;
        font-size: 0.625rem;
    }
    #recruitment-page .nav-tabs .nav-link.active {
        transform: translateY(-69px);
    }

    #recruitment-page .nav-tabs .nav-link:not(.active) {
        transform: translateY(-47px);
    }
    .modal-body {
        padding: 5rem 1rem 1rem 1rem;
    }
    .modal-title {
        width: 100%;
    }
    .building-description {
        width: 100%;
    }
}

@media screen and (max-width: 380px) {
    .recruitment-window {
        height: 7rem;
    }
    .recruitment-slider {
        display: none !important;
    }
    .recruitment-image-wrapper {
        overflow-y: clip;
    }
    .recruitment-image-wrapper > img {
        height: initial;
        width: 5rem;
    }
    #espionage-page .troops-table .col,
    #troops-page .troops-table .col {
        font-size: 0.625rem;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
