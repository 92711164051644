<template>
    <div class="arrows-wrapper">
        <OutgoingMapArrow
			v-if="outgoingArrow"
			:arrow="outgoingArrow"
			:angle-between-cities="angleBetweenCities"
			@pin-hovered="(pin_id) => $emit('pin-hovered', pin_id)"
			@pin-unhovered="$emit('pin-unhovered')"
		/>
        <IncomingMapArrow
			v-if="incomingArrow"
			:arrow="incomingArrow"
			:angle-between-cities="angleBetweenCities"
			@pin-hovered="(pin_id) => $emit('pin-hovered', pin_id)"
			@pin-unhovered="$emit('pin-unhovered')"
		/>
    </div>
</template>

<script>
import MapArrowsPair from '@/classes/mapArrows/MapArrowsPair';
import OutgoingMapArrow from '@/components/WorldMap/OutgoingMapArrow';
import {
    getAngleBetweenCities,
    getCityCenterX,
    getCityCenterY,
    getDistanceBetweenCities,
} from '@/utils/functions/mapArrowsUtils';
import { MapArrowDirection } from '@/utils/constants/actions';
import IncomingMapArrow from '@/components/WorldMap/IncomingMapArrow';

export default {
    name: 'MapArrowsPair',
    components: { IncomingMapArrow, OutgoingMapArrow },
    props: {
        arrowsPair: {
            type: MapArrowsPair,
            default: () => new MapArrowsPair([]),
        },
    },
	emits: ['pin-hovered', 'pin-unhovered'],
    computed: {
        outgoingArrow() {
            let Arrow = null;
            this.arrowsPair.items.forEach(arrow => {
                if (arrow.direction === MapArrowDirection.OUTGOING) {
                    Arrow = arrow;
                }
            });
            console.log('^^^^^^^', Arrow);
            return Arrow;
        },
        incomingArrow() {
            let Arrow = null;
            this.arrowsPair.items.forEach(arrow => {
                if (arrow.direction === MapArrowDirection.INCOMING) {
                    Arrow = arrow;
                }
            });
            return Arrow;
        },
        ownX() {
            console.log('ownX', getCityCenterX(this.arrowsPair.ownCity.x));
            return getCityCenterX(this.arrowsPair.ownCity.x);
        },
        ownY() {
            console.log('ownY', getCityCenterY(this.arrowsPair.ownCity.y));
            return getCityCenterY(this.arrowsPair.ownCity.y);
        },
        targetX() {
            return getCityCenterX(this.arrowsPair.targetCity.x);
        },
        targetY() {
            return getCityCenterY(this.arrowsPair.targetCity.y);
        },
        distanceBetweenCities() {
            return getDistanceBetweenCities(this.ownX, this.ownY, this.targetX, this.targetY);
        },
        angleBetweenCities() {
            return getAngleBetweenCities(this.ownX, this.ownY, this.targetX, this.targetY);
        },
    },
};
</script>

<style scoped>
.arrows-wrapper {
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transform-origin: left center;
    left: v-bind(ownX + 'px');
    top: v-bind(ownY + 'px');
    width: v-bind(distanceBetweenCities + 'px');
    transform: rotateZ(v-bind(angleBetweenCities + 'deg'));
}
</style>
