export default class ProgressTracking {
    // static
    durationInSeconds = 0;
    startTimestamp = 0;
    arrivalTimestamp = 0;
    staticDuration = '';
    returnDate = '';
    progressPerSecond = 0;

    // dynamic
    timeUntilArrival = 0;
    dynamicDurationString = '';
    dynamicDuration = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    };
    percentageDone = 0;
    currentTimeoutId = null;

    constructor(action) {
        this.initializeStaticProperties(action);
    }

    initializeStaticProperties(action) {
        this.durationInSeconds = action.originalData.duration;
        this.startTimestamp = this.formatTimestamp(action.originalData.dateCreated);
        this.arrivalTimestamp = this.formatTimestamp(action.originalData.arrivalDate);
        this.staticDuration = this.getFormattedDuration(action.originalData.duration * 1000);
        this.returnDate = this.getEstimatedReturn(this.arrivalTimestamp, this.durationInSeconds);
        this.progressPerSecond = 100 / action.originalData.duration;
    }

    // might not need this in the future
    formatTimestamp(timestamp) {
        return typeof timestamp === 'string' ? parseInt(timestamp) : timestamp;
    }

    getDurationObject(duration) {
        return {
            days: Math.floor(duration / (1000 * 60 * 60 * 24)),
            hours: Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes: Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((duration % (1000 * 60)) / 1000),
        };
    }

    getFormattedDuration(duration_object) {
        return `
            ${duration_object.days ? duration_object.days + 'd ' : ''}
            ${duration_object.hours ? duration_object.hours + 'h ' : ''}
            ${duration_object.minutes ? duration_object.minutes + 'm ' : ''}
            ${duration_object.seconds ? duration_object.seconds + 's' : ''}
        `;
    }

    getEstimatedReturn(arrival_timestamp, duration_in_seconds) {
        const ArrivalDate = new Date(arrival_timestamp + duration_in_seconds * 1000);
        return `${ArrivalDate.toLocaleDateString('en')} ${ArrivalDate.toLocaleTimeString('ro')}`;
    }

    startProgressUpdate(action_reference) {
        this.setInitialProgress();

        const Now = new Date().getTime();
        const TimeUntilNearestSecond = Now % 1000;

        this.currentTimeoutId = setTimeout(() => this.updateProgress(action_reference), TimeUntilNearestSecond);
    }

    setInitialProgress() {
        const TimeUntilArrival = this.getTimeUntilArrival();

        this.timeUntilArrival = TimeUntilArrival;
        this.dynamicDurationString = this.getFormattedDuration(this.dynamicDuration);
        this.dynamicDuration = this.getDurationObject(TimeUntilArrival);

        const DurationInMs = this.durationInSeconds * 1000;
        const TimeElapsed = DurationInMs - this.timeUntilArrival;
        this.percentageDone = (TimeElapsed / DurationInMs) * 100;
    }

    getTimeUntilArrival() {
        const Now = new Date().getTime();
        return this.arrivalTimestamp - Now;
    }

    updateProgress(action_reference) {
        if (this.timeUntilArrival <= 1000) {
            this.stopProgressUpdate();
            return action_reference.selfDestruct();
        }

        this.timeUntilArrival -= 1000;

        this.dynamicDurationString = this.getFormattedDuration(this.dynamicDuration);

        if (this.dynamicDuration.seconds > 0) {
            this.dynamicDuration.seconds--;
        } else {
            this.dynamicDuration.seconds = 59;
            if (this.dynamicDuration.minutes > 0) {
                this.dynamicDuration.minutes--;
            } else {
                this.dynamicDuration.minutes = 59;
                if (this.dynamicDuration.hours > 0) {
                    this.dynamicDuration.hours--;
                } else {
                    this.dynamicDuration.hours = 23;
                    if (this.dynamicDuration.days > 0) {
                        this.dynamicDuration.days--;
                    } else {
                        this.dynamicDuration.seconds = 0;
                        this.dynamicDuration.minutes = 0;
                        this.dynamicDuration.hours = 0;
                        this.dynamicDuration.days = 0;
                    }
                }
            }
        }

        this.percentageDone += this.progressPerSecond;
        this.currentTimeoutId = setTimeout(() => this.updateProgress(action_reference), 1000);
    }

    stopProgressUpdate() {
        clearTimeout(this.currentTimeoutId);
    }
}
