<template>
    <div class="arrow">
        <div class="arrow-tail" />
        <div class="arrow-body" />
        <div class="arrow-head" />
        <MapActionPin
            v-for="action in arrow.items"
            :key="action.originalData._id"
            :action="action"
            :angle-between-cities="angleBetweenCities"
			:is-outgoing-action="true"
			@pin-hovered="(pin_id) => $emit('pin-hovered', pin_id)"
			@pin-unhovered="$emit('pin-unhovered')"
        />
    </div>
</template>

<script>
import MapArrow from '@/classes/mapArrows/MapArrow';
import MapActionPin from '@/components/WorldMap/MapActionPin';

export default {
    name: 'OutgoingMapArrow',
    components: { MapActionPin },
    props: {
        arrow: {
            type: MapArrow,
            default: () => new MapArrow([]),
        },
        angleBetweenCities: {
            type: Number,
            default: 0,
        },
    },
	emits: ['pin-hovered', 'pin-unhovered'],
};
</script>

<style scoped>
.arrow {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    filter: drop-shadow(0 0 2px black);
    -webkit-filter: drop-shadow(0 0 2px black);
}

.arrow-tail {
    width: 32px;
    height: 3px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), white);
}

.arrow-body {
    width: calc(100% - 32px);
    height: 3px;
    background: white;
}

.arrow-head {
    width: 10px;
    height: 10px;
    transform: translateX(-50%);
    background-image: url('../../assets/icons/arrowhead.png');
    background-size: cover;
}
</style>
