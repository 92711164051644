<template>
    <TransitionGroup name="menu-items-transition">
        <div v-if="menuVisible" class="menu-item item-1" data-bs-toggle="modal" data-bs-target="#generals-office-modal">
            <img src="../../assets/icons/map_actions_icon.svg" />
            <span class="action-title">Send<br />Troops</span>
        </div>
        <div v-if="menuVisible" class="menu-item item-2" data-bs-toggle="modal" data-bs-target="#generals-office-modal">
            <img src="../../assets/icons/interface/map_spy_icon.svg" />
            <span class="action-title">Spy</span>
        </div>
        <div
            v-if="menuVisible"
            class="menu-item item-3"
            :class="{ disabled: city.isBarbarian }"
            @click="openCommercialCenterModal"
        >
            <img src="../../assets/icons/metal_icon.svg" />
            <span class="action-title">Send<br />Resources</span>
        </div>
        <div
            v-if="menuVisible"
            class="menu-item item-4"
            :class="{ disabled: city.isBarbarian }"
            data-bs-toggle="modal"
            data-bs-target="#profile-modal"
        >
            <img src="../../assets/icons/interface/map_profile_icon.svg" />
            <span class="action-title">Profile</span>
        </div>
        <div v-if="menuVisible" class="menu-item item-5" :class="{ disabled: city.isBarbarian }" @click="openMessageModal">
            <img src="../../assets/icons/interface/map_message_icon.svg" />
            <span class="action-title">Message</span>
        </div>
    </TransitionGroup>
</template>

<script>
export default {
    name: 'CityActionsMenu',
    props: {
        city: {
            type: Object,
            default: () => {},
        },
        menuVisible: {
            type: Boolean,
            default: false,
        },
        mapScaleFactor: {
            type: Number,
            default: 1,
        },
    },
};
</script>

<style scoped>
.menu-item {
    z-index: 10;
    position: absolute;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 50%;
    top: 30%;
    left: 33%;
    background: rgba(0, 0, 0, 0.5);
    border: 2px solid darkgray;
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
    transition: opacity 0.25s, transform 0.5s;
}

.menu-item.disabled {
    background: transparent;
    pointer-events: none;
    cursor: default;
}

.item-1 {
    transform: rotate(0deg) translate(calc(-7rem - v-bind(1 / mapScaleFactor + 'rem'))) rotate(-0deg)
        scale(v-bind(0.85 / mapScaleFactor));
}

.item-2 {
    transform: rotate(41deg) translate(calc(-6.5rem - v-bind(1 / mapScaleFactor + 'rem'))) rotate(-41deg)
        scale(v-bind(0.85 / mapScaleFactor));
}

.item-3 {
    transform: rotate(90deg) translate(calc(-6rem - v-bind(1 / mapScaleFactor + 'rem'))) rotate(-90deg)
        scale(v-bind(0.85 / mapScaleFactor));
}

.item-4 {
    transform: rotate(139deg) translate(calc(-6.5rem - v-bind(1 / mapScaleFactor + 'rem'))) rotate(-139deg)
        scale(v-bind(0.85 / mapScaleFactor));
}

.item-5 {
    transform: rotate(180deg) translate(calc(-7rem - v-bind(1 / mapScaleFactor + 'rem'))) rotate(-180deg)
        scale(v-bind(0.85 / mapScaleFactor));
}

.action-title {
    position: absolute;
    top: 110%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.625rem;
    line-height: 0.625rem;
    text-align: center;
    color: ghostwhite;
    text-shadow: -1px 1px 1px #000, 1px 1px 1px #000, 1px -1px 1px #000, -1px -1px 1px #000;
}

.menu-items-transition-enter-from,
.menu-items-transition-leave-to {
    opacity: 0;
    transform: unset;
}
</style>
