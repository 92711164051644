<template>
    <MapArrowsPair
        v-for="arrows_pair in mapArrows"
        :key="`${arrows_pair.ownCity._id}${arrows_pair.targetCity._id}`"
        :arrows-pair="arrows_pair"
        @pin-hovered="pin_id => highlightStackedPins(pin_id)"
        @pin-unhovered="unhighlightAllPins"
    />
</template>

<script>
import MapArrowsPair from '@/components/WorldMap/MapArrowsPair';
export default {
    name: 'MapArrowsLayer',
    components: { MapArrowsPair },
    props: {
        mapArrows: {
            type: Set,
            default: () => new Set(),
        },
        mapActions: {
            type: Set,
            default: () => new Set(),
        },
    },
    data() {
        return {
            indicatorArea: 1600, // bounding box area of the action indicator icon, based on a width and height of 40px
            intersectionThreshold: 0.5, // threshold for determining if two indicators are stacked
        };
    },
    methods: {
        highlightStackedPins(current_pin_id) {
			const BoundingBoxes = this.getAllPinBoundingBoxes();

            const CurrentPinBoundingBox = BoundingBoxes.find(bounding_box => bounding_box.actionId === current_pin_id);
			BoundingBoxes.forEach(other_bounding_box => {
				if (other_bounding_box.actionId === current_pin_id) {
					return
				}

                if (this.arePinsStacked(CurrentPinBoundingBox.box, other_bounding_box.box)) {
                    this.highlightPin(other_bounding_box.actionId);
                }
            });
        },

		getAllPinBoundingBoxes() {
			const BoundingBoxes = [];

			this.mapActions.forEach(action => {
				const ActionId = action.originalData._id;
				BoundingBoxes.push({
					actionId: ActionId,
					box: document.getElementById(ActionId).getBoundingClientRect(),
				});
			});

			return BoundingBoxes;
		},

        unhighlightAllPins() {
			const BoundingBoxes = this.getAllPinBoundingBoxes();
			BoundingBoxes.forEach(bounding_box => this.unhighlightPin(bounding_box.actionId));
        },

        arePinsStacked(current_box, other_box) {
            const OverlapWidth = Math.min(current_box.right, other_box.right) - Math.max(current_box.left, other_box.left);
            const OverlapHeight = Math.min(current_box.bottom, other_box.bottom) - Math.max(current_box.top, other_box.top);
            const OverlapArea = Math.max(0, OverlapWidth) * Math.max(0, OverlapHeight);

            return OverlapArea >= this.intersectionThreshold * this.indicatorArea;
        },

        highlightPin(action_id) {
            document.getElementById(action_id).classList.add('hovered');
        },

        unhighlightPin(action_id) {
            document.getElementById(action_id).classList.remove('hovered');
        },
    },
};
</script>

<style scoped></style>
