<template>
    <div class="city-info-wrapper" :style="`transform: translateX(-50%) scale(${1 / mapScaleFactor});`">
        <span
            class="city-name"
            :class="{
                'city-rebel': city.color === 'Dark Grey',
                'city-current': city._id === currentCityId,
                'city-other': city.color === 'White' && city._id !== currentCityId,
            }"
        >
            {{ city.name }}
        </span>
        <span class="city-points">{{ city.points }}</span>
    </div>
</template>

<script>
export default {
    name: 'CityInfo',
	props: {
		city: {
			type: Object,
			default: () => {},
		},
		currentCityId: {
			type: String,
			default: '',
		},
		mapScaleFactor: {
			type: Number,
			default: 1,
		},
	},
};
</script>

<style scoped>

.city-info-wrapper {
	position: absolute;
	top: -5%;
	left: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	opacity: 1;
	transition: opacity 0.25s;
	pointer-events: none;
	width: max-content;
}

.city-name {
	position: relative;
	font-size: 0.875rem;
	line-height: 1.25rem;
	text-shadow: -1px 1px 1px #000, 1px 1px 1px #000, 1px -1px 1px #000, -1px -1px 1px #000;
}

.city-name::before {
	content: '';
	width: 0.75rem;
	height: 0.75rem;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: -1.25rem;
	border-radius: 50%;
	box-shadow: 0 0 3px 1px black;
}

.city-rebel {
	color: darkgray;
}

.city-rebel::before {
	background: darkgray;
}

.city-current {
	color: gold;
}

.city-current::before {
	background: gold;
}

.city-other {
	color: white;
}

.city-other::before {
	background: white;
}

.city-points {
	font-size: 0.6875rem;
	line-height: 0.6875rem;
	color: ghostwhite;
	text-shadow: -1px 1px 1px #000, 1px 1px 1px #000, 1px -1px 1px #000, -1px -1px 1px #000;
}
</style>
